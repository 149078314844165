import { createSlice } from "@reduxjs/toolkit";
import { NewsFeedProps } from "./NewsFeed";
import { PostsProps } from "../posts/Posts";
import { addProxyUrlToProfileImage } from "../../../utils";

const initialState: NewsFeedProps.State = {
  page: 1,
  processed_posts: {},
  page_size: 5,
  remaining_posts: 0,
  total_count: 0,
  total_pages: 0,
  getNewsFeedPostApiLoading: true,
};

export const newsfeedSlice = createSlice({
  name: "newsfeed",
  initialState,
  reducers: {
    setGetNewsFeedPostsApiLoading: (
      state,
      action: NewsFeedProps.setGetNewsFeedPostsApiLoading
    ) => {
      state.getNewsFeedPostApiLoading = action.payload;
    },
    setPage: (state, action: NewsFeedProps.setPage) => {
      state.page = action.payload;
    },
    setNewsFeedPost: (state, action: NewsFeedProps.setNewsFeedPost) => {
      const {
        page,
        page_size,
        processed_posts,
        remaining_posts,
        total_count,
        total_pages,
      } = action.payload;
      state.page = page;
      state.total_pages = total_pages;
      state.total_count = total_count;
      state.processed_posts = { ...state.processed_posts, ...processed_posts };
      state.remaining_posts = remaining_posts;
      state.page_size = page_size;
      Object.values(processed_posts).forEach((post) => {
        const rating_interaction = post?.interactions?.rating_interaction;
        if (!rating_interaction) return;
        state.processed_posts[
          post.ID
        ].interactions.rating_interaction.users_profile_pictures =
          getUsersProfilePictureFromUsersVotedList(
            rating_interaction.users_voted_list
          );
      });
    },
    updatePollSubmitAnswerResponse: (
      state,
      action: PostsProps.updatePollSubmitAnswerResponse
    ) => {
      const id = action.payload.post_id;
      const poll_interaction =
        state.processed_posts[id]?.interactions?.poll_interaction;
      if (!poll_interaction) return;
      state.processed_posts[id].interactions.poll_interaction = action.payload;
    },
    setSubmitAnswerForARateInteractionResponse: (
      state,
      action: PostsProps.setSubmitAnswerForARateInteractionResponse
    ) => {
      const id = action.payload.post_id;
      const interactions = state.processed_posts[id]?.interactions;
      if (!interactions) return;

      const rating_interaction = action.payload;
      state.processed_posts[id].interactions.rating_interaction =
        rating_interaction;

      state.processed_posts[
        id
      ].interactions.rating_interaction.users_profile_pictures =
        getUsersProfilePictureFromUsersVotedList(
          rating_interaction.users_voted_list
        );
    },
  },
});

export const { actions: newsfeedActions, reducer: newsfeedReducer } =
  newsfeedSlice;

const getUsersProfilePictureFromUsersVotedList = (
  users_voted_list: PostsProps.RatingUsersVotedList
) => {
  if (!users_voted_list) return [];
  const users_list = Object.values(users_voted_list);
  if (!users_list || !users_list.length) return [];
  const users: PostsProps.RatedUser[] = [];
  users_list.forEach((user) => users.push(...user.users));
  return users.map((user) => addProxyUrlToProfileImage(user.profile_picture));
};
