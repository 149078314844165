import { useContext, useState } from "react";
import styled from "styled-components";
import { WithHeightTransition } from "../../HOC/WithHeightTransition";
import { useAppSelector } from "../../redux";
import { ModalContext } from "../Common/Modal";
import { FlexColumnWithTransition, verticalScroll } from "../Common/Styles";
import Loading from "../Loading";
import { Footer } from "./Footer";
import { Illustration } from "./Illustration";
import { OnboardingUtils } from "./utils";

export const OnboardingForm = () => {
  const { currentStep, getUserProfile_API_STATUS } = useAppSelector(
    (state) => state.onboarding
  );
  const isLoading = getUserProfile_API_STATUS === "progress";
  const { contentRef } = useContext(ModalContext);
  const [containerHeight, setContainerHeight] = useState(0);

  return (
    <>
      <Illustration />
      <StepContainer ref={contentRef} $height={containerHeight}>
        <WithHeightTransition setContainerHeight={setContainerHeight}>
          <StepContentContainer
            id="onboarding-step-container"
            className="onboarding-step-container"
          >
            {isLoading ? (
              <Loading />
            ) : (
              OnboardingUtils.stepComponent[currentStep]
            )}
          </StepContentContainer>
        </WithHeightTransition>
      </StepContainer>
      <Footer validateFields={OnboardingUtils.validationFields[currentStep]} />
    </>
  );
};

const StepContainer = styled(FlexColumnWithTransition)`
  ${verticalScroll};
  z-index: 2;
`;
const StepContentContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing["spacing-6"].value}px ${theme.spacing["spacing-8"].value}px ${theme.spacing["spacing-4"].value}px`};
`;
