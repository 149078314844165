import { Components } from "@thisgorilla/primate-ds";
import { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { API_CALLS } from "../../../API/api_calls";
import { TextGroup } from "../../../commonStyles";
import { useAppSelector } from "../../../redux";
import { handleLinkClick } from "../../../utils";
import { FlexRow } from "../Styles";
import UserInfo from "../UserInfo";
import { FollowMessageAndMore } from "./FollowMessageAndMore";
import { PopupStyles } from "./Styles";

type UserInfoPopupProps = { id: string };
export const UserInfoPopup = ({ id }: UserInfoPopupProps) => {
  const { typography, Color } = useTheme();
  const { getEntityInfoResponses } = useAppSelector((state) => state.info);

  useEffect(() => {
    if (!id) return;
    if (getEntityInfoResponses[id]?.id) return;
    API_CALLS.INFO.getEntityInfo({ id, type: "user" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!getEntityInfoResponses[id]) return null;
  const {
    name,
    profile_picture,
    headline,
    job_title,
    user_company,
    user_company_website,
  } = getEntityInfoResponses[id];

  return (
    <PopupStyles.Container $gap={8} className="user-info-popup">
      <UserInfo
        {...{ headline, name, profile_picture }}
        avatarSize="Medium-48"
        onClick={() => {}} //TODO: Redirect to user profile page
        showUserInfoOnHover={false}
        id={id}
      />

      <JobTitleAndCompanyWithIcon $gap={4}>
        <Components.Icon
          type="Briefcase"
          color={Color.icon["icon-bg-primary"].value}
          size="16px"
        />
        <JobTitleAndCompany>
          <Components.Text
            text={
              user_company && user_company_website
                ? `${job_title} @ `
                : job_title
            }
            style={{ tokenStyle: typography.Body.S }}
          />
          <Components.Text
            text={user_company}
            style={{ tokenStyle: typography.Body.S }}
            isunderlined={false}
            color={Color.text["text-link"].value}
            isLink={true}
            onLinkClick={() => {
              handleLinkClick(user_company_website);
            }}
          />
        </JobTitleAndCompany>
      </JobTitleAndCompanyWithIcon>

      <FollowMessageAndMore />
    </PopupStyles.Container>
  );
};

const JobTitleAndCompanyWithIcon = styled(FlexRow)`
  align-items: start;
  justify-content: start;
`;
const JobTitleAndCompany = styled(TextGroup)``;
