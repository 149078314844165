import { Components } from "@thisgorilla/primate-ds";
import { useTheme } from "styled-components";
import { useAppSelector } from "../../../../../../../redux";
import { FlexColumn } from "../../../../../../Common/Styles";
import { ReportPostUtils } from "../utils";
import { Report } from "./Report";

export const ReportDetails = () => {
  const {
    why_reporting_answer,
    best_problem_description_answer,
    what_is_happening_answer,
  } = useAppSelector((state) => state.reportPost);
  const { typography } = useTheme();
  const titles = ReportPostUtils.ReportPostDescription;

  return (
    <FlexColumn $gap={8}>
      <Components.Text
        text="Report details"
        style={{ tokenStyle: typography.Body.L }}
        fontWeight="font-weight-semibold"
      />
      <Report
        title={titles["why_reporting"]}
        description={why_reporting_answer}
      />
      <Report
        title={titles["best_problem_description"]}
        description={best_problem_description_answer}
      />
      <Report
        title={titles["what_is_happening"]}
        description={what_is_happening_answer}
      />
    </FlexColumn>
  );
};
