import { DragEvent, useState } from "react";
import styled from "styled-components";

import { Divider } from "../../../../../commonStyles";
import useMountTransition from "../../../../../hooks/useMountTransition";
import { useAppSelector } from "../../../../../redux";
import { disableScroll } from "../../../../../utils";
import { Modal } from "../../../../Common";
import { HomeStyles } from "../../home.styled";
import { Attachments } from "./Attachments";
import { AvatarAndText } from "./AvatarAndText";
import CreatePostModal from "./CreatePostModal";
import {
  MAX_IMAGE_UPLOAD_COUNT,
  uploadFile,
} from "./CreatePostModal/ModalContent/ImageUpload/utils";
import { DefaultPostProps } from "./DefaultPost";

export const DefaultPost = () => {
  const [pollOpen, setPollOpen] = useState(false);
  const [sliderOpen, setSliderOpen] = useState(false);
  const [createPostOpen, setCreatePostOpen] = useState(false);
  const modalOpen = useMountTransition(createPostOpen, 300);
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const [schedulePostOpen, setSchedulePostOpen] = useState(false);
  const { post_id, uploadedFiles } = useAppSelector((state) => state.posts);
  const [isMoreImgUploadButtonDisable, setIsMoreImgUploadButtonDisable] =
    useState(false);

  const openCreatePostModal = (openWith?: DefaultPostProps.OpenWith) => {
    setCreatePostOpen(true);
    disableScroll();
    if (openWith) {
      switch (openWith) {
        case "Poll":
          return setPollOpen(true);
        case "Image":
          return setImageUploadOpen(true);
        case "Slider":
          return setSliderOpen(true);
      }
    }
  };

  const onImageDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (
      isMoreImgUploadButtonDisable ||
      uploadedFiles.length === MAX_IMAGE_UPLOAD_COUNT
    )
      return;

    const files = e.dataTransfer.files;
    if (files.length) {
      uploadFile({
        files,
        post_id,
        uploadedImages: uploadedFiles.length,
        setImageUploadOpen,
        setIsMoreImgUploadButtonDisable,
      });
    }
  };

  return (
    <Container>
      <AvatarAndText openCreatePostModal={openCreatePostModal} />
      <Divider />
      <Attachments openCreatePostModal={openCreatePostModal} />
      {(modalOpen || createPostOpen) && (
        <Modal
          modalOpen={createPostOpen ? modalOpen : createPostOpen}
          from="default-post"
          type="Pages"
          onDrop={onImageDrop}
          withAnimation={true}
          maxWidth={schedulePostOpen ? 498 : 726}
        >
          <CreatePostModal
            setCreatePostOpen={setCreatePostOpen}
            setPollOpen={setPollOpen}
            pollOpen={pollOpen}
            imageUploadOpen={imageUploadOpen}
            setImageUploadOpen={setImageUploadOpen}
            isMoreImgUploadButtonDisable={isMoreImgUploadButtonDisable}
            setIsMoreImgUploadButtonDisable={setIsMoreImgUploadButtonDisable}
            setSliderOpen={setSliderOpen}
            sliderOpen={sliderOpen}
            schedulePostOpen={schedulePostOpen}
            setSchedulePostOpen={setSchedulePostOpen}
          />
        </Modal>
      )}
    </Container>
  );
};

const Container = styled.div`
  gap: 16px;
  padding: 20px;
  ${HomeStyles.defaultContainer}
`;
