import { useContext } from "react";
import styled from "styled-components";
import { API_PATHS } from "../../../../../../API/api_paths";
import { useAppSelector } from "../../../../../../redux";
import { PostCardContext } from "../PostCardContent";

export const ImageContent = () => {
  const { postId } = useContext(PostCardContext);
  const { processed_posts } = useAppSelector((state) => state.newsfeed);
  const original_post = processed_posts[postId];
  const image = original_post?.media?.images?.[0];

  if (!image || !image.id) return null;
  return <SingleImage src={API_PATHS.posts.getPostImage + image?.id} />;
};

const SingleImage = styled.img`
  object-fit: cover;
  width: 100%;
`;
