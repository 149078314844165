import { Components } from "@thisgorilla/primate-ds";
import { FlexColumn } from "../../../../../../Common/Styles";
import { ReportDetails } from "./ReportDetails";
import { YouAreAboutToSubmit } from "./YouAreAboutToSubmit";

export const SubmitConfirmation = () => {
  return (
    <FlexColumn $gap={8}>
      <Components.ListItem key={`submit-confirmation-divider`} type="Divider" />
      <YouAreAboutToSubmit />
      <ReportDetails />
    </FlexColumn>
  );
};
