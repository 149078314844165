import { reportCommentUtils } from "../utils";
import { ReportListItem } from "./ListItem";

export const BestProblemDescription = () => {
  return (
    <>
      {reportCommentUtils.bestProblemDescriptionListItems.map((item) => (
        <ReportListItem key={item} label={item} />
      ))}
    </>
  );
};
