import styled from "styled-components";
import { useAppSelector } from "../../../../../redux";
import UserInfo from "../../../../Common/UserInfo";

export const UserDetails = () => {
  const { profile_picture, first_name, last_name, headline, id } =
    useAppSelector((state) => state.me);
  const name = `${first_name} ${last_name}`;

  return (
    <Container className="user-details">
      <UserInfo
        id={id}
        headline={headline}
        name={name}
        profile_picture={profile_picture}
        avatarSize="Small-40"
        showUserInfoOnHover={false}
      />
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => `
  border-radius: ${theme.spacing["spacing-2"].value}px;
  padding: ${theme.spacing["spacing-3"].value}px ${theme.spacing["spacing-2"].value}px;

  display: flex;
  flex-direction: row;
  gap: ${theme.spacing["spacing-2"].value}px;
  &:hover {
    background: ${theme.Color.elevation.surface["surface-primary-hover"].value};
  }
`
);
