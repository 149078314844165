import { Components, Inputs } from "@thisgorilla/primate-ds";
import { useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import { useBodySize } from "../../../../../../../../hooks/useBodySize";
import { useClickOutside } from "../../../../../../../../hooks/useClickOutside";
import { useDropdownKeyDown } from "../../../../../../../../hooks/useDropdownKeyDown";
import { useScroll } from "../../../../../../../../hooks/useScroll";
import { useAppSelector } from "../../../../../../../../redux";
import { Emoji } from "../../../../../../../Emoji/EmojiIcon";

const SliderDropdown = ({
  onValueChange,
  selectedId,
  otherSelectedId,
}: CreateSliderProps.SliderDropdown) => {
  const size = useBodySize();
  const dropdownRef = useRef(null);
  const containerRef = useRef(null);
  const { typography } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const scroll = useScroll("create-post-modal-content");
  const { emojis } = useAppSelector((state) => state.me);
  const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(0);
  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const menuHeight = dropdownRef.current
    ? (dropdownRef.current as Element).clientHeight
    : 0;

  useClickOutside({
    ref: dropdownRef,
    setState: setIsOpen,
    excludedRef: containerRef,
  });

  useEffect(() => {
    setIsOpen(false);
  }, [scroll]);

  useEffect(() => {
    if (containerRef?.current) {
      const wrapperRect = (
        containerRef?.current as Element
      )?.getBoundingClientRect();

      setMenuPosition({
        top: wrapperRect?.bottom,
        left: wrapperRect?.left,
        width: wrapperRect?.width,
        height: wrapperRect?.height,
      });
    }
  }, [isOpen, containerRef, size]);

  const calculateBlocks = (containerWidth: number): number => {
    if (containerWidth < 32) {
      return 0;
    }
    return 1 + calculateBlocks(containerWidth - 40);
  };

  const keyDown = useDropdownKeyDown({
    handleSelect: (id: string) => onValueChange(id),
    highlightedIndex: highlightedOptionIndex,
    isDropdownOpen: isOpen,
    options: Object.keys(emojis),
    setDropdownOpen: setIsOpen,
    setHighlightedIndex: setHighlightedOptionIndex,
    renderInSigleLine: calculateBlocks(menuPosition.width - 26),
  });

  return (
    <>
      <Container
        onClick={() => {
          setIsOpen(!isOpen);
          setHighlightedOptionIndex(0);
        }}
        tabIndex={0}
        onKeyDown={keyDown}
        $active={isOpen}
        ref={containerRef}
      >
        <EmojiContainer>
          <Emoji type={emojis[selectedId].name} />
        </EmojiContainer>
        <div>
          <Components.IconButton
            disabled={false}
            iconType={isOpen ? "ChevronUp" : "ChevronDown"}
            kind="Tertiary"
            onClick={() => {}}
            size="Small"
          />
        </div>
      </Container>
      {isOpen && (
        <MenuContainer
          ref={dropdownRef}
          $menuPosition={menuPosition}
          $menuHeight={menuHeight}
          $positon={
            window.innerHeight - menuPosition.top - menuHeight > 0
              ? "bottom"
              : "top"
          }
        >
          <Components.Menu size="fill" itemGap={16}>
            <Components.Text
              style={{ tokenStyle: typography.Heading.S }}
              fontWeight="font-weight-semibold"
              text="Available emojis"
              lineHeight="line-height-400"
            />
            <EmojiListWrapper>
              {Object.entries(emojis)?.map(([key, { name }], index) => {
                if (key === otherSelectedId) return null;
                return (
                  <EmojiWrapper
                    $selected={key === selectedId}
                    $hover={highlightedOptionIndex === index}
                    key={index}
                    onClick={() => {
                      onValueChange(key);
                      setIsOpen(false);
                    }}
                    onMouseEnter={() => {
                      setHighlightedOptionIndex(index);
                    }}
                  >
                    <Emoji type={name} />
                  </EmojiWrapper>
                );
              })}
            </EmojiListWrapper>
          </Components.Menu>
        </MenuContainer>
      )}
    </>
  );
};

export default SliderDropdown;

const Container = styled.div<{
  $active: boolean;
}>`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid;
  outline: none;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-4"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  ${({ $active }) =>
    $active
      ? Inputs.CommonField.CommonFieldStyle.inputActivecss
      : Inputs.CommonField.getInputCss("Normal")}
`;

const EmojiContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const EmojiListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
const EmojiWrapper = styled.div<{ $selected: boolean; $hover: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  cursor: pointer;
  background: ${({ theme, $selected, $hover }) =>
    $selected || $hover
      ? theme.Color.elevation.surface["surface-primary-hover"].value
      : "transparent"};
  border: ${({ theme, $selected }) =>
    $selected &&
    "1px solid" + theme.Color.border["ui-border-emphasize-brand"].value};
  &:hover {
    background: ${({ theme }) =>
      theme.Color.elevation.surface["surface-primary-hover"].value};
  }
`;

const MenuContainer = styled.div<{
  $menuPosition: { top: number; left: number; width: number; height: number };
  $positon: "top" | "bottom";
  $menuHeight: number;
}>`
  position: fixed;
  top: ${({ $menuPosition, $positon, $menuHeight }) =>
    $positon === "top"
      ? $menuPosition.top - $menuPosition.height - $menuHeight - 8
      : $menuPosition.top + 8}px;
  left: ${({ $menuPosition }) => $menuPosition.left + 1}px;
  z-index: 10;
  width: ${({ $menuPosition }) => $menuPosition.width - 2}px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary"].value};

  & > div {
    padding: ${({ theme }) => theme.spacing["spacing-5"].value}px
      ${({ theme }) => theme.spacing["spacing-3"].value}px;
  }
`;
