import { useEffect, useState } from "react";

export const useContainerSize = (
  containerRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (entries[0]) {
        setSize({
          width: entries[0].borderBoxSize[0].inlineSize,
          height: entries[0].borderBoxSize[0].blockSize,
        });
      }
    };

    const resizeObserver = new ResizeObserver((entries) =>
      handleResize(entries)
    );

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  return size;
};
