import { createSlice } from "@reduxjs/toolkit";

import { CommentsProps } from "./comments";

export const INTIALCOMMENT: CommentsProps.CommentsResponse = {
  comments: [],
  page: 0,
  page_size: 3,
  total_pages: 0,
  total_count: 0,
  remaining_comments: 0,
};

const initialState: CommentsProps.State = {
  allPostComments: {},
};

const slice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    setPostComment: (state, actions: CommentsProps.setPostComment) => {
      const { post_comment, post_id, total_count } = actions.payload;
      state.allPostComments[post_id] = {
        ...INTIALCOMMENT,
        total_count,
        remaining_comments: total_count - post_comment.length,
        comments: post_comment,
      };
    },
    setComments: (state, actions: CommentsProps.setComments) => {
      const { post_id, commentData } = actions.payload;
      state.allPostComments[post_id] = {
        ...state.allPostComments[post_id],
        ...commentData,
        comments: [
          ...state.allPostComments[post_id].comments,
          ...commentData.comments,
        ],
      };
    },
    setUpdateComments: (state, actions: CommentsProps.setUpdateComments) => {
      const { post_id } = actions.payload;
      const updatedCommetnIndex = state.allPostComments[
        post_id
      ].comments.findIndex(
        (comment) => comment.id === actions.payload.old_comment_id
      );
      state.allPostComments[post_id].comments[updatedCommetnIndex] =
        actions.payload;
    },
    setCreateComments: (state, actions: CommentsProps.setCreateComments) => {
      const { post_id, id, total_count } = actions.payload;
      const commentIndex = state.allPostComments[post_id].comments.findIndex(
        (comment) => comment.id === id
      );
      if (commentIndex === -1) {
        state.allPostComments[post_id].comments = [
          actions.payload,
          ...state.allPostComments[post_id].comments,
        ];
      } else {
        state.allPostComments[post_id].comments[commentIndex] = actions.payload;
      }
      state.allPostComments[post_id].total_count = total_count;
    },
    setDeleteChildComments: (
      state,
      actions: CommentsProps.setDeleteChildComments
    ) => {
      const { post_id, id, total_count } = actions.payload;

      const updatedCommetnIndex = state.allPostComments[
        post_id
      ].comments.findIndex((comment) => comment.id === id);
      state.allPostComments[post_id].comments[updatedCommetnIndex] =
        actions.payload;
      state.allPostComments[post_id].total_count = total_count;
    },
    setDeleteParentComments: (
      state,
      actions: CommentsProps.setDeleteParentComments
    ) => {
      const { parent_id, post_id, total_count } = actions.payload;
      state.allPostComments[post_id].comments = state.allPostComments[
        post_id
      ].comments.filter((comment) => comment.id !== parent_id);
      state.allPostComments[post_id].total_count = total_count;
    },
    setCommentPage: (state, actions: CommentsProps.setCommentPage) => {
      state.allPostComments[actions.payload.post_id].page =
        actions.payload.page;
    },
    setLikeDislikeCount: (
      state,
      actions: CommentsProps.setLikeDislikeCount
    ) => {
      const {
        comment_id,
        parent_comment_id,
        dislikes,
        likes,
        user_voted,
        post_id,
      } = actions.payload;

      if (parent_comment_id) {
        const commentIndex = state.allPostComments[post_id].comments.findIndex(
          (comment) => comment.id === parent_comment_id
        );
        const repliesIndex = (
          state.allPostComments[post_id].comments[commentIndex]
            .replies as CommentsProps.Comment[]
        ).findIndex((comment) => comment.id === comment_id);

        const newReply = {
          ...(
            state.allPostComments[post_id].comments[commentIndex]
              .replies as CommentsProps.Comment[]
          )[repliesIndex],
          dislikes,
          likes,
          user_voted,
        };

        (
          state.allPostComments[post_id].comments[commentIndex]
            .replies as CommentsProps.Comment[]
        )[repliesIndex] = newReply;
      } else {
        const commentIndex = state.allPostComments[post_id].comments.findIndex(
          (comment) => comment.id === comment_id
        );
        const newComment = {
          ...state.allPostComments[post_id].comments[commentIndex],
          dislikes,
          likes,
          user_voted,
        };
        state.allPostComments[post_id].comments[commentIndex] = newComment;
      }
    },
    removeMuteAUserOnComment: (
      state,
      actions: CommentsProps.removeMuteAUserOnComment
    ) => {
      const { muted_user_id, post_id } = actions.payload;

      state.allPostComments[post_id].comments = state.allPostComments[
        post_id
      ].comments
        .filter((comment) => comment.user_id !== muted_user_id)
        .map((comment) => ({
          ...comment,
          replies: comment.replies?.filter(
            (comment) => comment.user_id !== muted_user_id
          ),
        }));
    },
    resetComment: (state, actions: CommentsProps.resetComment) => {
      state.allPostComments[actions.payload] = INTIALCOMMENT;
    },
    reset: () => initialState,
  },
});

export const commentsActions = slice.actions;
export const commentsReducer = slice.reducer;
