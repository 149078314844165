import { Components } from "@thisgorilla/primate-ds";
import moment from "moment";
import { useContext, useMemo, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import { CreatePostModalContext } from "..";
import { Divider } from "../../../../../../../commonStyles";
import { useAppSelector } from "../../../../../../../redux";
import { isFormSubmitButtonDisabled } from "../../../../../../../utils";
import { EmojiPicker } from "../../../../../../Common";
import { FlexRowCenter } from "../../../../../../Common/Styles";
import { CreatePostModalProps } from "../CreatePostModal";
import { MAX_IMAGE_UPLOAD_COUNT } from "../ModalContent/ImageUpload/utils";

const ModalFooter = () => {
  const emojiOptionsRef = useRef(null);
  const { typography, Color } = useTheme();
  const { uploadedFiles } = useAppSelector((state) => state.posts);
  const [showEmojiOptions, setShowEmojiOptions] = useState(false);
  const {
    setPollOpen,
    formik,
    pollOpen,
    choiceCount,
    setImageUploadOpen,
    setSchedulePostOpen,
    setSliderOpen,
    sliderOpen,
    isMoreImgUploadButtonDisable,
  } = useContext(CreatePostModalContext);

  const { delivery_date } = formik.values;

  const footerLeftSideOptions: CreatePostModalProps.FooterLeftSideOption[] = [
    {
      iconType: "Image",
      onClick: () => {
        setImageUploadOpen(true);
      },
      tooltipLabel: "Add media",
      disabled:
        isMoreImgUploadButtonDisable ||
        uploadedFiles.length === MAX_IMAGE_UPLOAD_COUNT,
    },
    { iconType: "Video", onClick: () => {}, tooltipLabel: "Add videos" },
    { iconType: "Gif", onClick: () => {}, tooltipLabel: "Add a GIF" },
    {
      iconType: "Smile",
      onClick: () => {
        setShowEmojiOptions(true);
      },
      tooltipLabel: "Add emoji",
    },
    {
      iconType: "Poll",
      onClick: () => {
        setPollOpen(true);
      },
      tooltipLabel: "Create a poll",
    },
    {
      iconType: "Slider",
      onClick: () => setSliderOpen(true),
      tooltipLabel: "Create a rating",
    },
    {
      iconType: "FileText",
      onClick: () => {},
      tooltipLabel: "Write an article",
    },
  ];

  const requiredFileds = useMemo(() => {
    const required: { [key: string]: boolean } = {};

    if (pollOpen) {
      required["question"] = true;
      required["duration"] = true;
      for (let i = 1; i <= choiceCount.length; i++) {
        required[`choice_${choiceCount[i - 1]}`] = true;
      }
    }

    if (sliderOpen) {
      required["slider_question"] = true;
      required["left_emoji_id"] = true;
      required["right_emoji_id"] = true;
    }

    required["content"] = !(sliderOpen || pollOpen || uploadedFiles.length);

    return required;
  }, [choiceCount, pollOpen, sliderOpen, uploadedFiles]);

  return (
    <Container>
      <Divider />
      <ModalFooterContainer>
        <FooterLeftSide>
          {footerLeftSideOptions.map(
            ({ iconType, onClick, tooltipLabel, disabled = false }, index) => (
              <Components.Tooltip
                type="label"
                label={tooltipLabel}
                position="top"
                key={index}
              >
                <Components.IconButton
                  iconType={iconType}
                  onClick={onClick}
                  disabled={disabled}
                  kind="Secondary"
                  size="Medium"
                />
              </Components.Tooltip>
            )
          )}
        </FooterLeftSide>
        {showEmojiOptions && (
          <EmojiPicker
            emojiOptionsRef={emojiOptionsRef}
            setShowEmojiOptions={setShowEmojiOptions}
            showEmojiOptions={showEmojiOptions}
            position="top"
          />
        )}
        <FooterRightSide>
          <Components.Button
            color="Neutral"
            kind="Secondary"
            disabled={false}
            label="Topics"
            onClick={() => {}}
            size="Medium"
            iconPosition="left"
            iconType="PlusCircle"
          />
          {delivery_date ? (
            <Wrapper>
              <Components.IconButton
                iconType="Calendar"
                onClick={() => setSchedulePostOpen(true)}
                disabled={false}
                kind="Secondary"
                size="Medium"
              />
              <StatusIndicatorContainer>
                <Components.Tooltip
                  type="component"
                  label={
                    <FlexRowCenter>
                      <Components.Text
                        renderInSingleLine={true}
                        style={{ tokenStyle: typography.Body.M }}
                        text={"Post scheduled for "}
                      />
                      <Components.Text
                        renderInSingleLine={true}
                        style={{ tokenStyle: typography.Body.M }}
                        text={moment(delivery_date).format("LLLL")}
                        color={Color.text["text-emphasize-brand"].value}
                      />
                    </FlexRowCenter>
                  }
                  position="top"
                  width={400}
                >
                  <Components.StatusIndicator
                    iconType="Check"
                    state="Positive"
                  />
                </Components.Tooltip>
              </StatusIndicatorContainer>
            </Wrapper>
          ) : (
            <Components.Tooltip
              type="label"
              label="Schedule post"
              position="top"
            >
              <Components.IconButton
                iconType="Calendar"
                onClick={() => setSchedulePostOpen(true)}
                disabled={false}
                kind="Secondary"
                size="Medium"
              />
            </Components.Tooltip>
          )}

          <Components.Button
            color="Primary"
            kind="Primary"
            label="Post"
            onClick={() => {
              formik.isValid && formik.submitForm();
            }}
            disabled={isFormSubmitButtonDisabled({
              formik,
              requiredFileds: requiredFileds,
            })}
            size="Medium"
          />
        </FooterRightSide>
      </ModalFooterContainer>
    </Container>
  );
};

export default ModalFooter;

const Container = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing["spacing-none"].value}px;
  right: ${({ theme }) => theme.spacing["spacing-none"].value}px;
  left: ${({ theme }) => theme.spacing["spacing-none"].value}px;
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-overlay"].value};
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-4"].value}px
    ${({ theme }) => theme.spacing["spacing-4"].value}px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-none"].value}px
    ${({ theme }) => theme.radius["radius-none"].value}px
    ${({ theme }) => theme.radius["radius-m"].value}px
    ${({ theme }) => theme.radius["radius-m"].value}px;
`;

const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  position: relative;
`;

const FooterLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
const FooterRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;

const Wrapper = styled.div`
  position: relative;
`;
const StatusIndicatorContainer = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
`;
