import { useContext, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useContainerSize } from "../../../hooks/useContainerSize";
import { Emoji } from "../../Emoji/EmojiIcon";
import { PostCardContext } from "../../pages/home/CenterPanel/PostCard/PostCardContent";
import { Curve } from "./Curve";
import { SliderProps } from "./Slider";
import { SliderUtils } from "./utils";

export const Slider = (props: SliderProps.IProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const { emojiValue, isInteractive, onDragEnd, isVoted, aggregatePercent } =
    props;
  const { postId } = useContext(PostCardContext);
  const [leftEmoji, rightEmoji] = emojiValue;
  const [value, setValue] = useState(50);

  let sliderValue = value;
  if (isVoted) {
    sliderValue = aggregatePercent > 50 ? 100 : 0;
  }

  const [leftEmojiSize, rightEmojiSize] = SliderUtils.getEmojiSize(sliderValue);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isInteractive || isVoted) return;
    const value = parseInt(event.target.value);
    setValue(value);
  };
  const handleDragEnd = () => {
    if (!isInteractive || isVoted) return;
    onDragEnd(value);
  };
  const { width } = useContainerSize(ref);

  return (
    <Container $isVoted={isVoted ?? false} className="slider-container">
      <Emoji type={leftEmoji} size={leftEmojiSize} />

      <SliderContainer>
        {isVoted && (
          <Curve
            width={width}
            aggregatePercent={aggregatePercent}
            postId={postId}
          />
        )}
        <CustomSlider
          type="range"
          min="0"
          max="100"
          step="0.5"
          value={sliderValue}
          onChange={handleChange}
          className={`custom-slider-interactive-${isInteractive}`}
          onMouseUp={handleDragEnd}
          $value={sliderValue}
          $isInteractive={isInteractive ?? false}
          $isVoted={isVoted ?? false}
          ref={ref}
        />
      </SliderContainer>
      <Emoji type={rightEmoji} size={rightEmojiSize} />
    </Container>
  );
};

const Container = styled.div<{ $isVoted: boolean }>`
  display: flex;
  align-items: ${({ $isVoted }) => ($isVoted ? "flex-end" : "center")};
  justify-content: center;
  width: 100%;
  min-height: ${({ $isVoted }) => ($isVoted ? 83 : 51)}px;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 349px;
`;
const sliderThumbCss = css<SliderProps.CustomSlider>`
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary"].value};
  border: 8px solid
    ${({ theme }) => theme.Color.border["ui-border-emphasize-brand"].value};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  cursor: ${({ $isInteractive }) => ($isInteractive ? "pointer" : "default")};
  opacity: ${({ $isVoted }) => ($isVoted ? 0.6 : 1)};
`;
const CustomSlider = styled.input<SliderProps.CustomSlider>`
  margin: ${({ theme }) => theme.spacing["spacing-none"].value}px;
  -webkit-appearance: none;
  width: 100%;
  height: 16px;
  border-radius: 60px;
  background: ${({ $value }) => SliderUtils.getBackground($value)};
  opacity: ${({ $isVoted }) => ($isVoted ? 0.3 : 1)};

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    ${sliderThumbCss}
  }

  &::-moz-range-thumb {
    ${sliderThumbCss}
  }
`;
