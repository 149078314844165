import { createSlice } from "@reduxjs/toolkit";

import { CountryProps } from "@thisgorilla/primate-ds";
import { OnboardingProps } from "./onboarding";

const initialAPI_STATUS: OnboardingProps.API = {
  getGeoLocationData_API_STATUS: "idle",
  queryCityByUserInput_API_STATUS: "idle",
  saveCityLocation_API_STATUS: "idle",

  getUserIndustries_API_STATUS: "idle",
  searchIndustries_API_STATUS: "idle",
  addUserIndustries_API_STATUS: "idle",

  getUserInterests_API_STATUS: "idle",
  searchInterests_API_STATUS: "idle",
  addUserInterests_API_STATUS: "idle",

  getUserJobTitles_API_STATUS: "idle",
  searchJobTitles_API_STATUS: "idle",
  addUserJobTitles_API_STATUS: "idle",

  getUserProfile_API_STATUS: "idle",
};

const initialAPI_Response_data: OnboardingProps.API_Response_data = {
  location: {} as OnboardingProps.Location,
  cityOptions: [],

  job_titles: [],
  jobTitleOptions: [],

  industries: [],
  interestCategories: [],
  industryOptions: [],

  interests: [],
  interestOptions: [],

  recommendedUsers: [],
};

const initialComponent_data: OnboardingProps.Component_data = {
  currentStep: "location",
  isGettingStartedVisited: false,
  selectedCountryCode: "",
  first_name: "",
  last_name: "",
  selectedLocationId: 0,
};

const initialState: OnboardingProps.State = {
  ...initialAPI_STATUS,
  ...initialAPI_Response_data,
  ...initialComponent_data,
};

const slice = createSlice({
  initialState,
  name: "onboarding",
  reducers: {
    //#region Get user profile
    setGetUserProfileResponse: (
      state,
      action: OnboardingProps.setGetUserProfileResponse
    ) => {
      const { location, first_name, last_name } = action.payload.step_1;
      const { job_titles } = action.payload.step_2;
      const { industries } = action.payload.step_3;
      const { interests } = action.payload.step_4;

      state.first_name = first_name;
      state.last_name = last_name;
      state.location = location;
      state.selectedLocationId = location.city_id;
      state.selectedCountryCode =
        location.country_code.toUpperCase() as CountryProps.FlagType;
      state.job_titles = job_titles;
      state.industries = industries;
      state.interests = interests;
    },
    //#endregion

    //#region component states
    setCurrentStep: (state, action: OnboardingProps.setCurrentStep) => {
      state.currentStep = action.payload || "get_started";
    },
    setGettingStartedVisited: (
      state,
      action: OnboardingProps.setGettingStartedVisited
    ) => {
      state.isGettingStartedVisited = action.payload;
    },
    removeSelectedLocation: (state) => {
      state.location = {} as OnboardingProps.Location;
      state.selectedLocationId = 0;
    },
    setSelectedLocation: (
      state,
      action: OnboardingProps.setSelectedLocation
    ) => {
      state.location = action.payload;
      state.selectedLocationId = action.payload.id;
    },
    setSelectedLocationId: (
      state,
      action: OnboardingProps.setSelectedLocationId
    ) => {
      state.selectedLocationId = action.payload;
    },
    addJobTitle: (state, action: OnboardingProps.addJobTitle) => {
      state.job_titles = [...state.job_titles, action.payload];
    },
    removeJobTitle: (state, action: OnboardingProps.removeJobTitle) => {
      state.job_titles = state.job_titles.filter((job) => {
        if (job.id) return job.id !== action.payload.id;
        return job.title !== action.payload.title;
      });
    },
    addIndustry: (state, action: OnboardingProps.addIndustry) => {
      state.industries = [...state.industries, action.payload];
    },
    removeIndustry: (state, action: OnboardingProps.removeIndustry) => {
      state.industries = state.industries.filter((industry) => {
        if (industry.id) return industry.id !== action.payload.id;
        return industry.title !== action.payload.title;
      });
    },
    addInterest: (state, action: OnboardingProps.addInterest) => {
      state.interests = [...state.interests, action.payload];
    },
    removeInterest: (state, action: OnboardingProps.removeInterest) => {
      state.interests = state.interests.filter((interest) => {
        if (interest.id) return interest.id !== action.payload.id;
        return interest.title !== action.payload.title;
      });
    },
    setFirstName: (state, action: OnboardingProps.setFirstName) => {
      state.first_name = action.payload;
    },
    setLastName: (state, action: OnboardingProps.setLastName) => {
      state.last_name = action.payload;
    },
    //#endregion

    //#region API RESPONSES

    //#region Location
    setSelectedCountryCode: (
      state,
      action: OnboardingProps.setSelectedCountryCode
    ) => {
      state.selectedCountryCode = action.payload;
    },
    setGetGeoLocationDataResponse: (
      state,
      action: OnboardingProps.setGetGeoLocationDataResponse
    ) => {
      const location = action.payload || ({} as OnboardingProps.Location);
      state.location = location;
      state.selectedCountryCode =
        location.country_code.toUpperCase() as CountryProps.FlagType;
      state.selectedLocationId = location.id;
    },
    setQueryCityByUserInputResponse: (
      state,
      action: OnboardingProps.setQueryCityByUserInputResponse
    ) => {
      state.cityOptions = action.payload || [];
    },
    setSaveCityLocationResponse: (
      state,
      action: OnboardingProps.setSaveCityLocationResponse
    ) => {
      state.location = action.payload;
    },
    //#endregion

    //#region Job titles
    setGetUserJobTitlesResponse: (
      state,
      action: OnboardingProps.setGetUserJobTitlesResponse
    ) => {
      state.job_titles = action.payload || [];
    },
    setSearchJobTitlesResponse: (
      state,
      action: OnboardingProps.setSearchJobTitlesResponse
    ) => {
      state.jobTitleOptions = action.payload || [];
    },
    setAddUserJobTitlesResponse: (
      state,
      action: OnboardingProps.setAddUserJobTitlesResponse
    ) => {
      state.job_titles = action.payload.job_titles || [];
    },
    //#endregion

    //#region Industries
    setGetUserIndustriesResponse: (
      state,
      action: OnboardingProps.setGetUserIndustriesResponse
    ) => {
      state.industries = action.payload || [];
    },
    setSearchIndustriesResponse: (
      state,
      action: OnboardingProps.setSearchIndustriesResponse
    ) => {
      state.industryOptions = action.payload || [];
    },
    setAddUserIndustriesResponse: (
      state,
      action: OnboardingProps.setAddUserIndustriesResponse
    ) => {
      state.industries = action.payload.industries || [];
      state.interestCategories = action.payload.interest_categories;
    },
    //#endregion

    //#region Interests
    setGetUserInterestsResponse: (
      state,
      action: OnboardingProps.setGetUserInterestsResponse
    ) => {
      state.interests = action.payload || [];
    },
    setSearchInterestsResponse: (
      state,
      action: OnboardingProps.setSearchInterestsResponse
    ) => {
      state.interestOptions = action.payload || [];
    },
    setAddUserInterestsResponse: (
      state,
      action: OnboardingProps.setAddUserInterestsResponse
    ) => {
      state.interests = action.payload.interests || [];
      state.recommendedUsers = action.payload.recommended_users || [];
    },
    //#endregion

    //#region API STATUS REDUCERS
    setGetGeoLocationData_API_STATUS: (
      state,
      action: OnboardingProps.setGetGeoLocationData_API_STATUS
    ) => {
      state.getGeoLocationData_API_STATUS = action.payload;
    },
    setQueryCityByUserInput_API_STATUS: (
      state,
      action: OnboardingProps.setQueryCityByUserInput_API_STATUS
    ) => {
      state.queryCityByUserInput_API_STATUS = action.payload;
    },
    setSaveCityLocation_API_STATUS: (
      state,
      action: OnboardingProps.setSaveCityLocation_API_STATUS
    ) => {
      state.saveCityLocation_API_STATUS = action.payload;
    },

    setGetUserIndustries_API_STATUS: (
      state,
      action: OnboardingProps.setGetUserIndustries_API_STATUS
    ) => {
      state.getUserIndustries_API_STATUS = action.payload;
    },
    setSearchIndustries_API_STATUS: (
      state,
      action: OnboardingProps.setSearchIndustries_API_STATUS
    ) => {
      state.searchIndustries_API_STATUS = action.payload;
    },
    setAddUserIndustries_API_STATUS: (
      state,
      action: OnboardingProps.setAddUserIndustries_API_STATUS
    ) => {
      state.addUserIndustries_API_STATUS = action.payload;
    },

    setGetUserInterests_API_STATUS: (
      state,
      action: OnboardingProps.setGetUserInterests_API_STATUS
    ) => {
      state.getUserInterests_API_STATUS = action.payload;
    },
    setSearchInterests_API_STATUS: (
      state,
      action: OnboardingProps.setSearchInterests_API_STATUS
    ) => {
      state.searchInterests_API_STATUS = action.payload;
    },
    setAddUserInterests_API_STATUS: (
      state,
      action: OnboardingProps.setAddUserInterests_API_STATUS
    ) => {
      state.addUserInterests_API_STATUS = action.payload;
    },

    setGetUserJobTitles_API_STATUS: (
      state,
      action: OnboardingProps.setGetUserJobTitles_API_STATUS
    ) => {
      state.getUserJobTitles_API_STATUS = action.payload;
    },
    setSearchJobTitles_API_STATUS: (
      state,
      action: OnboardingProps.setSearchJobTitles_API_STATUS
    ) => {
      state.searchJobTitles_API_STATUS = action.payload;
    },
    setAddUserJobTitles_API_STATUS: (
      state,
      action: OnboardingProps.setAddUserJobTitles_API_STATUS
    ) => {
      state.addUserJobTitles_API_STATUS = action.payload;
    },

    setGetUserProfile_API_STATUS: (
      state,
      action: OnboardingProps.setGetUserProfile_API_STATUS
    ) => {
      state.getUserProfile_API_STATUS = action.payload;
    },
    //#endregion

    //#region CLEAR
    clearCityOptions: (state) => {
      state.cityOptions = [];
    },
    clearJobTitleOptions: (state) => {
      state.jobTitleOptions = [];
    },
    clearIndustriesOptions: (state) => {
      state.industryOptions = [];
    },
    clearInterestsOptions: (state) => {
      state.interestOptions = [];
    },
    reset: () => initialState,
    //#endregion
  },
});

export const onboardingActions = slice.actions;
export const onboardingReducer = slice.reducer;
