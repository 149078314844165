import { AUTH } from "./authentication";
import { COMMENTS } from "./comments";
import { CONNECTIONS } from "./connections";
import { IMAGES } from "./images";
import { INFO } from "./info";
import { ME } from "./me";
import { NEWSFEED } from "./newsfeed";
import { ONBOARDING } from "./onboarding";
import { POLLS } from "./polls";
import { POSTS } from "./posts";
import { SAVED_ITEMS } from "./savedItems";

export const API_INSTANCES = {
  AUTH,
  COMMENTS,
  CONNECTIONS,
  IMAGES,
  INFO,
  ME,
  NEWSFEED,
  ONBOARDING,
  POLLS,
  POSTS,
  SAVED_ITEMS,
};
