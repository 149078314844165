import { Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_CALLS } from "../API/api_calls";
import { useAppSelector } from "../redux";
import { ROUTES } from "../utills/routes";
import { Layout } from "./Layout";
import Loading from "./Loading";
import { Onboarding } from "./onboarding";

export const Protected = ({ component }: { component: JSX.Element }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, getStatusAPI_STATUS } = useAppSelector(
    (state) => state.auth
  );
  const { onboarded } = useAppSelector((state) => state.me);

  const isLoading = getStatusAPI_STATUS === "progress";
  const isIdle = getStatusAPI_STATUS === "idle";

  useEffect(() => {
    if (getStatusAPI_STATUS !== "idle") return;
    API_CALLS.AUTH.getStatus();
  }, [getStatusAPI_STATUS]);

  useEffect(() => {
    //Redirect to login page if user is not authenticated
    if (isAuthenticated) return;
    if (isLoading || isIdle) return;

    navigate(`${ROUTES.login}?redirectURL=${location.pathname}`);
  }, [isAuthenticated, isLoading, isIdle, location.pathname, navigate]);

  if (isAuthenticated)
    return (
      <Layout>
        <>
          {component}
          {!isLoading && !onboarded && <Onboarding />}
        </>
      </Layout>
    );
  if (isLoading) return <Loading />;
  return <Fragment />;
};
