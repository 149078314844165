import { Components, Icons } from "@thisgorilla/primate-ds";
import styled, { useTheme } from "styled-components";
import {
  FlexColumn,
  FlexColumnStart,
  FlexRowCenter,
} from "../../../../../../Common/Styles";

export const TweakYourFeed = () => {
  const { typography, Color } = useTheme();
  const textColor = Color.text["text-emphasize-brand-bolder"].value;

  return (
    <Container
      $gap={8}
      onClick={() => {
        //TODO: Implement Tweak Your Feed functionality
      }}
    >
      <TitleAndDescription $gap={8}>
        <Title $gap={8}>
          <Icons.Info
            size="16px"
            color={Color.icon["icon-brand-bolder"].value}
          />
          <Components.Text
            style={{ tokenStyle: typography.Heading.S }}
            fontWeight="font-weight-semibold"
            text="Tweak your feed"
            color={textColor}
          />
        </Title>
        <Description $gap={0}>
          <Components.Text
            style={{ tokenStyle: typography.Body.M }}
            text="Not happy with the content you’re seeing in your feed?"
            color={textColor}
          />
          <Components.Text
            style={{ tokenStyle: typography.Body.M }}
            text="Lets make some adjustments"
            color={textColor}
          />
        </Description>
      </TitleAndDescription>

      <Components.Icon type="ArrowRight" size="24px" color={textColor} />
    </Container>
  );
};

const Container = styled(FlexRowCenter)`
  padding: 16px;
  background-color: ${({ theme }) =>
    theme.Color.background.brand.subtlest["default-hover"].value};
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
`;
const TitleAndDescription = styled(FlexColumnStart)``;
const Description = styled(FlexColumn)``;
const Title = styled(FlexRowCenter)``;
