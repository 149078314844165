const getEmojiSize = (value: number) => {
  const percentage = Math.max(value - 50, 50 - value);
  const expanded = percentage * 0.24;
  const shrinked = percentage * 0.08;

  if (value < 50) return [24 + expanded, 24 - shrinked];
  if (value > 50) return [24 - shrinked, 24 + expanded];
  return [24, 24];
};
const getBackground = (value: number) => {
  const darkBlue = "#01345a";
  const lightBlue = "#1e96f2";
  const lightGray = "#e5e8eb";
  const darkGray = "#6c777f";

  const notSelectedGradient = `linear-gradient(90deg, ${lightGray} 0%, ${darkGray} 49.97%, ${lightGray} 100%)`;
  if (value === 50) return notSelectedGradient;

  const leftSelectedGradient = `linear-gradient(90deg, ${darkBlue} 0%, ${lightBlue} ${
    value + 40
  }%, ${lightGray} 100%)`;
  const rightSelectedGradient = `linear-gradient(90deg, ${lightGray} 0%, ${lightBlue} ${
    value - 40
  }%, ${darkBlue} 100%)`;

  if (value < 50) return leftSelectedGradient;
  if (value > 50) return rightSelectedGradient;
  return notSelectedGradient;
};
export const SliderUtils = { getEmojiSize, getBackground };
