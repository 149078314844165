import { Components } from "@thisgorilla/primate-ds";
import { NavigateFunction } from "react-router-dom";

import { API_CALLS } from ".";
import store, { actions } from "../../redux";
import { AuthProps } from "../../redux/slices/auth/auth";
import { ROUTES } from "../../utills/routes";
import { API_INSTANCES } from "../api_instance";
import { handleError } from "../errors";

const dispatch = store.dispatch;

const getStatus = () => {
  dispatch(actions.auth.setGetStatusAPI_STATUS("progress"));
  API_INSTANCES.AUTH.getStatus()
    .then((response) => {
      const isAuthenticated = response.data as AuthProps.GetStatusResponse;
      dispatch(actions.auth.setAuthState(isAuthenticated));

      if (!isAuthenticated.authenticated) {
        dispatch(actions.auth.setGetStatusAPI_STATUS("error"));
        return;
      }
      API_CALLS.ME.getUserInformation();
      dispatch(actions.auth.setGetStatusAPI_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.auth.setGetStatusAPI_STATUS("error"));
      handleError(error, "getStatus");
    });
};

const login = (
  data: AuthProps.LoginRequest,
  redirect_url: string,
  navigate: NavigateFunction
) => {
  dispatch(actions.auth.setLoginAPI_STATUS("progress"));
  API_INSTANCES.AUTH.login(data)
    .then(() => {
      dispatch(actions.auth.setLoginAPI_STATUS("success"));
      getStatus();
      navigate(redirect_url);
    })
    .catch((error) => {
      handleError(error, "login");
      dispatch(actions.auth.setLoginAPI_STATUS("error"));
    });
};

const logout = (navigate: NavigateFunction) => {
  dispatch(actions.auth.setLogoutAPI_STATUS("progress"));

  API_INSTANCES.AUTH.logout()
    .then(() => {
      dispatch(actions.auth.reset());
      getStatus();
      navigate(ROUTES.login);
      dispatch(actions.auth.setLogoutAPI_STATUS("success"));
    })
    .catch((error) => {
      handleError(error, "logout");
      dispatch(actions.auth.setLogoutAPI_STATUS("error"));
    });
};

const registerANewUser = (
  data: AuthProps.RegisterANewUserRequest,
  navigate: NavigateFunction
) => {
  dispatch(actions.auth.setRegisterAPI_STATUS("progress"));
  API_INSTANCES.AUTH.registerANewUser(data)
    .then(() => {
      dispatch(actions.auth.setRegisterAPI_STATUS("success"));
      getStatus();
      navigate(ROUTES.home);
    })
    .catch((error) => {
      handleError(error, "registerANewUser");
      dispatch(actions.auth.setRegisterAPI_STATUS("error"));
    });
};

const forgotPassword = (email: string) => {
  dispatch(actions.auth.setForgotPasswordAPI_STATUS("progress"));
  API_INSTANCES.AUTH.forgotPassword(email)
    .then(() => {
      dispatch(actions.auth.setForgotPasswordStep("otp"));
      dispatch(actions.auth.setResetFormValue({ email, otp: "" }));
      dispatch(actions.auth.setForgotPasswordAPI_STATUS("success"));
    })
    .catch((error) => {
      handleError(error, "forgotPassword");
      dispatch(actions.auth.setForgotPasswordAPI_STATUS("error"));
    });
};

const resetPassword = (
  data: AuthProps.ResetPasswordRequest,
  navigate: NavigateFunction
) => {
  dispatch(actions.auth.setResetPasswordAPI_STATUS("progress"));
  API_INSTANCES.AUTH.resetPassword(data)
    .then(() => {
      Components.Toast.toastSuccessMessage({
        message: "Your password has been reset. You’re logged in!",
      });
      dispatch(actions.auth.setResetPasswordAPI_STATUS("success"));
      getStatus();
      navigate(ROUTES.home);
    })
    .catch((error) => {
      handleError(error, "resetPassword");
      dispatch(actions.auth.setResetPasswordAPI_STATUS("error"));
    });
};

const checkOtp = (data: AuthProps.CheckOtpRequest) => {
  dispatch(actions.auth.setCheckOtpAPI_STATUS("progress"));
  API_INSTANCES.AUTH.checkOtp(data)
    .then(() => {
      dispatch(actions.auth.setForgotPasswordStep("reset"));
      dispatch(actions.auth.setResetFormValue(data));
      dispatch(actions.auth.setCheckOtpAPI_STATUS("success"));
    })
    .catch((error) => {
      handleError(error, "checkOtp");
      dispatch(actions.auth.setCheckOtpAPI_STATUS("error"));
    });
};

const resendOtp = (email: string) => {
  dispatch(actions.auth.setResendOtpAPI_STATUS("progress"));
  API_INSTANCES.AUTH.resendOtp(email)
    .then(() => {
      Components.Toast.toastSuccessMessage({
        message: "Resend Otp Successfully",
      });
      dispatch(actions.auth.setResendOtpAPI_STATUS("success"));
    })
    .catch((error) => {
      handleError(error, "resendOtp");
      dispatch(actions.auth.setResendOtpAPI_STATUS("error"));
    });
};

export const AUTH = {
  login,
  getStatus,
  registerANewUser,
  logout,
  forgotPassword,
  resetPassword,
  checkOtp,
  resendOtp,
};
