import { Components, Inputs } from "@thisgorilla/primate-ds";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { API_CALLS } from "../../../../../../API/api_calls";
import {
  actions,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../redux";
import {
  CommentList,
  PostCommentField,
  UpvoteDownvote,
} from "../../../../../Common";
import {
  FlexColumn,
  FlexRow,
  FlexRowSpaced,
} from "../../../../../Common/Styles";
import { PostCardProps } from "../PostCard";
import { INTIALCOMMENT } from "../../../../../../redux/slices/comments";

const sortingOption = [
  { label: "Most Recent", value: "most_recent" },
  { label: "Most Relevant", value: "most_relevant" },
  { label: "Most Popular", value: "most_popular" },
];

export const Footer = ({ postId }: PostCardProps.PostCardContent) => {
  const reactionsCount = 36;
  const retweetsCount = 20;

  const contentRef = useRef(null);
  const dispatch = useAppDispatch();
  const [observeCount, setObserveCount] = useState(0);
  const [isCommentShow, setIsCommentShow] = useState(false);
  const [commentSorting, setCommentSortings] = useState(sortingOption[0]);
  const { allPostComments } = useAppSelector((state) => state.comments);
  const { page_size, page, comments, remaining_comments, total_count } =
    allPostComments?.[postId] ?? INTIALCOMMENT;

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      setObserveCount((prev) => prev + 1);
    });

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [isCommentShow]);

  return (
    <FooterContainer $gap={10}>
      <Container>
        <UpvoteDownvote
          upvoteCount={0}
          downvoteCount={0}
          downVoteHandler={() => {}}
          upVoteHandler={() => {}}
        />
        <Components.Button
          iconPosition="left"
          iconType="MessageCircle"
          kind="Tertiary"
          color="Primary"
          size="Small"
          disabled={false}
          label={String(total_count)}
          onClick={() => {
            setIsCommentShow(!isCommentShow);
          }}
        />
        <Components.Button
          kind="Tertiary"
          iconPosition="left"
          color="Primary"
          disabled={false}
          iconType="Smile"
          label={`${reactionsCount}`}
          size="Small"
          onClick={() => {
            //TODO: open comment section
          }}
        />

        <Components.Button
          iconPosition="left"
          iconType="Repeat"
          kind="Tertiary"
          color="Primary"
          size="Small"
          disabled={false}
          label={`${retweetsCount}`}
          onClick={() => {
            //TODO: open comment section
          }}
        />
        <SaveAndShare $gap={0}>
          <Components.IconButton
            disabled={false}
            iconType="Bookmark"
            kind="Tertiary"
            onClick={() => {}}
            size="Small"
          />
          <Components.IconButton
            disabled={false}
            iconType="Share"
            kind="Tertiary"
            onClick={() => {}}
            size="Small"
          />
        </SaveAndShare>
      </Container>
      {(comments.length > 0 || isCommentShow) && (
        <FlexColumn $gap={10} ref={contentRef}>
          {isCommentShow && (
            <PostCommentField parent_comment_id={null} post_id={postId} />
          )}
          {comments.length > 0 && (
            <FlexColumn $gap={0}>
              <CommentSorting>
                <Inputs.Search
                  fieldName="comment-sorting"
                  handleSelect={(option) => {
                    if (option.label === commentSorting.label) return;
                    dispatch(actions.comments.resetComment(postId));
                    setCommentSortings(option);
                    API_CALLS.COMMENTS.getCommentsForAPost({
                      post_id: postId,
                      sort_by: commentSorting.value,
                      page: "1",
                      page_size: String(page_size),
                    });
                  }}
                  idPrefix="comment"
                  isRequired={false}
                  onChangeHandler={() => {}}
                  value={commentSorting.label}
                  size="Small"
                  options={sortingOption}
                  fieldType="Select"
                />
              </CommentSorting>
              <CommentList
                showReplyButton={true}
                comments={comments}
                observeCount={observeCount}
              />
              {remaining_comments > 0 && (
                <ButtonContainer>
                  <Components.Button
                    kind="Primary"
                    color="Neutral"
                    size="XS"
                    disabled={false}
                    label="Show more comments"
                    onClick={() => {
                      API_CALLS.COMMENTS.getCommentsForAPost({
                        post_id: postId,
                        sort_by: commentSorting.value,
                        page: String(page + 1),
                        page_size: String(page_size),
                      });
                    }}
                  />
                </ButtonContainer>
              )}
            </FlexColumn>
          )}
        </FlexColumn>
      )}
    </FooterContainer>
  );
};

const FooterContainer = styled(FlexColumn)`
  padding: ${({ theme }) => theme.spacing["spacing-5"].value}px
    ${({ theme }) => theme.spacing["spacing-3"].value}px;
`;
const Container = styled(FlexRowSpaced)``;
const ButtonContainer = styled.div`
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-none"].value}px;
`;
const SaveAndShare = styled(FlexRow)``;
const CommentSorting = styled.div`
  width: min-content;
  min-width: 132px;

  & input {
    min-height: ${({ theme }) => theme.spacing["spacing-6"].value}px;
    padding-top: ${({ theme }) => theme.spacing["spacing-05"].value}px;
    padding-bottom: ${({ theme }) => theme.spacing["spacing-05"].value}px;
    ${({ theme }) => theme.typography.Body.S};
    line-height: ${({ theme }) => theme.line_height["line-height-100"]}px;
  }

  & .icon-container {
    padding-top: 3px;
    pointer-events: none;
  }

  & input {
    caret-color: transparent;
  }
`;
