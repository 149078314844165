import instance from "../../axios/axios-user";
import { NewsFeedProps } from "../../redux/slices/newsfeed/NewsFeed";
import { API_PATHS } from "../api_paths";

const getNewsFeedPosts = ({
  page,
  page_size,
}: NewsFeedProps.getNewsFeedPostsPayload) =>
  instance(
    API_PATHS.newsfeed.getNewsFeedPosts + page + `?page_size=${page_size}`
  );

export const NEWSFEED = { getNewsFeedPosts };
