import store, { actions } from "../../redux";
import { MeProps } from "../../redux/slices/me/Me";
import { API_INSTANCES } from "../api_instance";
import { handleError } from "../errors";

const dispatch = store.dispatch;

const getAllUsers = () => {
  API_INSTANCES.ME.getAllUsers()
    .then()
    .catch((error) => handleError(error, "getAllUsers"));
};
const getMentions = () => {
  API_INSTANCES.ME.getMentions()
    .then()
    .catch((error) => handleError(error, "getMentions"));
};
const getPeopleYouMightKnow = () => {
  dispatch(actions.me.setPeopleYouMightKnow_API_STATUS("progress"));
  API_INSTANCES.ME.getPeopleYouMightKnow()
    .then((response) => {
      const data = response.data as MeProps.PeopleYouMightKnow;
      dispatch(actions.me.setPeopleYouMightKnow(data));
      dispatch(actions.me.setPeopleYouMightKnow_API_STATUS("success"));
    })
    .catch((error) => handleError(error, "getPeopleYouMightKnow"));
};
const getRecommendedJobs = () => {
  dispatch(actions.me.setRecommendedJobs_API_STATUS("progress"));
  API_INSTANCES.ME.getRecommendedJobs()
    .then((response) => {
      const data = response.data as MeProps.RecommendedJobs;
      dispatch(actions.me.setRecommendedJobs(data));
      dispatch(actions.me.setRecommendedJobs_API_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.me.setRecommendedJobs_API_STATUS("error"));
      handleError(error, "getRecommendedJobs");
    });
};
const getSuggestedUsers = () => {
  dispatch(actions.me.setSuggestedUsers_API_STATUS("progress"));

  API_INSTANCES.ME.getSuggestedUsers()
    .then((response) => {
      const data = response.data as MeProps.SuggestedUsers;
      dispatch(actions.me.setSuggestedUsers(data));
      dispatch(actions.me.setSuggestedUsers_API_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.me.setSuggestedUsers_API_STATUS("error"));
      handleError(error, "getSuggestedUsers");
    });
};
const getUserInformation = () => {
  API_INSTANCES.ME.getUserInformation()
    .then((response) => {
      const data = response.data as MeProps.User;
      dispatch(actions.me.setUser(data));
    })
    .catch((error) => handleError(error, "getUserInformation"));
};
const getYourInnerCircle = () => {
  dispatch(actions.me.setYourInnerCircleAPI_STATUS("progress"));

  API_INSTANCES.ME.getYourInnerCircle()
    .then((response) => {
      const data = response.data as MeProps.YourInnerCircle;
      dispatch(actions.me.setYourInnerCircle(data));
      dispatch(actions.me.setYourInnerCircleAPI_STATUS("success"));
    })
    .catch((error) => handleError(error, "getYourInnerCircle"));
};

export const ME = {
  getAllUsers,
  getMentions,
  getPeopleYouMightKnow,
  getRecommendedJobs,
  getSuggestedUsers,
  getUserInformation,
  getYourInnerCircle,
};
