import store, { actions } from "../../redux";
import { NewsFeedProps } from "../../redux/slices/newsfeed/NewsFeed";
import { API_INSTANCES } from "../api_instance";
import { handleError } from "../errors";

const dispatch = store.dispatch;

const getNewsFeedPosts = (payload: NewsFeedProps.getNewsFeedPostsPayload) => {
  dispatch(actions.newsfeed.setGetNewsFeedPostsApiLoading(true));
  API_INSTANCES.NEWSFEED.getNewsFeedPosts(payload)
    .then((response) => {
      const data = response.data as NewsFeedProps.getNewsFeedPostsResponse;
      dispatch(actions.newsfeed.setNewsFeedPost(data));
      dispatch(actions.newsfeed.setGetNewsFeedPostsApiLoading(false));
      Object.values(data.processed_posts).forEach((post) => {
        dispatch(
          actions.comments.setPostComment({
            post_id: post.ID,
            post_comment: post?.post_comment ?? [],
            total_count: post.MainCommentCount,
          })
        );
      });
    })
    .catch((error) => {
      dispatch(actions.newsfeed.setGetNewsFeedPostsApiLoading(false));
      handleError(error, "getNewsFeedPosts");
    });
};

export const NEWSFEED = { getNewsFeedPosts };
