import { Components } from "@thisgorilla/primate-ds";
import { useContext } from "react";
import styled, { useTheme } from "styled-components";

import { CreatePostModalContext } from "../..";
import { Divider } from "../../../../../../../../commonStyles";
import { useAppSelector } from "../../../../../../../../redux";
import Input from "../../../../../../../Input";
import SliderDropdown from "./SliderDropdown";
import SliderPreview from "./SliderPreview";

const CreateSlider = () => {
  const { typography } = useTheme();
  const { emojis } = useAppSelector((state) => state.me);
  const { setSliderOpen, formik } = useContext(CreatePostModalContext);
  const { values, setFieldValue } = formik;
  const { right_emoji_id, left_emoji_id, slider_question } = values;

  return (
    <CreateSlidetContainer>
      <ButtonContainer>
        <Components.IconButton
          disabled={false}
          iconType="X"
          kind="Secondary"
          size="Small"
          onClick={() => {
            setSliderOpen(false);
            formik.setFieldValue("slider_question", "");
            formik.setFieldError("slider_question", undefined);
          }}
        />
      </ButtonContainer>
      <Divider />
      <Input
        fieldName="slider_question"
        idPrefix="create-slider"
        isRequired
        placeholder="Ask your question"
        label="Question"
        size="Large"
        maxCount={140}
        helpText={`${
          formik?.values?.slider_question?.length ?? 0
        }/140 characters`}
        helpTextKind={
          formik?.values?.slider_question?.length ? "Positive" : "Normal"
        }
      />
      <Divider />
      <Components.Text
        text="Add rating options"
        style={{ tokenStyle: typography.Body.M }}
      />
      <SliderOptionContainer>
        <SliderDropdown
          onValueChange={(value) => setFieldValue("left_emoji_id", value)}
          selectedId={left_emoji_id}
          otherSelectedId={right_emoji_id}
        />
        <SliderDropdown
          onValueChange={(value) => setFieldValue("right_emoji_id", value)}
          selectedId={right_emoji_id}
          otherSelectedId={left_emoji_id}
        />
      </SliderOptionContainer>
      {slider_question?.length > 0 && (
        <SliderPreview
          emojiValue={[
            emojis[left_emoji_id]?.name ?? "none",
            emojis[right_emoji_id]?.name ?? "none",
          ]}
        />
      )}
    </CreateSlidetContainer>
  );
};

export default CreateSlider;
const SliderOptionContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const CreateSlidetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-6"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  box-shadow: ${({ theme }) => theme.boxShadows["box-shadow-s"]};
`;
