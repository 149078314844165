import { Input as DSInput } from "@thisgorilla/primate-ds/dist/inputs/Input/Input";
import { Field, FieldProps } from "formik";
import { useState } from "react";

import { InputProps } from "./Input";
import { PasswordStrengthMeter } from "./PasswordStrengthMeter";
import { checkPasswordStrength } from "./utils";

const Input = (props: InputProps.IProps) => {
  const {
    fieldName,
    helpText,
    helpTextKind,
    onKeyDown = () => {},
    showPasswordStrength = false,
    maxCount,
  } = props;
  const [isFocused, setFocused] = useState(false);

  return (
    <Field name={fieldName} key={fieldName}>
      {({ field, meta, form }: FieldProps) => {
        const { value } = field;
        const { error } = meta;
        const { setFieldValue, validateField } = form;
        return (
          <>
            <DSInput
              {...{
                ...props,
                value,
                maxCount,
                helpText: error ? error : helpText,
                helpTextKind: error ? "Error" : helpTextKind,
                onChangeHandler: (e) => {
                  setFieldValue(fieldName, e, false).then(() => {
                    validateField(fieldName);
                  });
                },
              }}
              onKeyDown={onKeyDown}
              onFocus={() => setFocused(true)}
              onBlur={() => {
                
                validateField(fieldName);
              }}
            />
            {showPasswordStrength && isFocused && (
              <PasswordStrengthMeter strength={checkPasswordStrength(value)} />
            )}
          </>
        );
      }}
    </Field>
  );
};

export default Input;
