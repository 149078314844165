import { ThisGorillaUtils } from "@thisgorilla/primate-ds";
import { API_STATUS } from "../../redux/common";
import { GetStarted } from "./1-getStarted";
import { Location } from "./1-location";
import { JobTitle } from "./2-jobTitle";
import { Industry } from "./3-Industry";
import { Interests } from "./4-interests";
import { RecommendedPeople } from "./5-recommendedPeople";
import { OnboardingComponentProps } from "./OnboardingComponent";

const stepComponent: OnboardingComponentProps.StepComponent = {
  get_started: <GetStarted />,
  location: <Location />,
  job_title: <JobTitle />,
  industry: <Industry />,
  interests: <Interests />,
  people_recommendations: <RecommendedPeople />,
};

//#region Validation
const validationFields: OnboardingComponentProps.ValidationFields = {
  get_started: ["first_name", "last_name", "location"],
  location: ["location"],
  job_title: ["job_titles"],
  industry: ["industries"],
  interests: ["interests"],
  people_recommendations: [],
};
//#endregion

export const ONBOARDING_ERRORS = {
  emptyLocation:
    "We couldn't find that location. Please check your spelling or try a nearby area.",
  emptyIndustry:
    "We couldn't find that industry. Please check your spelling or try a related term.",
  emptyJobTitle:
    "We couldn't find that job title. Please check your spelling or try a related term.",
  emptyInterest:
    "We couldn't find that interest. Please check your spelling or try a related term.",
  emptyFirstName: "First name is required",
  emptyLastName: "Last name is required",
};

type getShowEmptyOptionsProps = {
  inputLength: number;
  optionsLength: number;
  apiStatus: API_STATUS;
};
const getShowEmptyOptions = ({
  inputLength,
  optionsLength,
  apiStatus,
}: getShowEmptyOptionsProps) => {
  return (
    apiStatus !== "idle" &&
    apiStatus !== "progress" &&
    optionsLength === 0 &&
    inputLength > ThisGorillaUtils.DEFAULT_CHARACTER_COUNT_BEFORE_SEARCH
  );
};

export const OnboardingUtils = {
  stepComponent,
  validationFields,
  ONBOARDING_ERRORS,
  getShowEmptyOptions,
};
