import store, { actions } from "../../redux";
import { SavedItemsProps } from "../../redux/slices/savedItems/SavedItems";
import { API_INSTANCES } from "../api_instance";
import { handleError } from "../errors";

const dispatch = store.dispatch;

const createSavedItem = () => {
  dispatch(actions.savedItems.setCreateSavedItemAPI_STATUS("progress"));

  API_INSTANCES.SAVED_ITEMS.createSavedItem()
    .then((response) => {
      const data = response.data as SavedItemsProps.CreateSavedItemResponse;
      dispatch(actions.savedItems.setCreateSavedItemResponse(data));
    })
    .catch((error) => handleError(error, "createSavedItem"));
};
const deleteSavedItem = () => {
  dispatch(actions.savedItems.setDeleteSavedItemAPI_STATUS("progress"));

  API_INSTANCES.SAVED_ITEMS.deleteSavedItem()
    .then()
    .catch((error) => handleError(error, "deleteSavedItem"));
};
const getSavedItemsCounts = () => {
  dispatch(actions.savedItems.setGetSavedItemsCountsAPI_STATUS("progress"));

  API_INSTANCES.SAVED_ITEMS.getSavedItemsCounts()
    .then((response) => {
      const data = response.data as SavedItemsProps.SavedItemsCounts;
      dispatch(actions.savedItems.setSavedItemsCounts(data));
      dispatch(actions.savedItems.setGetSavedItemsCountsAPI_STATUS("success"));
    })
    .catch((error) => handleError(error, "getSavedItemsCounts"));
};

export const SAVED_ITEMS = {
  createSavedItem,
  deleteSavedItem,
  getSavedItemsCounts,
};
