import store, { actions } from "../../redux";
import { PollsProps } from "../../redux/slices/polls/Polls";
import { PostsProps } from "../../redux/slices/posts/Posts";
import { API_INSTANCES } from "../api_instance";
import { handleError } from "../errors";

const dispatch = store.dispatch;

const createANewPoll = (data: PollsProps.CreateANewPollPayload) =>
  API_INSTANCES.POLLS.createANewPoll(data)
    .then((response) => {
      const data = response.data as PollsProps.CreateANewPollResponse;
      dispatch(actions.polls.setCreateANewPollResponse(data));
    })
    .catch((error) => handleError(error, createANewPoll.name));

const showAPoll = (poll_id: string) =>
  API_INSTANCES.POLLS.showAPoll(poll_id)
    .then((response) => {
      const data = response.data as PollsProps.ShowAPollResponse;
      console.log(data);
    })
    .catch((error) => handleError(error, showAPoll.name));

const submitAnswerForAPoll = (
  payload: PollsProps.SubmitAnswerForAPollPayload
) => {
  API_INSTANCES.POLLS.submitAnswerForAPoll(payload)
    .then((response) => {
      const data = response.data as PostsProps.PollInteraction;
      dispatch(actions.newsfeed.updatePollSubmitAnswerResponse(data));
    })
    .catch((error) => {
      handleError(error, "submitAnswerForAPoll");
    });
};

export const POLLS = { createANewPoll, showAPoll, submitAnswerForAPoll };
