import { Components } from "@thisgorilla/primate-ds";
import { useTheme } from "styled-components";
import { FlexRowCenter } from "../Common/Styles";

const Title = ({ text }: { text: string }) => {
  const { typography } = useTheme();

  return (
    <Components.Text
      text={text}
      style={{ tokenStyle: typography.Heading.L }}
      fontWeight="font-weight-medium"
    />
  );
};
const Description = ({ text }: { text: string }) => {
  const { typography } = useTheme();

  return (
    <Components.Text style={{ tokenStyle: typography.Body.M }} text={text} />
  );
};
const Instruction = ({ text }: { text: string }) => {
  const { typography, Color } = useTheme();
  return (
    <FlexRowCenter $gap={4}>
      <Components.IconButton
        kind="Tertiary"
        size="Small"
        disabled={false}
        iconType="Info"
        onClick={() => {}}
        IconButtonColor={Color.icon["icon-tertiary"].value}
      />
      <Components.Text
        style={{ tokenStyle: typography.Code.M }}
        text={text}
        color={Color.text["text-tertiary"].value}
      />
    </FlexRowCenter>
  );
};

export const OnboardingCommon = { Title, Description, Instruction };
