import { ThisGorillaUtils } from "@thisgorilla/primate-ds";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { SliderDirection } from "../redux/common";

type WithSliderProps = {
  direction: SliderDirection;
  children: React.ReactNode;
  stepKey: string;
  animationDuration?: number;
};

export const WithSlider = (props: WithSliderProps) => {
  const {
    direction,
    children,
    stepKey,
    animationDuration = ThisGorillaUtils.DEFAULT_SLIDE_DURATION,
  } = props;

  const [displayContent, setDisplayContent] = useState(children);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayContent(children);
    }, ThisGorillaUtils.DEFAULT_SLIDE_DURATION / 2);

    return () => clearTimeout(timer);
  }, [stepKey, children]);

  return (
    <TransitionWrapper>
      <SlideContent
        key={stepKey}
        $direction={direction}
        $animationDuration={animationDuration}
      >
        {displayContent}
      </SlideContent>
    </TransitionWrapper>
  );
};

const TransitionWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`;
const SlideContent = styled.div<{
  $direction: SliderDirection;
  $animationDuration: number;
}>`
  width: 100%;
  animation: ${({ $direction }) =>
      $direction === "to-right" ? "slideInRight" : "slideInLeft"}
    ${({ $animationDuration }) => $animationDuration}ms ease-in-out;

  @keyframes slideInRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;
