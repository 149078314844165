const baseUrl = process.env.REACT_APP_GORRILA_API;

//Authentication
const login = baseUrl + "/auth";
const logout = baseUrl + "/auth";
const checkOtp = baseUrl + "/auth/check-otp";
const forgotPassword = baseUrl + "/auth/forgot-password";
const registerANewUser = baseUrl + "/auth/register";
const resendOtp = baseUrl + "/auth/resend-otp";
const resetPassword = baseUrl + "/auth/reset-password";
const getStatus = baseUrl + "/auth/status";
const socialAuthGoogle = baseUrl + "/auth/google";
const socialAuthApple = baseUrl + "/auth/apple";
const Authentication = {
  login,
  logout,
  checkOtp,
  forgotPassword,
  registerANewUser,
  resendOtp,
  resetPassword,
  getStatus,
  socialAuthGoogle,
  socialAuthApple,
};

//Me
const getUserInformation = baseUrl + "/me";
const getPeopleYouMightKnow = baseUrl + "/me/discover";
const getYourInnerCircle = baseUrl + "/me/inner-circle";
const getRecommendedJobs = baseUrl + "/me/jobs";
const getMentions = baseUrl + "/me/mentions";
const getSuggestedUsers = baseUrl + "/me/suggested";
const getAllUsers = baseUrl + "/me/users";
const me = {
  getUserInformation,
  getPeopleYouMightKnow,
  getYourInnerCircle,
  getRecommendedJobs,
  getMentions,
  getSuggestedUsers,
  getAllUsers,
};

//Connections
const getConnections = baseUrl + "users/connections";
const followAUser = baseUrl + "/users/follow";
const getFollowCounts = baseUrl + "/follow/counts";
const getFollowingUsers = baseUrl + "/users/following";
const unfollowAUser = baseUrl + "/users/unfollow";
const connections = {
  getConnections,
  followAUser,
  getFollowCounts,
  getFollowingUsers,
  unfollowAUser,
};

//Saved Items
const getSavedItemsCounts = baseUrl + "/saved_items";
const createSavedItem = baseUrl + "/saved_items";
const deleteSavedItem = baseUrl + "/saved_items";
const savedItems = {
  getSavedItemsCounts,
  createSavedItem,
  deleteSavedItem,
};

//posts
const post = baseUrl + "/posts/";
const userPosts = baseUrl + "/user/posts/";
const repostAPost = baseUrl + "/posts/repost";
const createPostDraft = baseUrl + "/posts/draft";
const activateAPost = baseUrl + "/posts/activate";
const getPostImage = baseUrl + "/images/posts/";
const rateInteractions = baseUrl + "/rate_interactions";

const posts = {
  post,
  userPosts,
  repostAPost,
  createPostDraft,
  activateAPost,
  getPostImage,
  rateInteractions,
};

//Onboarding
const getGeoLocationData = baseUrl + "/location/geolocation";
const queryCityByUserInput = baseUrl + "/location/query-city";
const saveCityLocation = baseUrl + "/location/save-city";

const getUserIndustries = baseUrl + "/industries/user_industries";
const searchIndustries = baseUrl + "/industries/search";
const addUserIndustries = baseUrl + "/industries/add";

const getUserInterests = baseUrl + "/interests/user_interests";
const searchInterests = baseUrl + "/interests/search";
const addUserInterests = baseUrl + "/interests/add";

const getUserJobTitles = baseUrl + "/job-titles/titles";
const searchJobTitles = baseUrl + "/job-titles/search";
const addUserJobTitles = baseUrl + "/job-titles/add";

const getUserProfile = baseUrl + "/profile/user";

const onboarding = {
  getGeoLocationData,
  queryCityByUserInput,
  saveCityLocation,

  getUserIndustries,
  searchIndustries,
  addUserIndustries,

  getUserInterests,
  searchInterests,
  addUserInterests,

  getUserJobTitles,
  searchJobTitles,
  addUserJobTitles,

  getUserProfile,
};

//NLP - AI Recommendations
const getModelUsers = baseUrl + "/nlp/recommended-users";

const nlp = { getModelUsers };

//polls
const CreateANewPoll = baseUrl + "/polls";
const poll = baseUrl + "/polls";

const polls = { CreateANewPoll, poll };

//comment
const comments = baseUrl + "/comments/";

const comment = {
  comments,
};

//newsfeed
const getNewsFeedPosts = baseUrl + "/news-feed/";

const newsfeed = {
  getNewsFeedPosts,
};
//Info
const getEntityInfo = baseUrl + "/info/";

const info = { getEntityInfo };

export const API_PATHS = {
  Authentication,
  me,
  connections,
  savedItems,
  posts,
  onboarding,
  nlp,
  polls,
  comment,
  newsfeed,
  info,
};
