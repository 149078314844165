import { Components } from "@thisgorilla/primate-ds";
import { useContext } from "react";
import { ReportCommentContext } from "..";
import { useGoBackToPrevStep } from "../useGoBackToPrevStep";
import { useGoToNextStep } from "../useGoToNextStep";

const BackButton = () => {
  const { goBackToPrevStep } = useGoBackToPrevStep();
  const { setDirection } = useContext(ReportCommentContext);

  return (
    <Components.Button
      kind="Primary"
      color="Neutral"
      disabled={false}
      label="Back"
      onClick={() => {
        goBackToPrevStep();
        setDirection("to-left");
      }}
      size="Medium"
      iconPosition="left"
      iconType="ArrowLeft"
    />
  );
};

const SubmitButton = () => {
  const { goToNextStep } = useGoToNextStep();

  return (
    <Components.Button
      kind="Primary"
      color="Primary"
      disabled={false}
      label="Submit"
      onClick={() => {
        // TODO: submit the report
        goToNextStep();
      }}
      size="Medium"
    />
  );
};

const DoneButton = () => {
  const { setReportCommentOpen } = useContext(ReportCommentContext);

  return (
    <Components.Button
      kind="Primary"
      color="Primary"
      disabled={false}
      label="Done"
      onClick={() => {
        setReportCommentOpen(false);
      }}
      size="Medium"
    />
  );
};

export const FooterButtons = { BackButton, SubmitButton, DoneButton };
