import { Inputs } from "@thisgorilla/primate-ds";
import { CommonFieldProps } from "@thisgorilla/primate-ds/dist/inputs/CommonField";
import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { API_CALLS } from "../../../API/api_calls";
import { actions, useAppDispatch, useAppSelector } from "../../../redux";
import { OnboardingProps } from "../../../redux/slices/onboarding/onboarding";
import { InputContainer } from "../../Common/Styles";
import { OnboardingComponentProps } from "../OnboardingComponent";
import { ONBOARDING_ERRORS, OnboardingUtils } from "../utils";

type From = Extract<OnboardingProps.Step, "get_started" | "location">;
type useLocationFieldProps = { from: From };

export const useLocationField = ({ from }: useLocationFieldProps) => {
  const dispatch = useAppDispatch();
  const {
    cityOptions,
    location,
    selectedLocationId,
    queryCityByUserInput_API_STATUS,
    selectedCountryCode,
  } = useAppSelector((state) => state.onboarding);

  const { values } = useFormikContext<OnboardingComponentProps.FormValues>();
  const [locationInput, setLocationInput] = useState(values.location || "");

  useEffect(() => {
    if (location.city_id !== 0) return;
    API_CALLS.ONBOARDING.getGeoLocationData();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    dispatch(actions.onboarding.setQueryCityByUserInput_API_STATUS("idle"));
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!location.combined) return;
    if (!location.country_code) return;

    setLocationInput(location.combined?.split(",")[0]);
  }, [location]);

  const options = useMemo(() => {
    if (!cityOptions || !cityOptions.length) return [];

    return cityOptions.map(
      (cityOption) =>
        ({
          label: cityOption.city,
          value: cityOption,
        } as CommonFieldProps.OptionProps)
    );
  }, [cityOptions]);

  const emptyOptions: CommonFieldProps.OptionProps[] = [
    {
      label: ONBOARDING_ERRORS.emptyLocation,
      value: {} as OnboardingProps.Location,
    },
  ];

  const { setFieldTouched, touched } =
    useFormikContext<OnboardingComponentProps.FormValues>();

  const showErrorMessage =
    from === "get_started" && touched.location && !selectedLocationId;
  const showEmptyOptions = OnboardingUtils.getShowEmptyOptions({
    inputLength: locationInput?.length,
    optionsLength: options?.length,
    apiStatus: queryCityByUserInput_API_STATUS,
  });

  return (
    <InputContainer>
      <Inputs.CountrySelector
        fieldName="location"
        idPrefix="location"
        isRequired={true}
        placeholder="City"
        value={locationInput || (location && location.city)}
        onChangeHandler={(value) => {
          setLocationInput(value);
          dispatch(actions.onboarding.removeSelectedLocation());
        }}
        onBlur={() => setFieldTouched("location", true)}
        options={showEmptyOptions ? emptyOptions : options}
        citySearchFunction={(city_name) => {
          if (!selectedCountryCode) {
            setFieldTouched("location", true);
            return;
          }

          API_CALLS.ONBOARDING.queryCityByUserInput({
            city_name,
            country_code: selectedCountryCode,
          });
        }}
        handleCitySelect={({ value }) => {
          if (!value) return;

          setLocationInput(value.city);
          dispatch(actions.onboarding.clearCityOptions());
          dispatch(actions.onboarding.setSelectedLocation(value));
        }}
        handleCountrySelect={({ value }) => {
          const isCountryChanged = selectedCountryCode !== value;
          if (!isCountryChanged) return;

          dispatch(actions.onboarding.clearCityOptions());
          dispatch(actions.onboarding.setSelectedCountryCode(value));
          dispatch(actions.onboarding.removeSelectedLocation());
          setLocationInput("");
        }}
        selectedCountry={selectedCountryCode}
        scrollContainerId="onboarding-step-container"
      />
      {showErrorMessage && (
        <Inputs.CommonField.HelpText
          kind="Error"
          content={
            !selectedCountryCode
              ? "Please select a country before searching for a location"
              : "Please select a location"
          }
        />
      )}
    </InputContainer>
  );
};
