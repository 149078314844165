import { Components } from "@thisgorilla/primate-ds";
import React from "react";
import styled, { useTheme } from "styled-components";
import { useAppSelector } from "../../../redux";
import { SliderProps } from "./Slider";

type DataPoint = { x: number; y: number };
export const Curve: React.FC<SliderProps.Curve> = ({
  width = 300,
  aggregatePercent,
  postId,
}) => {
  const data: DataPoint[] = [
    { x: 0, y: 0 },
    { x: aggregatePercent, y: 100 },
    { x: 100, y: 0 },
  ];

  const height = 30;
  const xMin = Math.min(...data.map((d) => d.x));
  const xMax = Math.max(...data.map((d) => d.x));
  const yMin = Math.min(...data.map((d) => d.y));
  const yMax = Math.max(...data.map((d) => d.y));
  const scaleX = (x: number) => ((x - xMin) / (xMax - xMin)) * width;
  const scaleY = (y: number) => height - ((y - yMin) / (yMax - yMin)) * height;
  const adjustedData = [
    { x: xMin, y: data[0].y },
    ...data,
    { x: xMax, y: data[data.length - 1].y },
  ];
  const topPath =
    `M 0,${scaleY(adjustedData[0].y)} ` +
    adjustedData
      .map((d, i, arr) => {
        if (i === 0) return "";
        const prev = arr[i - 1];
        const cx = (scaleX(prev.x) + scaleX(d.x)) / 2;
        const cy = (scaleY(prev.y) + scaleY(d.y)) / 2;
        return `Q ${scaleX(prev.x)},${scaleY(prev.y)} ${cx},${cy}`;
      })
      .join(" ");
  const rightPath = `M ${width},${scaleY(
    adjustedData[adjustedData.length - 1].y
  )} L ${width},${height}`;
  const bottomPath = `M ${width},${height} L 0,${height}`;
  const leftPath = `M 0,${height} L 0,${scaleY(adjustedData[0].y)}`;
  const fillPath =
    topPath +
    ` L ${width},${scaleY(adjustedData[adjustedData.length - 1].y)}` +
    ` L ${width},${height}` +
    ` L 0,${height}` +
    ` L 0,${scaleY(adjustedData[0].y)} Z`;

  const { processed_posts } = useAppSelector((state) => state.newsfeed);
  const original_post = processed_posts[postId];
  const users_profile_pictures =
    original_post?.interactions?.rating_interaction?.users_profile_pictures;
  const showUserProfiles =
    users_profile_pictures && users_profile_pictures.length > 0;
  const srcCountToBeRendered =
    users_profile_pictures.length > 2 ? 2 : users_profile_pictures.length;

  const { Color } = useTheme();
  const color = Color.background.brand.default.value;

  return (
    <>
      <CircleContainer
        className="circle-container"
        $aggregatePercent={aggregatePercent}
        $height={height - 10}
      >
        <Circle />
        {showUserProfiles && <VerticalLine />}
        {showUserProfiles && (
          <AvatarGroup>
            <Components.AvatarGroup
              src={users_profile_pictures}
              size="XXSmall-20"
              srcCountToBeRendered={srcCountToBeRendered}
            />
          </AvatarGroup>
        )}
      </CircleContainer>
      <CurveContainer width={width > 15 ? width - 15 : width} height={height}>
        <defs>
          <linearGradient id="bgGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={color} stopOpacity="0.2" />
            <stop offset="100%" stopColor="white" stopOpacity="0.6" />
          </linearGradient>
        </defs>
        <path d={fillPath} fill="url(#bgGradient)" stroke="none" />
        <path d={topPath} stroke={color} strokeWidth={2} fill="none" />
        <path d={rightPath} stroke="white" strokeWidth={2} fill="none" />
        <path d={bottomPath} stroke="white" strokeWidth={2} fill="none" />
        <path d={leftPath} stroke="white" strokeWidth={2} fill="none" />
      </CurveContainer>
    </>
  );
};

const CurveContainer = styled.svg`
  position: absolute;
  bottom: 6px;
  left: 0;
`;
const CircleContainer = styled.div<{
  $height: number;
  $aggregatePercent: number;
}>(
  ({ $aggregatePercent, $height }) => `
  position: absolute;
  bottom: ${$height}px;
  left: calc(
    ${$aggregatePercent}% - ${
    $aggregatePercent === 100 ? 20 : $aggregatePercent === 0 ? 8 : 0
  }px
  );
  z-index: 1;
`
);
const Circle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${({ theme }) =>
    theme.Color.background.brand.default.value};
  border: 2px solid white;
`;
const VerticalLine = styled.div`
  width: 2px;
  height: 16px;
  background-color: ${({ theme }) =>
    theme.Color.background.brand.default.value};
  position: absolute;
  left: 8px;
  bottom: 8px;
`;
const AvatarGroup = styled.div`
  position: absolute;
  bottom: 27px;
  right: 0;
  transform: translateX(50%);
`;
