import { createContext, useEffect } from "react";
import styled from "styled-components";

import { API_CALLS } from "../../../../API/api_calls";
import { actions, useAppDispatch, useAppSelector } from "../../../../redux";
import { CenterPanelProps } from "./CenterPanel";
import { DefaultPost } from "./DefaultPost";
import { PostCard } from "./PostCard";

export const CenterPanelContext = createContext({} as CenterPanelProps.Context);
export const CenterPanel = () => {
  const dispatch = useAppDispatch();
  const { remaining_posts, page, getNewsFeedPostApiLoading, page_size } =
    useAppSelector((state) => state.newsfeed);

  useEffect(() => {
    API_CALLS.NEWSFEED.getNewsFeedPosts({ page, page_size });
  }, [page, page_size]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          !getNewsFeedPostApiLoading &&
            remaining_posts > 0 &&
            dispatch(actions.newsfeed.setPage(page + 1));
        }
      },
      { threshold: 0 }
    );

    const newsFeedListing = document.getElementById("newsfeedlisting");
    if (newsFeedListing) {
      const children = newsFeedListing.children || [];
      const childrenCount = children.length;
      if (children) {
        observer.observe(children[childrenCount - 2]);
      }
    }
    return () => observer.disconnect();
  }, [getNewsFeedPostApiLoading, page, dispatch, remaining_posts]);

  return (
    <CenterPanelContext.Provider value={{}}>
      <Container>
        <DefaultPost />
        <PostCard />
      </Container>
    </CenterPanelContext.Provider>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  flex: 1;
`;
