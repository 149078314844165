import { useFormikContext } from "formik";
import { API_CALLS } from "../../../API/api_calls";
import { useAppSelector } from "../../../redux";
import { OnboardingProps } from "../../../redux/slices/onboarding/onboarding";
import { OnboardingComponentProps } from "../OnboardingComponent";

export const useSaveDataToBackend = () => {
  const onboarding = useAppSelector((state) => state.onboarding);
  const { currentStep, selectedLocationId, job_titles, industries, interests } =
    onboarding;
  const { values } = useFormikContext<OnboardingComponentProps.FormValues>();

  return () => {
    switch (currentStep) {
      case "get_started":
        API_CALLS.ONBOARDING.saveCityLocation({
          city_id: String(selectedLocationId),
          additional_user_data: {
            first_name: values.first_name,
            last_name: values.last_name,
          },
        });
        break;
      case "location":
        API_CALLS.ONBOARDING.saveCityLocation({
          city_id: String(selectedLocationId),
          additional_user_data: {
            first_name: values.first_name,
            last_name: values.last_name,
          },
        });
        break;
      case "job_title":
        const newJobTitles: OnboardingProps.addJobTitleRequest[] =
          job_titles.map((jobTitle) => {
            if (jobTitle.user_created) return { title: jobTitle.title };
            return { title: jobTitle.title, id: jobTitle.id };
          });
        API_CALLS.ONBOARDING.addUserJobTitles({
          job_titles: newJobTitles,
        });
        break;
      case "industry":
        API_CALLS.ONBOARDING.addUserIndustries({
          industries: industries,
        });
        break;
      case "interests":
        API_CALLS.ONBOARDING.addUserInterests({
          interests: interests,
        });
        break;
      default:
        break;
    }
  };
};
