import { Components } from "@thisgorilla/primate-ds";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { API_CALLS } from "../../../../../../../../API/api_calls";
import { API_PATHS } from "../../../../../../../../API/api_paths";
import {
  actions,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux";
import { PostsProps } from "../../../../../../../../redux/slices/posts/Posts";
import { getImageBase64 } from "../../../../../../../../utils";
import ReUploadButton from "./ReUploadButton";

const ImgPreview = ({ file, id, status }: PostsProps.UploadedFiles) => {
  const dispatch = useAppDispatch();
  const isUploaded = status === "fulfilled";
  const { post_id } = useAppSelector((state) => state.posts);
  const [url, setUrl] = useState(
    isUploaded ? API_PATHS.posts.getPostImage + id : ""
  );

  useEffect(() => {
    if (isUploaded) {
      setUrl(API_PATHS.posts.getPostImage + id);
    } else {
      getImageBase64(file).then((url) => setUrl(url));
    }
  }, [file, isUploaded, id]);

  return (
    <ImageContainer id={id}>
      <Image $src={url} />
      <DeleteButton>
        <Components.IconButton
          disabled={false}
          iconType="X"
          kind="Opacity"
          onClick={() => {
            if (isUploaded) {
              API_CALLS.POSTS.deleteAnImageForAPost({
                image_id: id,
                post_id,
              });
            } else {
              dispatch(actions.posts.setDeleteUploadedFile(id));
            }
          }}
          size="XXS"
        />
      </DeleteButton>

      {!isUploaded && <ReUploadButton file={file} id={id} size="XS" />}
    </ImageContainer>
  );
};

export default ImgPreview;

const ImageContainer = styled.div`
  position: relative;
`;
const DeleteButton = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  z-index: 1;
  & > button {
    box-shadow: ${({ theme }) => theme.boxShadows["box-shadow-m"]};
  }
`;

const Image = styled.div<{ $src: string }>`
  display: block;
  width: 70px;
  height: 70px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  background: url(${({ $src }) => $src}) lightgray 50% / cover no-repeat;
`;
