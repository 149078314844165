import { InputsProps } from "@thisgorilla/primate-ds";
import * as Yup from "yup";

import { SchedulePostModalProps } from "./SchedulePostModal";

const dateDescription = (date: Date) => {
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    year:"numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "long",
  })
    .formatToParts(date)

    .map((part) => (part.value === " at " ? " " : part.value))
    .join("");
  return formattedDate + ", based on your location";
};

const createDate = (dateData: SchedulePostModalProps.FormData) => {
  const { year, month, day, hour, minute } = dateData;
  const monthIndex = new Date(`${month} 1, 2000`).getMonth();

  const date = new Date(
    parseInt(year),
    monthIndex,
    parseInt(day),
    parseInt(hour),
    parseInt(minute)
  );
  return date;
};

const CommonFieldProps = {
  idPrefix: "scheduled_post",
  isRequired: true,
  size: "Small" as InputsProps.CommonFieldProps.Size,
  maxOptionsHeight: 168,
};

const schedulePostValidation = Yup.object({
  year: Yup.string().required("Year is required"),
  month: Yup.string().required("Month is required"),
  day: Yup.string().required("Day is required"),
  hour: Yup.string().required("Hour is required"),
  minute: Yup.string().required("Minute is required"),
});
const requiredFileds = {
  year: true,
  month: true,
  day: true,
  hour: true,
  minute: true,
};

export const scheduledPostUtils = {
  createDate,
  dateDescription,
  CommonFieldProps,
  schedulePostValidation,
  requiredFileds,
};
