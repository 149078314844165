import { Formik, FormikValues } from "formik";
import { createContext, useEffect, useState } from "react";

import { API_CALLS } from "../../../../../../API/api_calls";
import {
  actions,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../redux";
import { enableScroll } from "../../../../../../utils";
import { CreatePostModalProps } from "./CreatePostModal";
import ModalContent from "./ModalContent";
import ModalHeader from "./ModalHeader";
import SchedulePostModal from "./SchedulePostModal";
import {
  CHOICE_COUNT_INITIAL_VALUE,
  createPostValidation,
  initialValues,
} from "./utils";

export const CreatePostModalContext = createContext(
  {} as CreatePostModalProps.Context
);

const CreatePostModal = (props: CreatePostModalProps.IProps) => {
  const dispatch = useAppDispatch();
  const { post_id } = useAppSelector((state) => state.posts);
  const { emojis } = useAppSelector((state) => state.me);
  const emojiIds = Object.keys(emojis);
  const [choiceCount, setChoiceCount] = useState(CHOICE_COUNT_INITIAL_VALUE);
  const [urlData, setUrlData] = useState<CreatePostModalProps.CustomURL | null>(
    null
  );

  const {
    setCreatePostOpen,
    pollOpen,
    setPollOpen,
    setImageUploadOpen,
    setSliderOpen,
    sliderOpen,
    schedulePostOpen,
  } = props;

  useEffect(() => {
    API_CALLS.POSTS.createPostDraft();
  }, []);

  const resetPostState = () => {
    dispatch(actions.posts.resetCreatePostData());
    setCreatePostOpen(false);
    setImageUploadOpen(false);
    setPollOpen(false);
    setSliderOpen(false);
    enableScroll();
  };

  const postSubmit = (values: FormikValues) => {
    const newValues = { ...values };
    delete newValues.duration;
    delete newValues.content;
    delete newValues.question;
    delete newValues.slider_question;
    delete newValues.right_emoji_id;
    delete newValues.left_emoji_id;
    delete newValues.delivery_date;
    const newChoice: FormikValues = {};

    Object.values(newValues).forEach((choice, index) => {
      newChoice[`choice_${index + 1}`] = choice;
    });

    API_CALLS.POSTS.activateAPost(
      {
        content: values.content || null,
        delivery_date: values.delivery_date
          ? values.delivery_date.toISOString()
          : null,
        node_tree: "",
        post_id,
        topics: [],
        url_id: urlData?.id ?? null,
        poll: pollOpen
          ? {
              ...newChoice,
              post_id,
              question: values.question,
              duration: values.duration,
            }
          : null,
        slider: sliderOpen
          ? {
              left_emoji_id: values.left_emoji_id,
              post_id,
              question: values.slider_question,
              right_emoji_id: values.right_emoji_id,
            }
          : null,
      },
      resetPostState
    );
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        left_emoji_id: emojiIds[0],
        right_emoji_id: emojiIds[1],
      }}
      validationSchema={createPostValidation({
        choiceCount,
        pollOpen,
        sliderOpen,
      })}
      onSubmit={postSubmit}
      validateOnChange={false}
      isInitialValid={false}
      validateOnBlur
    >
      {(formik) => {
        const contextValue: CreatePostModalProps.Context = {
          ...props,
          urlData,
          setUrlData,
          choiceCount,
          setChoiceCount,
          formik,
          resetPostState,
        };

        return (
          <CreatePostModalContext.Provider value={contextValue}>
            {schedulePostOpen ? (
              <SchedulePostModal />
            ) : (
              <>
                <ModalHeader />
                <ModalContent />
              </>
            )}
          </CreatePostModalContext.Provider>
        );
      }}
    </Formik>
  );
};

export default CreatePostModal;
