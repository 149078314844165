import { Components } from "@thisgorilla/primate-ds";
import { useState } from "react";
import styled, { useTheme } from "styled-components";

type CounterProps = { title: string; count: number | string };
export const Counter = ({ title, count }: CounterProps) => {
  const { typography, Color } = useTheme();
  const [isHovered, setHovered] = useState(false);
  const textColor = isHovered
    ? Color.text["text-emphasize-brand-bolder"].value
    : undefined;

  return (
    <Container
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Components.Text
        text={title}
        style={{ tokenStyle: typography.Heading.XXS }}
        fontWeight="font-weight-semibold"
        color={textColor}
        isunderlined={isHovered}
      />
      <Components.Counter color="Dark" kind="Fill" count={count} size="Small" />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  cursor: default;
  &:hover {
    background: ${({ theme }) =>
      theme.Color.elevation.surface["surface-primary-emphasize-hover"].value};
  }
`;
