import { Inputs } from "@thisgorilla/primate-ds";
import { Field, FieldProps } from "formik";
import { useContext, useState } from "react";
import styled from "styled-components";

import { CreatePostModalContext } from "..";
import instance from "../../../../../../../axios/axios-user";
import { hideScrollBar } from "../../../../../../../commonStyles";
import { useAppSelector } from "../../../../../../../redux";
import {
  addProxyUrlToProfileImage,
  RICHTEXT_INITIAL_VALUE,
} from "../../../../../../../utils";
import { FlexColumn } from "../../../../../../Common/Styles";
import ModalFooter from "../ModalFooter";
import CreatePoll from "./CreatePoll";
import CreateSlider from "./CreateSlider";
import ImageUpload from "./ImageUpload";
import UrlPreview from "./UrlPreview";
import { findCustomURL } from "./utils";

const ModalContent = () => {
  const [isPreviewShow, setIsPreviewShow] = useState(true);
  const { post_theme, suggestedUsers } = useAppSelector((state) => state.me);
  const { pollOpen, setUrlData, urlData, sliderOpen } = useContext(
    CreatePostModalContext
  );
  const fieldNameContent = "content";

  return (
    <ModalContentContainer id="create-post-modal-content">
      <Field key={fieldNameContent} name={fieldNameContent}>
        {({ field, form }: FieldProps) => {
          const { value } = field;
          const { setFieldValue, validateField } = form;

          return (
            <Inputs.RichTextarea
              axiosInstance={instance}
              fieldName={fieldNameContent}
              idPrefix="richtext"
              placeholder={post_theme}
              minHeight={196}
              variant="Post"
              isRequired
              mentionList={suggestedUsers[0].followers.map((user) => ({
                ...user,
                profile_picture: addProxyUrlToProfileImage(
                  user.profile_picture
                ),
              }))}
              onBlur={() => {
                validateField(fieldNameContent);
              }}
              value={value}
              onChangeHandler={(state, html) => {
                setUrlData(findCustomURL(state.toJSON().root));

                if (!html || html === RICHTEXT_INITIAL_VALUE) {
                  setFieldValue(fieldNameContent, "");
                } else {
                  setFieldValue(fieldNameContent, html);
                  setTimeout(() => {
                    validateField(fieldNameContent);
                  }, 0);
                }
              }}
              footerComponent={
                <>
                  <FlexColumn $gap={16}>
                    {urlData && isPreviewShow && (
                      <UrlPreview setIsPreviewShow={setIsPreviewShow} />
                    )}
                    {pollOpen && <CreatePoll />}
                    {sliderOpen && <CreateSlider />}
                    <ImageUpload />
                  </FlexColumn>
                  <ModalFooter />
                </>
              }
            />
          );
        }}
      </Field>
    </ModalContentContainer>
  );
};

export default ModalContent;

const ModalContentContainer = styled.div`
  max-height: calc(100vh - 277px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-4"].value}px 89px;

  ${hideScrollBar}
`;
