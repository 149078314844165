import { useAppSelector } from "../../../../../redux";
import { FlexColumn } from "../../../../Common/Styles";
import { PostCardContent } from "./PostCardContent";

export const PostCard = () => {
  const { processed_posts } = useAppSelector((state) => state.newsfeed);
  const NewsFeedIds = Object.keys(processed_posts);

  if (!NewsFeedIds || !NewsFeedIds.length) return null;

  return (
    <FlexColumn $gap={16} id="newsfeedlisting">
      {NewsFeedIds.map((id) => (
        <PostCardContent key={id} postId={id} />
      ))}
    </FlexColumn>
  );
};
