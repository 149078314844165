import { styled } from "styled-components";

import { UserCard } from "../../../Common";
import { FlexColumn } from "../../../Common/Styles";
import { CommonInterests } from "./CommonInterests";
import { UserCardWithCommonInterestsProps } from "./UserCardWithCommonInterests.d";

export const UserCardWithCommonInterests = (
  props: UserCardWithCommonInterestsProps.IProps
) => {
  return (
    <Container>
      <UserCard {...props} />
      <CommonInterests />
    </Container>
  );
};

const Container = styled(FlexColumn)`
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-secondary"].value};
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;

  &:hover {
    background: ${({ theme }) =>
      theme.Color.elevation.surface["surface-primary-hover"].value};
  }
`;
