import { Components } from "@thisgorilla/primate-ds";
import { useContext, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { API_CALLS } from "../../../../../../../API/api_calls";
import { WithHeightTransition } from "../../../../../../../HOC/WithHeightTransition";
import { useAppSelector } from "../../../../../../../redux";
import { MAX_POLL_OPTIONS } from "../../../../../../../utils";
import {
  FlexColumn,
  transition,
  TransitionProps,
} from "../../../../../../Common/Styles";
import { PostCardContext } from "../../PostCardContent";
import { PollContentProps } from "./PollContent";
import { PollOption } from "./PollOption";
import { VotedFeedBack } from "./VotedFeedback";

export const PollContent = () => {
  const { postId } = useContext(PostCardContext);
  const { processed_posts } = useAppSelector((state) => state.newsfeed);
  const original_post = processed_posts[postId];
  const pollData = original_post?.interactions?.poll_interaction;
  const userVotedChoice = pollData?.user_voted_choice;

  const votes = pollData?.votes;
  const totalVotes = pollData?.total_votes;
  const isPollActive = pollData?.active;
  type Votes = keyof typeof votes;

  const { typography, Color } = useTheme();
  const isVoted = pollData?.poll_interaction_user_voted;

  const [containerHeight, setContainerHeight] = useState(0);

  const pollOptions = useMemo(() => {
    if (!pollData?.id) return [];
    return Array.from({ length: MAX_POLL_OPTIONS }, (_, i) => {
      const optionIndex = i + 1;
      const key = `choice_${optionIndex}` as PollContentProps.ChoiceKeys;
      const title = pollData?.[key] as Votes;

      const percentage =
        (votes?.[title] ?? 0) === 0
          ? 0
          : Math.round((votes[title] / totalVotes) * 100);
      if (!title) return null;
      return (
        <PollOption
          key={key}
          title={`${title}`}
          onClick={() => {
            if (!isPollActive || isVoted) return;

            API_CALLS.POLLS.submitAnswerForAPoll({
              choice: optionIndex,
              poll_id: pollData.id,
            });
          }}
          isVoted={isVoted}
          percentage={percentage}
          isPollActive={isPollActive}
        />
      );
    }).filter(Boolean);
    //eslint-disable-next-line
  }, [pollData, isVoted, votes, isPollActive]);

  if (!pollData) return null;
  if (!pollData.id || !pollData.active) return null;
  return (
    <Container
      $gap={10}
      $height={containerHeight}
      className="poll-content-container"
    >
      <WithHeightTransition
        additionalHeight={16}
        setContainerHeight={setContainerHeight}
      >
        <Widget $gap={8}>
          {isVoted && isPollActive && (
            <VotedFeedBack selectedPollOption={userVotedChoice} />
          )}
          <Components.Text
            text={pollData?.question}
            style={{ tokenStyle: typography.Body.L }}
            fontWeight="font-weight-semibold"
          />
          {pollOptions}
          {(isVoted || !isPollActive) && (
            <Components.Text
              text={`${totalVotes} vote${totalVotes > 1 ? "s" : ""} ${
                isPollActive ? "" : "• Poll closed"
              }`}
              style={{ tokenStyle: typography.Body.M }}
              color={Color.text["text-tertiary"].value}
            />
          )}
        </Widget>
      </WithHeightTransition>
    </Container>
  );
};

const Container = styled(FlexColumn)<TransitionProps>`
  padding-top: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  padding-right: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  padding-bottom: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  padding-left: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  ${transition}
`;
const Widget = styled(FlexColumn)(
  ({ theme }) => `
  border-radius: ${theme.radius["radius-xs"].value}px;
  border: 1px solid ${theme.Color.border["ui-border-neutral"].value};
  padding: ${theme.spacing["spacing-2"].value}px;
  background-color: ${theme.Color.elevation.surface["surface-secondary"].value};
  background: ${theme.Color.elevation.surface["surface-primary"].value};
`
);
