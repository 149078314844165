import { createSlice } from "@reduxjs/toolkit";

import { PostsProps } from "./Posts";
import { addProxyUrlToProfileImage } from "../../../utils";

const intailActivateAPostResponse: PostsProps.activateAPostResponse = {
  ID: "",
  UserID: "",
  EmbedPostID: "",
  DeliveryDate: "",
  CreatedAt: "",
  UpdatedAt: "",
  UserDetails: {
    name: "",
    profilePicture: "",
    info: "",
    activeDuration: "",
  },
  topics: [],
  PostMeta: {
    post_urls: [],
    post_users: [],
  },
  Content: {
    ID: "",
    PostID: "",
    Blueprint: "",
    NodeTree: "",
    PlainText: "",
    Summary: "",
    CleanedText: "",
    TokenizedText: "",
    CreatedAt: "",
    UpdatedAt: "",
  },
  Media: {
    Images: [],
  },
  interactions: {
    poll: {
      id: "",
      post_id: "",
      question: "",
      choice_1: "",
      choice_2: "",
      choice_3: "",
      choice_4: "",
      choice_5: "",
      choice_6: "",
      choice_7: "",
      choice_8: "",
      choice_9: "",
      choice_10: "",
      votes: {},
      leading_choice: "",
      total_votes: 0,
      created_at: "",
      duration: 0,
      active: true,
    },
    slider: {
      id: "",
      post_id: "",
      question: "",
      left_emoji_id: "",
      right_emoji_id: "",
      left_emoji: "",
      right_emoji: "",
      left_score: 0,
      right_score: 0,
      leading_emoji: 0,
      total: 0,
    },
  },
  Likes: 0,
  Dislikes: 0,
  LikePercentage: 0,
  DislikePercentage: 0,
  MainCommentCount: 0,
  ReactionCounts: {},
  TotalCuratedReactions: 0,
  OriginalPost: null,
};

const initialCreatePostAPI: PostsProps.CreatePostAPI = {};
const initialGetPostAPI: PostsProps.GetPostAPI = {
  getSinglePost_API_STATUS: "idle",
};

const initialState: PostsProps.State = {
  ...initialCreatePostAPI,
  ...initialGetPostAPI,
  post_id: "",
  activateAPostResponse: intailActivateAPostResponse,
  uploadedImages: [],
  uploadedFiles: [],
  NewsFeed: {},
};

const slice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setCreatePostDraftResponse: (
      state,
      action: PostsProps.setCreatePostDraftResponse
    ) => {
      state.post_id = action.payload.id;
    },
    setActivateAPostResponse: (
      state,
      action: PostsProps.setActivateAPostResponse
    ) => {
      state.activateAPostResponse = action.payload;
    },

    setUploadsImageForAPostResponse: (
      state,
      action: PostsProps.setUploadsImageForAPostResponse
    ) => {
      state.uploadedImages = action.payload;
    },
    setUploadedFile: (state, action: PostsProps.setUploadedFile) => {
      const findFileIndex = state.uploadedFiles.findIndex(
        (file) => file.id === action.payload.fileId
      );
      if (findFileIndex === -1) {
        state.uploadedFiles = [...state.uploadedFiles, action.payload.image];
      } else {
        const newUploadedFiles = [...state.uploadedFiles];
        newUploadedFiles[findFileIndex] = action.payload.image;
        state.uploadedFiles = newUploadedFiles;
      }
    },
    setDeleteUploadedFile: (
      state,
      action: PostsProps.setDeleteUploadedFile
    ) => {
      state.uploadedFiles = state.uploadedFiles.filter(
        (file) => file.id !== action.payload
      );
    },
    setDeleteAllUploadedFile: (state) => {
      state.uploadedFiles = [];
    },
    //#region Single post
    setSinglePostResponse: (
      state,
      action: PostsProps.setSinglePostResponse
    ) => {
      //const isARepost = !!action.payload.EmbedPostID; //TODO: Utilise isARepost to know the type of post(original or repost)

      const post = action.payload as PostsProps.PostData;
      state.NewsFeed[post.ID] = {
        ...post,
        interactions: {
          ...post.interactions,
          rating_interaction: {
            ...post.interactions.rating_interaction,
            users_profile_pictures: [],
          },
        },
      };

      const rating_interaction = post?.interactions?.rating_interaction;
      if (!rating_interaction) return;
      state.NewsFeed[
        post.ID
      ].interactions.rating_interaction.users_profile_pictures =
        getUsersProfilePictureFromUsersVotedList(
          rating_interaction.users_voted_list
        );
    },
    setGetSinglePostAPIStatus: (
      state,
      action: PostsProps.setGetSinglePostAPIStatus
    ) => {
      state.getSinglePost_API_STATUS = action.payload;
    },
    //#endregion
    resetCreatePostData: (state) => {
      state.post_id = "";
      state.activateAPostResponse = intailActivateAPostResponse;
      state.uploadedImages = [];
      state.uploadedFiles = [];
    },
    reset: () => initialState,
  },
});

const getUsersProfilePictureFromUsersVotedList = (
  users_voted_list: PostsProps.RatingUsersVotedList
) => {
  if (!users_voted_list) return [];
  const users_list = Object.values(users_voted_list);
  if (!users_list || !users_list.length) return [];
  const users: PostsProps.RatedUser[] = [];
  users_list.forEach((user) => users.push(...user.users));
  return users.map((user) => addProxyUrlToProfileImage(user.profile_picture));
};

export const postsActions = slice.actions;
export const postsReducer = slice.reducer;
