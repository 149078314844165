import { useEffect } from "react";
import styled from "styled-components";

import { API_CALLS } from "../../../../../API/api_calls";
import { useAppSelector } from "../../../../../redux";
import { getHexValueForPercentage } from "../../../../../utils";
import { LeftPanelCommonStyles } from "../Common";
import { AvatarGroupAndDescription } from "./AvatarGroupAndDescription";
import { TitleAndButton } from "./TitleAndButton";

export const PeopleYouMightKnow = () => {
  const { peopleYouMightKnow_API_STATUS } = useAppSelector((state) => state.me);

  useEffect(() => {
    if (peopleYouMightKnow_API_STATUS !== "idle") return;

    API_CALLS.ME.getPeopleYouMightKnow();
  }, [peopleYouMightKnow_API_STATUS]);

  return (
    <Container className="people-user-might-know">
      <TitleAndButton />
      <AvatarGroupAndDescription />
    </Container>
  );
};

const Container = styled.div`
  ${LeftPanelCommonStyles.getGradientBackgroundComponent(
    ` #FFFFFF 10%, #1E96F2${getHexValueForPercentage(4)} 108%`
  )};
`;
