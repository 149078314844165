import { Dispatch, KeyboardEvent, SetStateAction } from "react";

type UseDropdownKeyDownProps = {
  options: any[];
  highlightedIndex: number;
  setHighlightedIndex: Dispatch<SetStateAction<number>>;
  isDropdownOpen: boolean;
  setDropdownOpen: Dispatch<SetStateAction<boolean>>;
  handleSelect: (option: any) => void;
  onEnterClick?: () => void;
  scrollIntoView?: (highlightedIndex: number) => void;
  renderInSigleLine?: number;
};

export const useDropdownKeyDown = (props: UseDropdownKeyDownProps) => {
  const {
    options,
    highlightedIndex,
    setHighlightedIndex,
    isDropdownOpen,
    setDropdownOpen,
    handleSelect,
    onEnterClick,
    renderInSigleLine = 1,
    scrollIntoView = () => {},
  } = props;

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "Enter":
        if (!isDropdownOpen) return;
        setDropdownOpen(false);
        if (!handleSelect) return;
        if (!options || options.length === 0) return;
        handleSelect(options[highlightedIndex]);
        if (onEnterClick) onEnterClick();
        break;
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex((prev: number) => {
          const highlightedIndex =
            prev + renderInSigleLine > options.length - 1
              ? prev
              : prev + renderInSigleLine;
          scrollIntoView(highlightedIndex);
          return highlightedIndex;
        });
        break;
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prev: number) => {
          const highlightedIndex =
            prev - renderInSigleLine >= 0 ? prev - renderInSigleLine : prev;
          scrollIntoView(highlightedIndex);
          return highlightedIndex;
        });
        break;
      case "ArrowRight":
        e.preventDefault();
        setHighlightedIndex((prev: number) => {
          const highlightedIndex =
            prev + 1 > options.length - 1 ? options.length - 1 : prev + 1;
          scrollIntoView(highlightedIndex);
          return highlightedIndex;
        });
        break;
      case "ArrowLeft":
        e.preventDefault();
        setHighlightedIndex((prev: number) => {
          const highlightedIndex = prev - 1 > 0 ? prev - 1 : 0;
          scrollIntoView(highlightedIndex);
          return highlightedIndex;
        });
        break;
      default:
        break;
    }
  };

  return handleKeyDown;
};
