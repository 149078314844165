import { Components } from "@thisgorilla/primate-ds";
import { useContext, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import { CreatePostModalContext } from "../..";
import { API_CALLS } from "../../../../../../../../API/api_calls";
import { API_PATHS } from "../../../../../../../../API/api_paths";
import { useMouseOver } from "../../../../../../../../hooks/useMouseOver";
import {
  actions,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux";
import { getImageBase64 } from "../../../../../../../../utils";
import { ImageUploadProps } from "./ImageUpload";
import ReUploadButton from "./ReUploadButton";

const SingleImagePreview = ({ fileInputRef }: ImageUploadProps.IProps) => {
  const dispatch = useAppDispatch();
  const { typography, Color } = useTheme();
  const { isMoreImgUploadButtonDisable } = useContext(CreatePostModalContext);
  const { isHovered } = useMouseOver({ id: "single-image-preview" });
  const { uploadedFiles, post_id } = useAppSelector((state) => state.posts);
  const isUploaded = uploadedFiles[0].status === "fulfilled";
  const [url, setUrl] = useState(
    isUploaded ? API_PATHS.posts.getPostImage + uploadedFiles[0].id : ""
  );

  useEffect(() => {
    if (isUploaded) {
      setUrl(API_PATHS.posts.getPostImage + uploadedFiles[0].id);
    } else {
      getImageBase64(uploadedFiles[0].file).then((url) => setUrl(url));
    }
  }, [uploadedFiles, isUploaded]);

  return (
    <SingleImagePreviewContainer id="single-image-preview">
      <Container>
        <ImageContainer $src={url} $hover={isHovered && isUploaded}>
          <Image src={url} />
          {isUploaded && (
            <TooltipText $hover={isHovered}>
              <Components.Text
                style={{ tokenStyle: typography.Body.M }}
                text={"You can drag and drop more images here."}
                lineHeight="line-height-100"
                color={Color.text["on-bold"].default.value}
              />
            </TooltipText>
          )}
        </ImageContainer>

        <ButtonContainer>
          <Components.Button
            color="On-Primary"
            disabled={isMoreImgUploadButtonDisable}
            kind="Primary"
            label="Add images/videos"
            onClick={() => fileInputRef?.current?.click()}
            size="Small"
            iconPosition="left"
            iconType="PlusCircle"
          />
          <CloseButton>
            <Components.IconButton
              disabled={false}
              iconType="X"
              kind="Secondary"
              onClick={() => {
                if (isUploaded) {
                  API_CALLS.POSTS.deleteAnImageForAPost({
                    image_id: uploadedFiles[0].id,
                    post_id,
                  });
                } else {
                  dispatch(
                    actions.posts.setDeleteUploadedFile(uploadedFiles[0].id)
                  );
                }
              }}
              size="Small"
            />
          </CloseButton>
        </ButtonContainer>
        {!isUploaded && (
          <ReUploadButton
            file={uploadedFiles[0].file}
            id={uploadedFiles[0].id}
            size="Large"
          />
        )}
      </Container>
    </SingleImagePreviewContainer>
  );
};

export default SingleImagePreview;

const SingleImagePreviewContainer = styled.div`
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-m"].value}px;
  box-shadow: ${({ theme }) => theme.boxShadows["box-shadow-s"]};
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-secondary"].value};
`;

const Container = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  min-height: 100px;
`;

const TooltipText = styled.div<{ $hover: boolean }>`
  opacity: ${({ $hover }) => ($hover ? "1" : "0")};
  position: absolute;
  bottom: 10px;
  left: 11px;
  padding: 1px ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  background: ${({ theme }) => theme.Color.background.brand.default.value};
`;

const ImageContainer = styled.div<{ $src: string; $hover: boolean }>`
  width: 100%;
  display: block;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  background: ${({ $hover, $src, theme }) =>
    $hover
      ? `linear-gradient(
        0deg,
        ${theme.Color.elevation.surface["surface-primary-emphasize-hover"].value}
          0%,
        ${theme.Color.elevation.surface["surface-primary-emphasize-hover"].value}
          100%
      ),
      url(${$src}) lightgray 50% / cover no-repeat`
      : `url(${$src}) lightgray 50% / cover no-repeat`};
`;
const Image = styled.img`
  width: 100%;
  display: block;
  opacity: 0;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
`;

const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  top: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  right: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  z-index: 1;
`;

const CloseButton = styled.div`
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-overlay"].value};
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
`;
