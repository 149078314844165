import { actions, useAppDispatch, useAppSelector } from "../../../redux";
import { OnboardingProps } from "../../../redux/slices/onboarding/onboarding";
import { OnboardingUtils } from "../../../redux/slices/onboarding/utils";

export const useBackbuttonClick = () => {
  const { isGettingStartedVisited, currentStep } = useAppSelector(
    (state) => state.onboarding
  );
  const dispatch = useAppDispatch();

  return () => {
    const previousStepNumber = OnboardingUtils.STEPNUMBERS[currentStep] - 1;
    const previousStep = OnboardingUtils.STEPS[
      previousStepNumber
    ] as OnboardingProps.Step;

    if (previousStepNumber > 1) {
      dispatch(actions.onboarding.setCurrentStep(previousStep));
      return;
    }

    if (isGettingStartedVisited)
      dispatch(actions.onboarding.setCurrentStep("get_started"));
    else dispatch(actions.onboarding.setCurrentStep("location"));
  };
};
