import { Components } from "@thisgorilla/primate-ds";
import { AvatarProps } from "@thisgorilla/primate-ds/dist/components/Avatar";
import { useEffect, useMemo } from "react";
import styled from "styled-components";

import { API_CALLS } from "../../../../../API/api_calls";
import { useAppSelector } from "../../../../../redux";
import { addProxyUrlToProfileImage } from "../../../../../utils";
import { hideScrollbar } from "../../../../Common/Styles";

export const AvatarsList = () => {
  const { yourInnerCircle_API_STATUS, yourInnerCircle } = useAppSelector(
    (state) => state.me
  );
  useEffect(() => {
    if (yourInnerCircle_API_STATUS !== "idle") return;
    API_CALLS.ME.getYourInnerCircle();
  }, [yourInnerCircle_API_STATUS]);

  const size: AvatarProps.Size = "Small-40";
  const type: AvatarProps.Type = "Circle";

  const yourInnerCircleList = useMemo(() => {
    if (!yourInnerCircle || !yourInnerCircle.length) return [];

    return yourInnerCircle.map((person) => {
      const src: AvatarProps.IProps["src"] = addProxyUrlToProfileImage(
        person.profile_picture
      );
      const status: AvatarProps.Status = person.online_status;
      const count: AvatarProps.IProps["count"] = person.total_messages;
      const props: AvatarProps.IProps = { size, type, src, status, count };
      return props;
    });
  }, [yourInnerCircle]);

  return (
    <Container className="AvatarsList">
      {yourInnerCircleList.map((props, index) => (
        <Components.Avatar key={props.src + index} {...props} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 11px;
  overflow: auto;
  ${hideScrollbar};
}
`;
