import { Components } from "@thisgorilla/primate-ds";
import styled from "styled-components";
import { FlexRow } from "../Styles";

export const FollowMessageAndMore = () => {
  return (
    <Container $gap={4}>
      <Components.Button
        kind="Primary"
        color="Primary"
        disabled={false}
        iconPosition="left"
        iconType="Plus"
        label="Follow"
        onClick={() => {}}
        size="Small"
        width="fill"
      />
      <Components.Button
        kind="Primary"
        color="Neutral"
        size="Small"
        disabled={false}
        iconPosition="left"
        iconType="MessageCircle"
        label="Message"
        onClick={() => {}}
        width="fill"
      />
      <Components.IconButton
        kind="Secondary"
        size="Small"
        disabled={false}
        iconType="MoreHorizontal"
        onClick={() => {}}
      />
    </Container>
  );
};

const Container = styled(FlexRow)``;
