import { Components } from "@thisgorilla/primate-ds";
import { useContext } from "react";
import styled from "styled-components";
import { useClickOutside } from "../../../../../../hooks/useClickOutside";
import { actions, useAppDispatch } from "../../../../../../redux";
import { FadeTransitionType } from "../../../../../../redux/common";
import { fadeAnimation, FlexColumn } from "../../../../../Common/Styles";
import { PostCardContext } from "../PostCardContent";

export const PostCardOptions = () => {
  const dispatch = useAppDispatch();
  const {
    postCardOptionsRef,
    showPostCardOptions,
    setShowPostCardOptions,
    setReportPostOpen,
  } = useContext(PostCardContext);

  useClickOutside({
    ref: postCardOptionsRef,
    setState: setShowPostCardOptions,
  });

  return (
    <Container
      $fadeTransitionType={showPostCardOptions ? "FadeIn" : "FadeOut"}
      className="post-card-options"
      ref={postCardOptionsRef}
    >
      <Components.Menu size="large" withBackground={true}>
        <Components.ListItem
          type="List Item"
          label="Save"
          onClick={() => {
            setShowPostCardOptions(false);
          }}
          iconType="Bookmark"
        />
        <Components.ListItem
          type="List Item"
          label="Copy link to post"
          onClick={() => {
            setShowPostCardOptions(false);
          }}
          iconType="Link"
        />
        <Components.ListItem type="Divider" />
        <Components.ListItem
          type="List Item"
          label="Not Interested"
          onClick={() => {
            setShowPostCardOptions(false);
          }}
          iconType="EyeOff"
        />
        <Components.ListItem
          type="List Item"
          label="Mute {user}"
          onClick={() => {
            setShowPostCardOptions(false);
          }}
          iconType="XCircle"
        />
        <Components.ListItem type="Divider" />
        <Components.ListItem
          type="List Item"
          label="Report post"
          onClick={() => {
            dispatch(actions.reportPost.setReportPostStep("why_reporting"));
            setReportPostOpen(true);
            setShowPostCardOptions(false);
          }}
          iconType="Flag"
        />
      </Components.Menu>
    </Container>
  );
};

const Container = styled(FlexColumn)<{
  $fadeTransitionType: FadeTransitionType;
}>`
  position: absolute;
  top: 36px;
  right: 0;
  z-index: 2;

  ${fadeAnimation}
`;
