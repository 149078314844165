import { Components } from "@thisgorilla/primate-ds";
import { useState } from "react";
import styled, { useTheme } from "styled-components";

import CommentList from "../..";
import { API_CALLS } from "../../../../../API/api_calls";
import PostCommentField from "../../../PostCommentField";
import { FlexColumn, FlexRow } from "../../../Styles";
import { UpvoteDownvote } from "../../../UpvoteDownvote";
import { CommentProps } from "../Comment";

const CommentFooter = ({
  showReplyButton,
  id,
  likes,
  dislikes,
  replies = [],
  parent_comment_id,
  user_voted,
  observeCount,
  user_id,
  post_id,
}: CommentProps.CommentFooter) => {
  const { spacing } = useTheme();
  const [isButtonShow, setIsButtonShow] = useState(true);
  const [isCommentShow, setIsCommentShow] = useState(false);

  return (
    <>
      <Container $gap={Number(spacing["spacing-4"].value)}>
        <UpvoteDownvote
          upvoteCount={likes}
          downvoteCount={dislikes}
          downVoteHandler={() =>
            user_voted === "dislike"
              ? API_CALLS.COMMENTS.removeLikeOrDislikeFromAComment({
                  comment_id: id,
                  parent_comment_id,
                  post_id,
                })
              : API_CALLS.COMMENTS.dislikeAComment({
                  comment_id: id,
                  parent_comment_id,
                  post_id,
                })
          }
          upVoteHandler={() =>
            user_voted === "like"
              ? API_CALLS.COMMENTS.removeLikeOrDislikeFromAComment({
                  comment_id: id,
                  parent_comment_id,
                  post_id,
                })
              : API_CALLS.COMMENTS.likeAComment({
                  comment_id: id,
                  parent_comment_id,
                  post_id,
                })
          }
        />
        {showReplyButton && (
          <Components.Button
            iconPosition="left"
            iconType="MessageCircle"
            kind="Tertiary"
            color="Primary"
            size="Small"
            disabled={false}
            label={String(replies.length)}
            onClick={() => setIsCommentShow(!isCommentShow)}
          />
        )}
      </Container>
      {(replies.length > 0 || isCommentShow) && (
        <Wrapper $gap={10} $topPadding={isButtonShow ? 8 : 24}>
          {isCommentShow && (
            <PostCommentField
              parent_comment_id={id}
              value={`<span>{{ @user:${user_id} }}</span>`}
              post_id={post_id}
              onClose={() => setIsCommentShow(false)}
            />
          )}
          {replies.length > 0 && (
            <CommentList
              showReplyButton={false}
              comments={replies}
              observeCount={observeCount}
              isButtonShow={isButtonShow}
              setIsButtonShow={setIsButtonShow}
            />
          )}
        </Wrapper>
      )}
    </>
  );
};

export default CommentFooter;

const Container = styled(FlexRow)`
  padding-left: ${({ theme }) => theme.spacing["spacing-9"].value}px;
`;

const Wrapper = styled(FlexColumn)<{ $topPadding: number }>`
  padding-left: ${({ theme }) => theme.spacing["spacing-9"].value}px;
  padding-top: ${({ $topPadding }) => $topPadding}px;
`;
