import { Components } from "@thisgorilla/primate-ds";
import { useState } from "react";
import styled from "styled-components";
import { WithSlider } from "../../../../HOC/WithSlider";
import { useAppSelector } from "../../../../redux";
import { SliderDirection } from "../../../../redux/common";
import { JobCard } from "../../../Common";
import { FlexColumn, FlexColumnSpaced } from "../../../Common/Styles";

export const RecommendedJobsList = () => {
  const { recommendedJobs } = useAppSelector((state) => state.me);
  const [direction, setDirection] = useState("to-right" as SliderDirection);
  const [currentStep, setCurrentStep] = useState(1);
  const ITEMS_PER_SLIDE = 3;
  const TOTAL_DATA_LENGTH = recommendedJobs?.length;
  const TOTAL_SLIDES = Math.ceil(TOTAL_DATA_LENGTH / ITEMS_PER_SLIDE);

  const startIndex = (currentStep - 1) * ITEMS_PER_SLIDE;
  const endIndex = startIndex + ITEMS_PER_SLIDE;

  return (
    <Container className="suggested-user-list">
      <WithSlider
        stepKey={`recommended-people-${currentStep}`}
        direction={direction}
      >
        <FlexColumn>
          {recommendedJobs.slice(startIndex, endIndex).map((job) => (
            <JobCard key={job.id} {...job} />
          ))}
        </FlexColumn>
      </WithSlider>
      <StepperContainer>
        {TOTAL_DATA_LENGTH > 0 && (
          <Components.Stepper
            onColor="On White"
            selectedStep={currentStep}
            steps={TOTAL_SLIDES}
            setStepNumber={setCurrentStep}
            view="Gallery"
            leftLabel="Prev"
            rightLabel="Next"
            onStepForward={() => setDirection("to-right")}
            onStepBackward={() => setDirection("to-left")}
          />
        )}
      </StepperContainer>
    </Container>
  );
};

const StepperContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
`;

const Container = styled(FlexColumnSpaced)`
  min-height: 330px;
`;
