import { createSlice } from "@reduxjs/toolkit";
import { InfoProps } from "./info";

const initialState: InfoProps.state = {
  getEntityInfoResponses: {} as InfoProps.getEntityInfoResponses,
  getEntityInfo_API_STATUS: "idle",
};

const slice = createSlice({
  initialState,
  name: "info",
  reducers: {
    setEntityInfoResponse: (state, action: InfoProps.setEntityInfoResponse) => {
      state.getEntityInfoResponses[action.payload.id] = action.payload;
    },
    setEntityInfo_API_STATUS: (
      state,
      action: InfoProps.setEntityInfo_API_STATUS
    ) => {
      state.getEntityInfo_API_STATUS = action.payload;
    },
  },
});

export const infoActions = slice.actions;
export const infoReducer = slice.reducer;
