import { Components } from "@thisgorilla/primate-ds";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { API_CALLS } from "../../../../API/api_calls";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { useAppSelector } from "../../../../redux";

export const Profile = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { profile_picture } = useAppSelector((state) => state.me);

  useClickOutside({ ref: dropdownRef, setState: setIsOpen });

  return (
    <Container ref={dropdownRef}>
      <Components.Avatar
        type="Circle"
        size="Small-40"
        disabled={false}
        src={profile_picture}
        onClick={() => setIsOpen((prev) => !prev)}
      />
      <DropdownWrapper $isOpen={isOpen}>
        <Components.ListItem
          type="List Item"
          label="Sign out"
          onClick={() => {
            API_CALLS.AUTH.logout(navigate);
          }}
          showSelected={false}
          renderInSingleLine={true}
        />
      </DropdownWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
`;
const DropdownWrapper = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: calc(100% + 12px);
  right: 0;
  background-color: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary"].value};
  box-shadow: ${({ theme }) => theme.boxShadows["box-shadow-m"]};
  border-radius: 4px;
  padding: 4px;
  width: 150px;

  visibility: ${({ $isOpen }) => ($isOpen ? "visible" : "hidden")};
  opacity: ${({ $isOpen }) => ($isOpen ? "1" : "0")};
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
`;
