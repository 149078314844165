import { Components } from "@thisgorilla/primate-ds";
import { AvatarProps } from "@thisgorilla/primate-ds/dist/components/Avatar";
import { useRef } from "react";
import styled, { useTheme } from "styled-components";

import { addProxyUrlToProfileImage } from "../../../utils";
import { Popup } from "../Popup";
import { FlexRow } from "../Styles";

type WithPostedDate = { activeDuration: string; showPostedDate: true };
type WithoutPostedDate = { activeDuration?: never; showPostedDate?: false };
type PostedDate = WithPostedDate | WithoutPostedDate;
type UserInfoProps = {
  profile_picture: string;
  name: string;
  headline: string;
  avatarSize?: AvatarProps.Size;
  onClick?: () => void;
  showUserInfoOnHover?: boolean;
  id: string;
} & PostedDate;
const UserInfo = (user: UserInfoProps) => {
  const { Color, typography } = useTheme();
  const {
    id,
    profile_picture,
    name,
    headline,
    avatarSize = "XSmall-28",
    showUserInfoOnHover = true,
    onClick = () => {},
    activeDuration,
    showPostedDate = false,
  } = user;
  const ref = useRef<HTMLDivElement>(null);

  return (
    <InfoContainer ref={ref} onClick={onClick}>
      <Components.Avatar
        disabled={false}
        size={avatarSize}
        src={addProxyUrlToProfileImage(profile_picture)}
        type="Circle"
      />
      <NameContainer className="user-info-name-container">
        <Components.Text
          text={name}
          maxLineLength={1}
          style={{ tokenStyle: { ...typography.Heading.XXS } }}
          lineHeight="line-height-50"
          fontWeight="font-weight-semibold"
        />
        <Components.Text
          text={headline}
          maxLineLength={1}
          style={{ tokenStyle: typography.Body.S }}
          color={Color.text["text-secondary"].value}
        />
        {showPostedDate && (
          <PostedDateAndIcon $gap={2}>
            <Components.Text
              text={`${activeDuration} •`}
              style={{ tokenStyle: typography.Body.S }}
            />
            <Components.Icon type="Globe" size="16px" />
          </PostedDateAndIcon>
        )}
        {showUserInfoOnHover && <Popup parentRef={ref} type="user" id={id} />}
      </NameContainer>
    </InfoContainer>
  );
};

export default UserInfo;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
  position: relative;
`;
const PostedDateAndIcon = styled(FlexRow)`
  align-items: center;
`;
