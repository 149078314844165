import { useEffect, useState } from "react";

type WithId = { id: string; ref?: never };
type WithRef = { ref: React.RefObject<HTMLDivElement>; id?: never };
type WithDelay = {
  mouseLeaveDelay?: number;
  mouseEnterDelay?: number;
};

type MouseOverProps = (WithId | WithRef) & WithDelay;

export const useMouseOver = ({
  id,
  ref,
  mouseLeaveDelay = 0,
  mouseEnterDelay = 0,
}: MouseOverProps) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const element = id ? document.getElementById(id) : ref?.current;
    if (!element) return;
    let mouseEnterTimeoutId: NodeJS.Timeout | null = null;

    const handleMouseEnter = () => {
      if (!mouseEnterDelay) {
        setIsHovered(true);
        return;
      }

      mouseEnterTimeoutId = setTimeout(
        () => setIsHovered(true),
        mouseEnterDelay
      );
    };
    const handleMouseLeave = () => {
      if (mouseEnterTimeoutId) {
        clearTimeout(mouseEnterTimeoutId);
        mouseEnterTimeoutId = null;
      }

      if (mouseLeaveDelay)
        setTimeout(() => setIsHovered(false), mouseLeaveDelay);
      else setIsHovered(false);
    };

    element.addEventListener("mouseenter", handleMouseEnter);
    element.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      element.removeEventListener("mouseenter", handleMouseEnter);
      element.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [id, ref, mouseLeaveDelay, mouseEnterDelay]);

  return { isHovered };
};
