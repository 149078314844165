import { Components } from "@thisgorilla/primate-ds";
import styled, { css, useTheme } from "styled-components";

type Props = {
  downvoteCount: number;
  upvoteCount: number;
  downVoteHandler: () => void;
  upVoteHandler: () => void;
};
export const UpvoteDownvote = ({
  upvoteCount,
  downvoteCount,
  upVoteHandler,
  downVoteHandler,
}: Props) => {
  const { Color } = useTheme();

  const totalVotes = upvoteCount + downvoteCount;

  const getVotePercentage = () => {
    if (totalVotes === 0) return [0, 0];
    const upvotePercentage = Math.round((upvoteCount / totalVotes) * 100);
    const downvotePercentage = Math.round((downvoteCount / totalVotes) * 100);
    return [upvotePercentage, downvotePercentage];
  };
  const [upvotePercentage, downvotePercentage] = getVotePercentage();

  const upvoteColors = {
    textColor: Color.text["text-emphasize-brand-bolder"].value,
    iconColor: Color.icon["icon-brand-bolder"].value,
  } as const;
  const downvoteColors = {
    textColor: Color.text["text-secondary"].value,
    iconColor: Color.icon["icon-secondary"].value,
  } as const;
  const showGradient = upvotePercentage > 0 || downvotePercentage > 0;

  return (
    <Container
      $upvotePercentage={upvotePercentage}
      $showGradient={showGradient}
    >
      <Components.Button
        kind="Tertiary"
        color="Primary"
        disabled={false}
        label={`${upvoteCount}`}
        onClick={upVoteHandler}
        size="Small"
        iconPosition="left"
        iconType="Upvote"
        {...(!upvotePercentage ? {} : upvoteColors)}
      />
      {!upvotePercentage && !downvotePercentage && <VerticalLine />}
      <Components.Button
        kind="Tertiary"
        color="Primary"
        disabled={false}
        label={`${downvoteCount}`}
        onClick={downVoteHandler}
        size="Small"
        iconPosition="left"
        iconType="Downvote"
        {...(!upvotePercentage ? {} : downvoteColors)}
      />
    </Container>
  );
};

type ContainerProps = { $upvotePercentage: number; $showGradient: boolean };
type GetGradientProps = ContainerProps & { red: string; blue: string };

const getGradientBackground = ({
  $showGradient,
  $upvotePercentage,
  blue,
  red,
}: GetGradientProps) => {
  if (!$showGradient) return `background: transparent;`;
  const MIN_GAP = 10;
  const MIN_WIDTH = MIN_GAP / 2;

  const blueEnd = $upvotePercentage - MIN_WIDTH;
  const redStart = blueEnd + MIN_GAP;

  return `
    background: linear-gradient(
      90deg,
      ${blue} 0%,
      ${blue} ${Math.max(MIN_WIDTH, Math.min(85, blueEnd))}%,
      ${red} ${Math.max(15, Math.min(redStart, 100 - MIN_WIDTH))}%,
      ${red} 100%
    );`;
};
const borderBackground = css<ContainerProps>(({ theme, ...rest }) => {
  const blue = theme.Color.border.brand.subtle.default.value;
  const red = theme.Color.icon.negative.subtlest["default-hover"].value;
  return getGradientBackground({ ...rest, blue, red });
});
const contentBackground = css<ContainerProps>(({ theme, ...rest }) => {
  const blue = theme.Color.border.brand.subtler["default-hover"].value;
  const red = theme.Color.icon.negative.subtlest["default-hover"].value;
  return getGradientBackground({ ...rest, blue, red });
});
const gradientBackground = css<ContainerProps>`
  position: relative;
  isolation: isolate;
  &::before {
    content: "";
    position: absolute;
    inset: -1px;
    ${borderBackground}
    border-radius: inherit;
    z-index: -1;
  }
  &::after {
    content: "";
    position: absolute;
    inset: 1;
    border-radius: inherit;
    ${contentBackground}
    z-index: -1;
  }
`;
const hoverAnimation = css`
  transform: scaleX(1);
  transition: transform 0.2s cubic-bezier(0.34, 1.56, 0.64, 1); // Spring animation
  @keyframes shrinkSpring {
    0% {
      transform: scaleX(1);
    }
    50% {
      transform: scaleX(0.98);
    }
    100% {
      transform: scaleX(1);
    }
  }
  &:hover {
    animation: shrinkSpring 500ms cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  }
`;
const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  height: 32px;
  border-radius: 4px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  cursor: pointer;
  padding: 0;
  background: transparent;

  ${gradientBackground}
  ${hoverAnimation}
`;
const VerticalLine = styled.span`
  height: 100%;
  border: 0.5px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
`;
