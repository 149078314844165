import { $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Components, Inputs } from "@thisgorilla/primate-ds";
import { $createTextNode, $getRoot, $getSelection } from "lexical";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";

import { useClickOutside } from "../../../hooks/useClickOutside";
import { useDropdownKeyDown } from "../../../hooks/useDropdownKeyDown";
import { PostCommentFieldProps } from "../PostCommentField/PostCommentField";
import { FlexRowSpaced } from "../Styles";
import {
  Container,
  EmojiContainer,
  EmojiListWrapper,
  EmojiWrapper,
  IconButtonWrapper,
} from "./style";
import { emojiList, emojisFooterIcon, emojisType } from "./utils";

export const EmojiPicker = ({
  emojiOptionsRef,
  showEmojiOptions,
  setShowEmojiOptions,
  position = "bottom",
}: PostCommentFieldProps.EmojiPicker) => {
  const recentEmoji: string[] = JSON.parse(
    localStorage.getItem("recentEmoji") || "[]"
  );

  const recentEmojiCount = recentEmoji.length;
  const [editor] = useLexicalComposerContext();
  const { spacing, typography, Color } = useTheme();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(0);

  useClickOutside({
    ref: emojiOptionsRef,
    setState: setShowEmojiOptions,
  });

  useEffect(() => {
    if (emojiOptionsRef.current) {
      emojiOptionsRef.current.focus();
    }
  }, [emojiOptionsRef]);

  const handleSelect = () => {
    editor.update(() => {
      const selection = $getSelection();
      const selectedEmoji =
        highlightedOptionIndex <= recentEmojiCount - 1
          ? recentEmoji[highlightedOptionIndex]
          : emojiList[highlightedOptionIndex - recentEmojiCount].emoji;
      if (!recentEmoji.includes(selectedEmoji)) {
        localStorage.setItem(
          "recentEmoji",
          JSON.stringify([...recentEmoji, selectedEmoji])
        );
        recentEmoji.push(selectedEmoji);
      }

      if (selection) {
        selection.insertNodes([$createTextNode(selectedEmoji)]);
      } else {
        const parser = new DOMParser();
        const dom = parser.parseFromString(selectedEmoji, "text/html");
        const nodes = $generateNodesFromDOM(editor, dom);
        const root = $getRoot();
        root.append(...nodes);
      }
    });
    setShowEmojiOptions(false);
  };

  const selectedOptionScrollIntoView = (
    highlightedIndex: number,
    position: ScrollLogicalPosition = "nearest"
  ) => {
    const selectedOption = document.getElementById(
      `emoji-${highlightedIndex}`
    ) as HTMLElement;

    if (selectedOption) {
      selectedOption.scrollIntoView({ behavior: "smooth", block: position });
    }
  };

  const keyDown = useDropdownKeyDown({
    handleSelect,
    highlightedIndex: highlightedOptionIndex,
    isDropdownOpen: showEmojiOptions,
    options: [...recentEmoji, ...emojiList],
    setDropdownOpen: setShowEmojiOptions,
    setHighlightedIndex: setHighlightedOptionIndex,
    renderInSigleLine: 1,
    scrollIntoView: selectedOptionScrollIntoView,
  });

  return (
    <Container
      ref={emojiOptionsRef}
      tabIndex={0}
      onKeyDown={keyDown}
      $position={position}
    >
      <Components.Menu
        size="fill"
        withBackground={true}
        itemGap={Number(spacing["spacing-2"].value)}
      >
        <Inputs.Input
          fieldName="search-emoji"
          idPrefix="comment"
          isRequired={false}
          onChangeHandler={() => {}}
          value=""
          size="Small"
          iconType="Search"
        />
        <Components.Tabs
          TabItems={[
            { title: "Emojis", iconType: "Smile" },
            { title: "Stickers", iconType: "Activity" },
          ]}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
        <EmojiContainer $gap={Number(spacing["spacing-2"].value)}>
          <Components.Text
            style={{ tokenStyle: typography.Heading.XS }}
            text="Recent"
            color={Color.text["text-secondary"].value}
            fontWeight="font-weight-medium"
            lineHeight="line-height-400"
          />
          <EmojiListWrapper>
            {recentEmoji.map((emoji, index) => (
              <EmojiWrapper
                $selected={false}
                $hover={highlightedOptionIndex === index}
                key={index}
                id={`emoji-${index}`}
                onClick={handleSelect}
                onMouseEnter={() => {
                  setHighlightedOptionIndex(index);
                }}
              >
                {emoji}
              </EmojiWrapper>
            ))}
          </EmojiListWrapper>
          {emojisType.map((type) => (
            <>
              <Components.Text
                style={{ tokenStyle: typography.Heading.XS }}
                text={type}
                color={Color.text["text-secondary"].value}
                fontWeight="font-weight-medium"
                lineHeight="line-height-400"
              />
              <EmojiListWrapper>
                {emojiList.map(({ emoji, category }, index) => {
                  if (category !== type) return <></>;
                  return (
                    <EmojiWrapper
                      $selected={false}
                      $hover={
                        highlightedOptionIndex > recentEmojiCount - 1
                          ? highlightedOptionIndex === recentEmojiCount + index
                          : false
                      }
                      key={index}
                      onClick={handleSelect}
                      id={`emoji-${index + recentEmojiCount}`}
                      onMouseEnter={() => {
                        setHighlightedOptionIndex(recentEmojiCount + index);
                      }}
                    >
                      {emoji}
                    </EmojiWrapper>
                  );
                })}
              </EmojiListWrapper>
            </>
          ))}
        </EmojiContainer>
        <FlexRowSpaced>
          {emojisFooterIcon.map((iconType, index) => (
            <IconButtonWrapper
              $active={
                index === 0
                  ? highlightedOptionIndex < recentEmojiCount
                  : highlightedOptionIndex - recentEmojiCount >=
                      emojiList.findIndex(
                        (e) => e.category === emojisType[index - 1]
                      ) &&
                    highlightedOptionIndex - recentEmojiCount <
                      emojiList.findIndex(
                        (e) => e.category === emojisType[index]
                      )
              }
            >
              <Components.IconButton
                kind="Tertiary"
                onClick={() => {
                  if (index) {
                    const currentTypeFindIndex = emojiList.findIndex(
                      (e) => e.category === emojisType[index - 1]
                    );
                    selectedOptionScrollIntoView(currentTypeFindIndex, "start");
                    setHighlightedOptionIndex(
                      currentTypeFindIndex + recentEmojiCount
                    );
                  } else {
                    selectedOptionScrollIntoView(0);
                    setHighlightedOptionIndex(0);
                  }
                }}
                disabled={false}
                iconType={iconType}
                size="XS"
              />
            </IconButtonWrapper>
          ))}
        </FlexRowSpaced>
      </Components.Menu>
    </Container>
  );
};
