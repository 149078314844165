import { Components } from "@thisgorilla/primate-ds";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { API_CALLS } from "../../../../API/api_calls";

const ReUploadButton = ({
  uploadedFile,
  setTemp_id,
  post_id,
}: {
  uploadedFile: File;
  post_id: string;
  setTemp_id: Dispatch<SetStateAction<string | null>>;
}) => {
  const [progress, setProgress] = useState(0);
  const [isImageUploading, setIsImageUploading] = useState(false);

  let timer: NodeJS.Timer;

  useEffect(() => {
    if (!isImageUploading) {
      setProgress(100);
      clearInterval(timer);
    }
    //eslint-disable-next-line
  }, [isImageUploading]);

  return (
    <ReUploadButtonContainer>
      <Container>
        <Components.IconButton
          disabled={false}
          iconType="ArrowUp"
          kind="Opacity"
          onClick={() => {
            setProgress(0);
            timer = setInterval(() => {
              setProgress((prev) => (prev < 90 ? prev + 10 : prev));
            }, 200);
            API_CALLS.COMMENTS.uploadATemporaryCommentImage(
              {
                image: uploadedFile,
                post_id,
              },
              setTemp_id,
              setIsImageUploading
            );
          }}
          size="XS"
        />
        {isImageUploading && <BackDrop $progress={progress} />}
      </Container>
    </ReUploadButtonContainer>
  );
};

export default ReUploadButton;

const ReUploadButtonContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: ${({ theme }) => theme.spacing["spacing-none"].value}px;
  & > div > button {
    box-shadow: ${({ theme }) => theme.boxShadows["box-shadow-m"]};
  }
`;

const BackDrop = styled.div<{ $progress: number }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: ${({ $progress }) => $progress}%;
  background: ${({ theme }) => theme.Color.icon.brand.subtle.default.value};
  border-radius: 0px 0px 4px 4px;
  transition: height 700ms ease;
`;

const Container = styled.div`
  position: relative;
`;
