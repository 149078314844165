import { Components } from "@thisgorilla/primate-ds";
import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { TextGroup } from "../../../../../../../commonStyles";
import { WithHeightTransition } from "../../../../../../../HOC/WithHeightTransition";
import {
  FlexRow,
  transition,
  TransitionProps,
} from "../../../../../../Common/Styles";
import { PollContentProps } from "./PollContent";

export const VotedFeedBack = ({
  selectedPollOption,
}: PollContentProps.VotedFeedBackProps) => {
  const { typography } = useTheme();
  const [containerHeight, setContainerHeight] = useState(0);

  return (
    <Container $height={containerHeight}>
      <WithHeightTransition setContainerHeight={setContainerHeight}>
        <FlexRow $gap={8}>
          <IconWrapper>
            <Components.Icon type="ThumbsUp" size="16px" />
          </IconWrapper>
          <TextGroup>
            <Components.Text
              text={`You have voted for ${selectedPollOption}. See poll results below.`}
              style={{ tokenStyle: typography.Body.M }}
            />
          </TextGroup>
        </FlexRow>
      </WithHeightTransition>
    </Container>
  );
};
const IconWrapper = styled.div`
  padding-top: 2px;
`;
const Container = styled.div<TransitionProps>(
  ({ theme }) => `
  border-radius: ${theme.radius["radius-xs"].value}px;
  border: 1px solid ${theme.Color.border["ui-border-primary-subtle"].value};
  padding: ${theme.spacing["spacing-4"].value}px;
  background: ${theme.Color.background.neutral.subtle.default.value};
  ${transition}
  `
);
