import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { Auth } from "./components/auth";
import { Pages } from "./components/pages";
import { Protected } from "./components/Protected";
import { ROUTES } from "./utills/routes";

function App() {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.login} element={<Auth.Login />} />
          <Route path={ROUTES.register} element={<Auth.Register />} />
          <Route path={ROUTES.forgotPassword} element={<Auth.ForgotPassword />} />
          <Route
            path={ROUTES.home}
            element={<Protected component={<Pages.Home />} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

const GlobalStyle = createGlobalStyle`
  body {
    scrollbar-width: inherit;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
