import { Components } from "@thisgorilla/primate-ds";
import { ReportPostProps } from "../ReportPost";
import { useGoToNextStep } from "../useGoToNextStep";

export const ReportListItem = ({ label }: ReportPostProps.ReportListItem) => {
  const { goToNextStep } = useGoToNextStep(label);

  return (
    <>
      <Components.ListItem
        key={label}
        type="List Item"
        label={label}
        onClick={goToNextStep}
        ArrowIconType="ChevronRight"
      />
      <Components.ListItem key={`${label}-divider`} type="Divider" />
    </>
  );
};
