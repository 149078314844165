const STEPNUMBERS = {
  get_started: 1,
  location: 1,
  job_title: 2,
  industry: 3,
  interests: 4,
  people_recommendations: 5,
} as const;

const STEPS = Object.keys(STEPNUMBERS) as (keyof typeof STEPNUMBERS)[];
const MAX_STEP_NUMBER = Math.max(...Object.values(STEPNUMBERS));

export const OnboardingUtils = { STEPS, STEPNUMBERS, MAX_STEP_NUMBER };
