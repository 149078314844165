import store, { actions } from "../../redux";
import { ConnectionsProps } from "../../redux/slices/connections/connections";
import { API_INSTANCES } from "../api_instance";
import { handleError } from "../errors";

const dispatch = store.dispatch;

const followAUser = () => {
  API_INSTANCES.CONNECTIONS.followAUser()
    .then()
    .catch((error) => handleError(error, "followAUser"));
};
const getConnections = () => {
  API_INSTANCES.CONNECTIONS.getConnections()
    .then()
    .catch((error) => handleError(error, "getConnections"));
};
const getFollowCounts = () => {
  API_INSTANCES.CONNECTIONS.getFollowCounts()
    .then((response) => {
      const data = response.data as ConnectionsProps.FollowCounts;
      dispatch(actions.connections.setFollowCounts(data));
    })
    .catch((error) => handleError(error, "getFollowCounts"));
};
const getFollowingUsers = () => {
  API_INSTANCES.CONNECTIONS.getFollowingUsers()
    .then()
    .catch((error) => handleError(error, "getFollowingUsers"));
};
const unfollowAUser = () => {
  API_INSTANCES.CONNECTIONS.unfollowAUser()
    .then()
    .catch((error) => handleError(error, "unfollowAUser"));
};

export const CONNECTIONS = {
  followAUser,
  getConnections,
  getFollowCounts,
  getFollowingUsers,
  unfollowAUser,
};
