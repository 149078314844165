import store, { actions } from "../../redux";
import { OnboardingProps } from "../../redux/slices/onboarding/onboarding";
import { API_INSTANCES } from "../api_instance";
import { getErrorMessage, handleError } from "../errors";

const dispatch = store.dispatch;

const getGeoLocationData = () => {
  dispatch(actions.onboarding.setGetGeoLocationData_API_STATUS("progress"));
  API_INSTANCES.ONBOARDING.getGeoLocationData()
    .then((response) => {
      const data = response.data as OnboardingProps.getGeoLocationDataResponse;
      dispatch(actions.onboarding.setGetGeoLocationDataResponse(data));

      dispatch(actions.onboarding.setGetGeoLocationData_API_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.onboarding.setGetGeoLocationData_API_STATUS("error"));
      handleError(error, "getGeoLocationData");
    });
};
const queryCityByUserInput = (
  payload: OnboardingProps.queryCityByInputPayload
) => {
  dispatch(actions.onboarding.setQueryCityByUserInput_API_STATUS("progress"));
  API_INSTANCES.ONBOARDING.queryCityByUserInput(payload)
    .then((response) => {
      const data = response.data as OnboardingProps.queryCityByInputResponse;
      dispatch(actions.onboarding.setQueryCityByUserInputResponse(data));

      dispatch(
        actions.onboarding.setQueryCityByUserInput_API_STATUS("success")
      );
    })
    .catch((error) => {
      dispatch(actions.onboarding.setQueryCityByUserInput_API_STATUS("error"));
      const errorMessage = getErrorMessage(error);
      if (errorMessage === "No matching city data found") {
        dispatch(actions.onboarding.setQueryCityByUserInputResponse([]));
        return;
      }
      handleError(error, "queryCityByUserInput");
    });
};
const saveCityLocation = (payload: OnboardingProps.saveCityLocationPayload) => {
  dispatch(actions.onboarding.setSaveCityLocation_API_STATUS("progress"));
  API_INSTANCES.ONBOARDING.saveCityLocation(payload)
    .then((response) => {
      const data = response.data as OnboardingProps.saveCityLocationResponse;
      dispatch(actions.onboarding.setSaveCityLocationResponse(data));

      dispatch(actions.onboarding.setSaveCityLocation_API_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.onboarding.setSaveCityLocation_API_STATUS("error"));
      handleError(error, "saveCityLocation");
    });
};

const getUserIndustries = () => {
  dispatch(actions.onboarding.setGetUserIndustries_API_STATUS("progress"));
  API_INSTANCES.ONBOARDING.getUserIndustries()
    .then((response) => {
      const data = response.data as OnboardingProps.getUserIndustriesResponse;
      dispatch(actions.onboarding.setGetUserIndustriesResponse(data));

      dispatch(actions.onboarding.setGetUserIndustries_API_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.onboarding.setGetUserIndustries_API_STATUS("error"));
      handleError(error, "getUserIndustries");
    });
};
const searchIndustries = (payload: OnboardingProps.searchIndustriesPayload) => {
  dispatch(actions.onboarding.setSearchIndustries_API_STATUS("progress"));
  API_INSTANCES.ONBOARDING.searchIndustries(payload)
    .then((response) => {
      const data = response.data as OnboardingProps.searchIndustriesResponse;
      dispatch(actions.onboarding.setSearchIndustriesResponse(data));

      dispatch(actions.onboarding.setSearchIndustries_API_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.onboarding.setSearchIndustries_API_STATUS("error"));
      handleError(error, "searchIndustries");
    });
};
const addUserIndustries = (
  payload: OnboardingProps.addUserIndustriesPayload
) => {
  dispatch(actions.onboarding.setAddUserIndustries_API_STATUS("progress"));
  API_INSTANCES.ONBOARDING.addUserIndustries(payload)
    .then((response) => {
      const data = response.data as OnboardingProps.addUserIndustriesResponse;
      dispatch(actions.onboarding.setAddUserIndustriesResponse(data));

      dispatch(actions.onboarding.setAddUserIndustries_API_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.onboarding.setAddUserIndustries_API_STATUS("error"));
      handleError(error, "addUserIndustries");
    });
};

const getUserInterests = () => {
  dispatch(actions.onboarding.setGetUserInterests_API_STATUS("progress"));
  API_INSTANCES.ONBOARDING.getUserInterests()
    .then((response) => {
      const data = response.data as OnboardingProps.getUserInterestsResponse;
      dispatch(actions.onboarding.setGetUserInterestsResponse(data));

      dispatch(actions.onboarding.setGetUserInterests_API_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.onboarding.setGetUserInterests_API_STATUS("error"));
      handleError(error, "getUserInterests");
    });
};
const searchInterests = (payload: OnboardingProps.searchInterestsPayload) => {
  dispatch(actions.onboarding.setSearchInterests_API_STATUS("progress"));
  API_INSTANCES.ONBOARDING.searchInterests(payload)
    .then((response) => {
      const data = response.data as OnboardingProps.searchInterestsResponse;
      dispatch(actions.onboarding.setSearchInterestsResponse(data));

      dispatch(actions.onboarding.setSearchInterests_API_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.onboarding.setSearchInterests_API_STATUS("error"));
      handleError(error, "searchInterests");
    });
};
const addUserInterests = (payload: OnboardingProps.addUserInterestsPayload) => {
  dispatch(actions.onboarding.setAddUserInterests_API_STATUS("progress"));
  API_INSTANCES.ONBOARDING.addUserInterests(payload)
    .then((response) => {
      const data = response.data as OnboardingProps.addUserInterestsResponse;
      dispatch(actions.onboarding.setAddUserInterestsResponse(data));

      dispatch(actions.onboarding.setAddUserInterests_API_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.onboarding.setAddUserInterests_API_STATUS("error"));
      handleError(error, "addUserInterests");
    });
};

const getUserJobTitles = () => {
  dispatch(actions.onboarding.setGetUserJobTitles_API_STATUS("progress"));
  API_INSTANCES.ONBOARDING.getUserJobTitles()
    .then((response) => {
      const data = response.data as OnboardingProps.getUserJobTitlesResponse;
      dispatch(actions.onboarding.setGetUserJobTitlesResponse(data));

      dispatch(actions.onboarding.setGetUserJobTitles_API_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.onboarding.setGetUserJobTitles_API_STATUS("error"));
      handleError(error, "getUserJobTitles");
    });
};
const searchJobTitles = (payload: OnboardingProps.searchJobTitlesPayload) => {
  dispatch(actions.onboarding.setSearchJobTitles_API_STATUS("progress"));
  API_INSTANCES.ONBOARDING.searchJobTitles(payload)
    .then((response) => {
      const data = response.data as OnboardingProps.searchJobTitlesResponse;
      dispatch(actions.onboarding.setSearchJobTitlesResponse(data));

      dispatch(actions.onboarding.setSearchJobTitles_API_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.onboarding.setSearchJobTitles_API_STATUS("error"));
      handleError(error, "searchJobTitles");
    });
};
const addUserJobTitles = (payload: OnboardingProps.addUserJobTitlesPayload) => {
  dispatch(actions.onboarding.setAddUserJobTitles_API_STATUS("progress"));
  API_INSTANCES.ONBOARDING.addUserJobTitles(payload)
    .then((response) => {
      const data = response.data as OnboardingProps.addUserJobTitlesResponse;
      dispatch(actions.onboarding.setAddUserJobTitlesResponse(data));

      dispatch(actions.onboarding.setAddUserJobTitles_API_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.onboarding.setAddUserJobTitles_API_STATUS("error"));
      handleError(error, "addUserJobTitles");
    });
};

const getUserProfile = () => {
  dispatch(actions.onboarding.setGetUserProfile_API_STATUS("progress"));
  API_INSTANCES.ONBOARDING.getUserProfile()
    .then((response) => {
      const data = response.data as OnboardingProps.getUserProfileResponse;
      dispatch(actions.onboarding.setGetUserProfileResponse(data));
      dispatch(actions.me.setFirstName(data.step_1.first_name));
      dispatch(actions.me.setLastName(data.step_1.last_name));
      dispatch(actions.onboarding.setGetUserProfile_API_STATUS("success"));
    })
    .catch((error) => {
      dispatch(actions.onboarding.setGetUserProfile_API_STATUS("error"));
      handleError(error, "getUserProfile");
    });
};

export const ONBOARDING = {
  getGeoLocationData,
  queryCityByUserInput,
  saveCityLocation,

  getUserIndustries,
  searchIndustries,
  addUserIndustries,

  getUserInterests,
  searchInterests,
  addUserInterests,

  getUserJobTitles,
  searchJobTitles,
  addUserJobTitles,

  getUserProfile,
};
