import { Inputs, ThisGorillaUtils } from "@thisgorilla/primate-ds";
import { CommonFieldProps } from "@thisgorilla/primate-ds/dist/inputs/CommonField";
import { useEffect, useMemo, useState } from "react";
import { API_CALLS } from "../../../API/api_calls";
import { actions, useAppDispatch, useAppSelector } from "../../../redux";
import { OnboardingProps } from "../../../redux/slices/onboarding/onboarding";
import { InputContainer } from "../../Common/Styles";
import { OnboardingCommon } from "../common";
import { OnboardingStyles } from "../styles";
import { ONBOARDING_ERRORS, OnboardingUtils } from "../utils";
import { SelectedJobTitles } from "./selectedJobTitles";

export const JobTitle = () => {
  const dispatch = useAppDispatch();
  const { jobTitleOptions, job_titles, searchJobTitles_API_STATUS } =
    useAppSelector((state) => state.onboarding);
  const [jobTitleInput, setJobTitleInput] = useState("");
  useEffect(() => {
    dispatch(actions.onboarding.setSearchJobTitles_API_STATUS("idle"));
    //eslint-disable-next-line
  }, []);

  const options = useMemo(() => {
    const isFirstJobTitleOptionAndInputAreSame =
      jobTitleOptions &&
      jobTitleOptions.length > 0 &&
      jobTitleInput === jobTitleOptions[0].title;
    const isInputExistsInJobTitles =
      job_titles.findIndex((jobTitle) => jobTitle.title === jobTitleInput) !==
      -1;
    const isJobTitleExists =
      isFirstJobTitleOptionAndInputAreSame || isInputExistsInJobTitles;

    const addCustomOption =
      jobTitleInput.length >
        ThisGorillaUtils.DEFAULT_CHARACTER_COUNT_BEFORE_SEARCH &&
      !isJobTitleExists;
    const customOption = {
      label: jobTitleInput,
      value: { title: jobTitleInput },
    };
    const newOptions = addCustomOption ? [customOption] : [];

    if (!jobTitleOptions || !jobTitleOptions.length) return newOptions;

    jobTitleOptions.forEach((jobTitleOption) => {
      const existingJobTitleIndex = job_titles?.findIndex(
        (jobTitle) => jobTitle.title === jobTitleOption.title
      );
      const isJobTitleExists = existingJobTitleIndex !== -1;
      if (isJobTitleExists) return;

      const newOption = {
        label: jobTitleOption.title,
        value: jobTitleOption,
      } as CommonFieldProps.OptionProps;
      newOptions.push(newOption);
    });

    return newOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTitleOptions]);

  const emptyOptions: CommonFieldProps.OptionProps[] = [
    {
      label: ONBOARDING_ERRORS.emptyJobTitle,
      value: {} as OnboardingProps.JobTitle,
    },
  ];

  const showEmptyOptions = OnboardingUtils.getShowEmptyOptions({
    inputLength: jobTitleInput?.length,
    optionsLength: options?.length,
    apiStatus: searchJobTitles_API_STATUS,
  });

  return (
    <OnboardingStyles.Container $stepName="job_title">
      <OnboardingStyles.TitleAndDescription>
        <OnboardingCommon.Title text="What’s your job title?" />
        <OnboardingStyles.Description>
          <OnboardingCommon.Description text="Tell us about your job title. Would you like to add related job titles?" />
        </OnboardingStyles.Description>
      </OnboardingStyles.TitleAndDescription>

      <SelectedJobTitles />
      <InputContainer>
        <Inputs.Search
          fieldName="job_titles"
          idPrefix="jobTitle"
          isRequired={true}
          placeholder="Enter your job title"
          value={jobTitleInput}
          onChangeHandler={(value) => {
            setJobTitleInput(value);
          }}
          searchFunction={(title) => {
            API_CALLS.ONBOARDING.searchJobTitles({ title });
          }}
          handleSelect={({ value }) => {
            if (!value.title) return;
            setJobTitleInput("");

            const existingJobTitleIndex = job_titles.findIndex(
              (jobTitle) => jobTitle.title === value.title
            );
            const isJobTitleExists = existingJobTitleIndex !== -1;
            if (isJobTitleExists) return;

            dispatch(actions.onboarding.addJobTitle(value));
            dispatch(actions.onboarding.clearJobTitleOptions());
          }}
          options={showEmptyOptions ? emptyOptions : options}
          scrollContainerId="onboarding-step-container"
        />
      </InputContainer>
    </OnboardingStyles.Container>
  );
};
