import store, { actions } from "../../redux";
import { InfoProps } from "../../redux/slices/info/info";
import { API_INSTANCES } from "../api_instance";

const dispatch = store.dispatch;
const getEntityInfo = (props: InfoProps.getEntityInfoPayload) => {
  dispatch(actions.info.setEntityInfo_API_STATUS("progress"));
  API_INSTANCES.INFO.getEntityInfo(props)
    .then((response) => {
      const data = response.data as InfoProps.getEntityInfoResponse;
      dispatch(actions.info.setEntityInfoResponse(data));
      dispatch(actions.info.setEntityInfo_API_STATUS("success"));
    })
    .catch(() => {
      dispatch(actions.info.setEntityInfo_API_STATUS("error"));
    });
};

export const INFO = { getEntityInfo };
