import { useMemo } from "react";
import { useAppSelector } from "../../../redux";
import { OnboardingProps } from "../../../redux/slices/onboarding/onboarding";
import { OnboardingComponentProps } from "../OnboardingComponent";

export const useIsStepValid = ({
  validateFields,
}: OnboardingComponentProps.useIsStepValidProps) => {
  const onboarding = useAppSelector((state) => state.onboarding);

  const isStepValid = useMemo(() => {
    return validateFields?.every((field) => {
      const value = onboarding[field as keyof OnboardingProps.State];

      if (Array.isArray(value)) return value.length > 0;
      if (typeof value === "object") return Object.keys(value).length > 0;
      return value ? true : false;
    });
  }, [onboarding, validateFields]);

  return isStepValid;
};
