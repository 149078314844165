import { Components, TokensProps } from "@thisgorilla/primate-ds";
import styled, { useTheme } from "styled-components";
import { PollContentProps } from "./PollContent";

export const PollOption = ({
  title,
  onClick,
  isVoted,
  percentage,
  isPollActive,
}: PollContentProps.PollOption) => {
  const { typography, Color } = useTheme();
  const showVotePercentage = isVoted || !isPollActive;

  return (
    <Container
      onClick={onClick}
      $showVotePercentage={showVotePercentage}
      $percentage={percentage}
    >
      <Components.Text
        text={title}
        style={{ tokenStyle: typography.Body.L }}
        color={
          showVotePercentage ? Color.text["text-on-bold"].value : undefined
        }
        renderInSingleLine={true}
        maxLineLength={1}
      />
      {showVotePercentage && (
        <Components.Counter
          color={percentage < 80 ? "Primary-Bold" : "Primary"}
          count={`${percentage}%`}
          kind="Fill"
          size="Large"
        />
      )}
    </Container>
  );
};

const getGradientBackground = (
  theme: TokensProps.ThemeProps,
  percentage: number
) => {
  return `linear-gradient(to right, ${
    theme.Color.icon.brand.bold.default.value
  } ${percentage}%, ${theme.Color.background.brand.default.value} ${
    percentage + 20
  }%, ${theme.Color.background.brand.default.value} 95.85%)`;
};
const Container = styled.div<{
  $showVotePercentage: boolean;
  $percentage: number;
}>(({ theme, $showVotePercentage, $percentage }) => {
  return `
    display:flex;
    justify-content:space-between;
    border-radius: ${theme.radius["radius-xxs"].value}px;
    padding-top: ${theme.spacing["spacing-2"].value}px;
    padding-right: ${theme.spacing["spacing-4"].value}px;
    padding-bottom: ${theme.spacing["spacing-2"].value}px;
    padding-left: ${theme.spacing["spacing-4"].value}px;
    background: ${
      $showVotePercentage
        ? getGradientBackground(theme, $percentage)
        : theme.Color.background.brand.subtlest["default-hover"].value
    };
    cursor: ${$showVotePercentage ? "default" : "pointer"};
    height: 40px;
    transition: background-color 200ms ease-in-out;
    &:hover {
        background: ${
          $showVotePercentage
            ? getGradientBackground(theme, $percentage)
            : theme.Color.background.brand.subtlest["default-active"].value
        };
        border: ${$showVotePercentage ? 0 : 1}px solid
          ${
            $showVotePercentage
              ? "transparent"
              : theme.Color.border["ui-border-emphasize-brand"].value
          };
      }
`;
});
