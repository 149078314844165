import styled from "styled-components";
import { FlexRowSpaced } from "../../../../../Common/Styles";
import { PostCardHeaderLeft } from "./1-PostCardHeaderLeft";
import { PostCardHeaderRight } from "./2-PostCardHeaderRight";

export const PostCardHeader = () => {
  return (
    <Container>
      <PostCardHeaderLeft />
      <PostCardHeaderRight />
    </Container>
  );
};

const Container = styled(FlexRowSpaced)`
  padding: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  padding-bottom: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
