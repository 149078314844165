import { Components } from "@thisgorilla/primate-ds";
import { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { useAppSelector } from "../../redux";
import { ModalContext } from "../Common/Modal";

export const Illustration = () => {
  const { typography } = useTheme();
  const { headerRef } = useContext(ModalContext);
  const { currentStep } = useAppSelector((state) => state.onboarding);
  const isRecommendationStep = currentStep === "people_recommendations";

  return (
    <Container ref={headerRef} $isRecommendationStep={isRecommendationStep}>
      <Components.Text
        text={`Illustration / Lotte animation`}
        style={{ tokenStyle: typography.Body.S }}
      />
    </Container>
  );
};

const Container = styled.div<{ $isRecommendationStep: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ $isRecommendationStep }) =>
    $isRecommendationStep ? "45px 164px" : "116px 164px"};
  border-bottom: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-default"].value};
`;
