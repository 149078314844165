import {
  actions,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../redux";
import { reportPostUtils } from "../../../../../../redux/slices/reportPost/utils";

export const useGoBackToPrevStep = () => {
  const dispatch = useAppDispatch();
  const { reportPostStep } = useAppSelector((state) => state.reportPost);
  const currentStepNumber =
    reportPostUtils.ReportPostStepNumber[reportPostStep];
  const prevStepNumber = currentStepNumber - 2;
  const prevStep = reportPostUtils.REPORT_POST_STEPS[prevStepNumber];

  const goBackToPrevStep = () => {
    dispatch(actions.reportPost.setReportPostStep(prevStep));
  };

  return { goBackToPrevStep };
};
