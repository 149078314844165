import instance from "../../axios/axios-user";
import { OnboardingProps } from "../../redux/slices/onboarding/onboarding";
import { API_PATHS } from "../api_paths";

const getGeoLocationData = () =>
  instance(API_PATHS.onboarding.getGeoLocationData);
const queryCityByUserInput = (
  payload: OnboardingProps.queryCityByInputPayload
) => instance.post(API_PATHS.onboarding.queryCityByUserInput, payload);
const saveCityLocation = (payload: OnboardingProps.saveCityLocationPayload) =>
  instance.post(API_PATHS.onboarding.saveCityLocation, payload);

const getUserIndustries = () =>
  instance(API_PATHS.onboarding.getUserIndustries);
const searchIndustries = (payload: OnboardingProps.searchIndustriesPayload) =>
  instance.post(API_PATHS.onboarding.searchIndustries, payload);
const addUserIndustries = (payload: OnboardingProps.addUserIndustriesPayload) =>
  instance.post(API_PATHS.onboarding.addUserIndustries, payload);

const getUserInterests = () => instance(API_PATHS.onboarding.getUserInterests);
const searchInterests = (payload: OnboardingProps.searchInterestsPayload) =>
  instance.post(API_PATHS.onboarding.searchInterests, payload);
const addUserInterests = (payload: OnboardingProps.addUserInterestsPayload) =>
  instance.post(API_PATHS.onboarding.addUserInterests, payload);

const getUserJobTitles = () => instance(API_PATHS.onboarding.getUserJobTitles);
const searchJobTitles = (payload: OnboardingProps.searchJobTitlesPayload) =>
  instance.post(API_PATHS.onboarding.searchJobTitles, payload);
const addUserJobTitles = (payload: OnboardingProps.addUserJobTitlesPayload) =>
  instance.post(API_PATHS.onboarding.addUserJobTitles, payload);

const getUserProfile = () => instance(API_PATHS.onboarding.getUserProfile);

export const ONBOARDING = {
  getGeoLocationData,
  queryCityByUserInput,
  saveCityLocation,

  getUserIndustries,
  searchIndustries,
  addUserIndustries,

  getUserInterests,
  searchInterests,
  addUserInterests,

  getUserJobTitles,
  searchJobTitles,
  addUserJobTitles,

  getUserProfile,
};
