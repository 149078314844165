import { createContext, useState } from "react";
import styled from "styled-components";
import { Modal } from "../../../../../../components/Common/Modal";
import {
  FlexColumn,
  FlexColumnWithTransition,
} from "../../../../../../components/Common/Styles";
import { WithHeightTransition } from "../../../../../../HOC/WithHeightTransition";
import { WithSlider } from "../../../../../../HOC/WithSlider";
import { useAppSelector } from "../../../../../../redux";
import { SliderDirection } from "../../../../../../redux/common";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { ReportPostProps } from "./ReportPost";
import { ReportPostUtils } from "./utils";

export const ReportPostContext = createContext({} as ReportPostProps.Context);
export const ReportPost = ({ setReportPostOpen }: ReportPostProps.IProps) => {
  const { reportPostStep } = useAppSelector((state) => state.reportPost);
  const [direction, setDirection] = useState("to-right" as SliderDirection);
  const [containerHeight, setContainerHeight] = useState(0);

  return (
    <ReportPostContext.Provider value={{ direction, setDirection }}>
      <Modal
        from="report-post"
        type="Pages"
        showCloseButton={true}
        setCloseButton={setReportPostOpen}
        stretchToFullWidth={false}
        maxWidth={510}
        minWidth={478}
        zIndex={2}
      >
        <Container $gap={16}>
          <Header />
          <ReportPostContainer $height={containerHeight} $gap={4}>
            <WithHeightTransition setContainerHeight={setContainerHeight}>
              <WithSlider stepKey={reportPostStep} direction={direction}>
                {ReportPostUtils.Component[reportPostStep]}
              </WithSlider>
            </WithHeightTransition>
          </ReportPostContainer>
          <Footer />
        </Container>
      </Modal>
    </ReportPostContext.Provider>
  );
};

const Container = styled(FlexColumn)``;
const ReportPostContainer = styled(FlexColumnWithTransition)`
  padding: 0 ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;
