import { createSlice } from "@reduxjs/toolkit";
import { ReportPostProps } from "./reportPost";

const initialState: ReportPostProps.State = {
  reportPostStep: "why_reporting",
  why_reporting_answer: "",
  best_problem_description_answer: "",
  what_is_happening_answer: "",
};

const slice = createSlice({
  name: "reportPost",
  initialState,
  reducers: {
    setReportPostStep: (state, action: ReportPostProps.setReportPostStep) => {
      state.reportPostStep = action.payload;
    },
    setWhyReportingAnswer: (
      state,
      action: ReportPostProps.setWhyReportingAnswer
    ) => {
      state.why_reporting_answer = action.payload;
    },
    setBestProblemDescriptionAnswer: (
      state,
      action: ReportPostProps.setBestProblemDescriptionAnswer
    ) => {
      state.best_problem_description_answer = action.payload;
    },
    setWhatIsHappeningAnswer: (
      state,
      action: ReportPostProps.setWhatIsHappeningAnswer
    ) => {
      state.what_is_happening_answer = action.payload;
    },
  },
});

export const reportPostActions = slice.actions;
export const reportPostReducer = slice.reducer;
