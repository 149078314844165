import { Components } from "@thisgorilla/primate-ds";
import styled from "styled-components";
import { actions, useAppDispatch, useAppSelector } from "../../../redux";
import { FlexRow } from "../../Common/Styles";

export const SelectedJobTitles = () => {
  const dispatch = useAppDispatch();
  const { job_titles } = useAppSelector((state) => state.onboarding);

  if (!job_titles || !job_titles.length) return null;
  return (
    <Container $gap={16}>
      {job_titles.map((jobTitle, index) => (
        <Components.Chip
          key={`${jobTitle.id}-${index}`}
          content={jobTitle.title}
          kind="Primary"
          showCloseIcon={true}
          onClose={() => {
            dispatch(actions.onboarding.removeJobTitle(jobTitle));
          }}
          tooltipPosition="top"
        />
      ))}
    </Container>
  );
};

const Container = styled(FlexRow)`
  flex-flow: row;
  flex-wrap: wrap;
`;
