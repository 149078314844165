import { Components } from "@thisgorilla/primate-ds";
import { useContext } from "react";
import styled, { useTheme } from "styled-components";

import { CommentHeaderContext } from ".";
import { API_CALLS } from "../../../../../API/api_calls";
import { useClickOutside } from "../../../../../hooks/useClickOutside";
import { useAppSelector } from "../../../../../redux";
import { FlexColumn } from "../../../Styles";

export const CommentOptions = () => {
  const { spacing } = useTheme();
  const { id } = useAppSelector((state) => state.me);
  const { allPostComments } = useAppSelector((state) => state.comments);
  const {
    commentOptionsRef,
    setShowCommentOptions,
    comment_id,
    comment_user_name,
    post_id,
    user_id,
    setReportCommentOpen,
    setIsEditComment,
    parent_comment_id,
  } = useContext(CommentHeaderContext);
  const isCurrentUserComment = id === user_id;
  const { page_size, page } = allPostComments[post_id];

  useClickOutside({
    ref: commentOptionsRef,
    setState: setShowCommentOptions,
  });
  return (
    <Container ref={commentOptionsRef}>
      <Components.Menu
        size="large"
        withBackground={true}
        itemGap={Number(spacing["spacing-1"].value)}
      >
        {isCurrentUserComment ? (
          <>
            <Components.ListItem
              type="List Item"
              label="Edit comment"
              iconType="Edit3"
              onClick={() => {
                setShowCommentOptions(false);
                setIsEditComment(true);
              }}
            />
            <Components.ListItem type="Divider" />
            <Wrapper>
              <Components.ListItem
                type="List Item"
                label="Delete Comments"
                iconType="Trash2"
                onClick={() => {
                  API_CALLS.COMMENTS.deleteAComment(
                    {
                      comment_id,
                      post_id,
                      page: String(page),
                      page_size: String(page_size),
                    },
                    parent_comment_id ? true : false
                  );
                  setShowCommentOptions(false);
                }}
              />
            </Wrapper>
          </>
        ) : (
          <>
            <Components.ListItem
              type="List Item"
              label={`Mute ${comment_user_name}`}
              iconType="XCircle"
              onClick={() => {
                API_CALLS.COMMENTS.muteAUserOnComment({
                  muted_user_id: user_id,
                  post_id: post_id,
                });
                setShowCommentOptions(false);
              }}
            />
            <Components.ListItem
              type="List Item"
              label="Report comment"
              iconType="Flag"
              onClick={() => {
                setReportCommentOpen(true);
                setShowCommentOptions(false);
              }}
            />
          </>
        )}
      </Components.Menu>
    </Container>
  );
};

const Container = styled(FlexColumn)`
  position: absolute;
  top: 36px;
  right: 0;
  z-index: 2;
`;
const Wrapper = styled(FlexColumn)`
  label {
    color: ${({ theme }) => theme.Color.text["text-emphasize-negative"].value};
  }
  svg path {
    stroke: ${({ theme }) => theme.Color.icon["icon-negative"].value};
  }
`;
