import { Components } from "@thisgorilla/primate-ds";
import { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { CreatePostModalContext } from "..";
import { Divider } from "../../../../../../../commonStyles";

const SchedulePostHeader = () => {
  const { typography } = useTheme();
  const { setSchedulePostOpen } = useContext(CreatePostModalContext);
  return (
    <>
      <Container>
        <Components.Text
          text="Schedule post"
          style={{ tokenStyle: typography.Heading.S }}
          fontWeight="font-weight-semibold"
        />
        <Components.IconButton
          disabled={false}
          iconType="X"
          kind="Tertiary"
          onClick={() => setSchedulePostOpen(false)}
          size="Medium"
        />
      </Container>
      <Divider />
    </>
  );
};

export default SchedulePostHeader;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;
