import { Components } from "@thisgorilla/primate-ds";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";

import { API_CALLS } from "../../../../API/api_calls";
import { API_PATHS } from "../../../../API/api_paths";
import { getImageBase64 } from "../../../../utils";
import ReUploadButton from "./ReUploadButton";

const ImgPreview = ({
  uploadedFile,
  temp_id,
  post_id,
  setTemp_id,
  setUploadedFile,
  setIsImageUploading,
  isEditMode,
  comment_id,
}: {
  uploadedFile: File | null;
  temp_id: string | null;
  comment_id?: string;
  post_id: string;
  isEditMode: boolean;
  setTemp_id: Dispatch<SetStateAction<string | null>>;
  setUploadedFile: Dispatch<SetStateAction<File | null>>;
  setIsImageUploading: Dispatch<SetStateAction<boolean>>;
}) => {
  const [url, setUrl] = useState(
    temp_id
      ? isEditMode && !uploadedFile
        ? process.env.REACT_APP_GORRILA_API + "/images/comments/" + temp_id
        : API_PATHS.posts.post + `${post_id}/comments/temp/image/${temp_id}`
      : ""
  );

  useEffect(() => {
    if (temp_id) {
      setUrl(
        isEditMode && !uploadedFile
          ? process.env.REACT_APP_GORRILA_API + "/images/comments/" + temp_id
          : API_PATHS.posts.post + `${post_id}/comments/temp/image/${temp_id}`
      );
    } else {
      uploadedFile && getImageBase64(uploadedFile).then((url) => setUrl(url));
    }
  }, [uploadedFile, temp_id, post_id, isEditMode]);

  return (
    <ImageContainer>
      <Image $src={url} />
      <DeleteButton>
        <Components.IconButton
          disabled={false}
          iconType="X"
          kind="Opacity"
          onClick={() => {
            if (temp_id) {
              isEditMode && !uploadedFile
                ? setTemp_id(null)
                : API_CALLS.COMMENTS.deleteATemporaryCommentImage(
                    {
                      temp_id,
                      post_id,
                    },
                    setTemp_id,
                    setUploadedFile,
                    setIsImageUploading
                  );
            } else {
              setUploadedFile(null);
            }
          }}
          size="XXS"
        />
      </DeleteButton>

      {!temp_id && (
        <ReUploadButton
          uploadedFile={uploadedFile as File}
          setTemp_id={setTemp_id}
          post_id={post_id}
        />
      )}
    </ImageContainer>
  );
};

export default ImgPreview;

const ImageContainer = styled.div`
  position: relative;
  width: fit-content;
`;
const DeleteButton = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  z-index: 1;
  & > button {
    box-shadow: ${({ theme }) => theme.boxShadows["box-shadow-m"]};
  }
`;

const Image = styled.div<{ $src: string }>`
  display: block;
  width: 70px;
  height: 70px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  background: url(${({ $src }) => $src}) lightgray 50% / cover no-repeat;
`;
