import { Inputs } from "@thisgorilla/primate-ds";
import { CommonFieldProps } from "@thisgorilla/primate-ds/dist/inputs/CommonField";
import { useEffect, useMemo, useState } from "react";
import { API_CALLS } from "../../../API/api_calls";
import { actions, useAppDispatch, useAppSelector } from "../../../redux";
import { OnboardingProps } from "../../../redux/slices/onboarding/onboarding";
import { InputContainer } from "../../Common/Styles";
import { OnboardingCommon } from "../common";
import { OnboardingStyles } from "../styles";
import { ONBOARDING_ERRORS, OnboardingUtils } from "../utils";
import { SelectedIndustries } from "./selectedIndustries";

export const Industry = () => {
  const dispatch = useAppDispatch();
  const { industryOptions, industries, searchIndustries_API_STATUS } =
    useAppSelector((state) => state.onboarding);
  const [industryInput, setIndustryInput] = useState("");
  useEffect(() => {
    dispatch(actions.onboarding.setSearchIndustries_API_STATUS("idle"));
    //eslint-disable-next-line
  }, []);

  const options = useMemo(() => {
    if (!industryOptions || !industryOptions.length) return [];
    const newOptions: CommonFieldProps.OptionProps[] = [];
    industryOptions.forEach((industryOption) => {
      const existingIndustryIndex = industries?.findIndex(
        (industry) => industry.title === industryOption.title
      );
      const isIndustryExists = existingIndustryIndex !== -1;
      if (isIndustryExists) return;
      const newOption = {
        label: industryOption.title,
        value: industryOption,
      } as CommonFieldProps.OptionProps;
      newOptions.push(newOption);
    });
    return newOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industryOptions]);
  const emptyOptions: CommonFieldProps.OptionProps[] = [
    {
      label: ONBOARDING_ERRORS.emptyIndustry,
      value: {} as OnboardingProps.Location,
    },
  ];
  const showEmptyOptions = OnboardingUtils.getShowEmptyOptions({
    inputLength: industryInput?.length,
    optionsLength: options?.length,
    apiStatus: searchIndustries_API_STATUS,
  });

  return (
    <OnboardingStyles.Container $stepName="industry">
      <OnboardingStyles.TitleAndDescription>
        <OnboardingCommon.Title text="What industry are you in?" />
        <OnboardingStyles.DescriptionWithInfo>
          <OnboardingCommon.Description text="We’ve curated industries based on your job title. Please select up to 5 industries. " />
          <OnboardingCommon.Instruction text="Select an industry from the dropdown if your industry isn’t listed." />
        </OnboardingStyles.DescriptionWithInfo>
        <SelectedIndustries />
      </OnboardingStyles.TitleAndDescription>

      <InputContainer>
        <Inputs.Search
          fieldName="industries"
          idPrefix="industry"
          isRequired={true}
          value={industryInput}
          options={showEmptyOptions ? emptyOptions : options}
          onChangeHandler={(value) => {
            setIndustryInput(value);
          }}
          searchFunction={(title) => {
            API_CALLS.ONBOARDING.searchIndustries({ title });
          }}
          placeholder="Enter your industry"
          handleSelect={({ value }) => {
            if (!value.id) return;
            setIndustryInput("");
            dispatch(actions.onboarding.addIndustry(value));
            dispatch(actions.onboarding.clearIndustriesOptions());
          }}
          scrollContainerId="onboarding-step-container"
        />
      </InputContainer>
    </OnboardingStyles.Container>
  );
};
