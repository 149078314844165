import { Dispatch, SetStateAction } from "react";
import store, { actions } from "../../redux";
import { CommentsProps } from "../../redux/slices/comments/comments";
import { API_INSTANCES } from "../api_instance";
import { handleError } from "../errors";

const dispatch = store.dispatch;

const getCommentsForAPost = (
  payload: CommentsProps.getCommentsForAPostPayload
) => {
  API_INSTANCES.COMMENTS.getCommentsForAPost(payload)
    .then((response) => {
      const commentData = response.data as CommentsProps.CommentsResponse;
      dispatch(
        actions.comments.setComments({ commentData, post_id: payload.post_id })
      );
    })
    .catch((error) => handleError(error, "getCommentsForAPost"));
};

const createANewComment = (payload: CommentsProps.createANewCommentPayload) => {
  return API_INSTANCES.COMMENTS.createANewComment(payload)
    .then((response) => {
      const data = response.data as CommentsProps.EditCommentResponse;
      dispatch(actions.comments.setCreateComments(data));
    })
    .catch((error) => handleError(error, "createANewComment"));
};

const updateAComment = (payload: CommentsProps.updateACommentPayload) => {
  return API_INSTANCES.COMMENTS.updateAComment(payload)
    .then((response) => {
      const data = response.data as CommentsProps.Comment;
      dispatch(actions.comments.setUpdateComments(data));
    })
    .catch((error) => handleError(error, "updateAComment"));
};
const deleteAComment = (
  payload: CommentsProps.DeleteACommentPayload,
  isChildComment: boolean
) => {
  return API_INSTANCES.COMMENTS.deleteAComment(payload)
    .then((response) => {
      if (isChildComment) {
        const comment = response.data as CommentsProps.EditCommentResponse;
        dispatch(actions.comments.setDeleteChildComments(comment));
      } else {
        const data = response.data as CommentsProps.ParentCommentDeleteResponse;
        dispatch(
          actions.comments.setDeleteParentComments({
            ...data,
            post_id: payload.post_id,
          })
        );
      }
    })
    .catch((error) => handleError(error, "deleteAComment"));
};

const likeAComment = (payload: CommentsProps.likeOrDislikeCommentPayload) => {
  return API_INSTANCES.COMMENTS.likeAComment(payload)
    .then((response) => {
      const data = response.data as CommentsProps.LikeDisLikeAPIResponse;
      dispatch(
        actions.comments.setLikeDislikeCount({
          ...data,
          ...payload,
          user_voted: "like",
          post_id: payload.post_id,
        })
      );
    })
    .catch((error) => handleError(error, "likeAComment"));
};

const dislikeAComment = (
  payload: CommentsProps.likeOrDislikeCommentPayload
) => {
  return API_INSTANCES.COMMENTS.dislikeAComment(payload)
    .then((response) => {
      const data = response.data as CommentsProps.LikeDisLikeAPIResponse;
      dispatch(
        actions.comments.setLikeDislikeCount({
          ...data,
          ...payload,
          user_voted: "dislike",
          post_id: payload.post_id,
        })
      );
    })
    .catch((error) => handleError(error, "dislikeAComment"));
};
const removeLikeOrDislikeFromAComment = (
  payload: CommentsProps.RemoveLikeOrDislikeFromACommentPayload
) => {
  return API_INSTANCES.COMMENTS.removeLikeOrDislikeFromAComment(payload)
    .then((response) => {
      const data = response.data as CommentsProps.LikeDisLikeAPIResponse;
      dispatch(
        actions.comments.setLikeDislikeCount({
          ...data,
          ...payload,
          user_voted: "",
          post_id: payload.post_id,
        })
      );
    })
    .catch((error) => handleError(error, "dislikeAComment"));
};

const uploadATemporaryCommentImage = (
  data: CommentsProps.uploadATemporaryCommentImagePayload,
  setTemp_id: Dispatch<SetStateAction<string | null>>,
  setIsImageUploading?: Dispatch<SetStateAction<boolean>>
) => {
  setIsImageUploading && setIsImageUploading(true);
  API_INSTANCES.COMMENTS.uploadATemporaryCommentImage(data)
    .then((response) => {
      const data = response.data.temp_id;
      setTemp_id(data);
    })
    .catch((error) => handleError(error, "uploadATemporaryCommentImage"))
    .finally(() => setIsImageUploading && setIsImageUploading(false));
};

const deleteATemporaryCommentImage = (
  data: CommentsProps.deleteATemporaryCommentImagePayload,
  setTemp_id: Dispatch<SetStateAction<string | null>>,
  setUploadedFile: Dispatch<SetStateAction<File | null>>,
  setIsImageUploading: Dispatch<SetStateAction<boolean>>
) => {
  setIsImageUploading && setIsImageUploading(true);
  return API_INSTANCES.COMMENTS.deleteATemporaryCommentImage(data)
    .then(() => {
      setTemp_id(null);
      setUploadedFile(null);
    })
    .catch((error) => handleError(error, "deleteATemporaryCommentImage"))
    .finally(() => setIsImageUploading && setIsImageUploading(false));
};

const muteAUserOnComment = (
  payload: CommentsProps.muteAUserOnCommentPayload
) => {
  return API_INSTANCES.COMMENTS.muteAUserOnComment(payload)
    .then(() => {
      dispatch(actions.comments.removeMuteAUserOnComment(payload));
    })
    .catch((error) => handleError(error, "muteAUserOnComment"));
};

export const COMMENTS = {
  getCommentsForAPost,
  createANewComment,
  updateAComment,
  likeAComment,
  dislikeAComment,
  deleteAComment,
  removeLikeOrDislikeFromAComment,
  uploadATemporaryCommentImage,
  deleteATemporaryCommentImage,
  muteAUserOnComment,
};
