import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Components } from "@thisgorilla/primate-ds";
import { $getRoot } from "lexical";
import { useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import { API_CALLS } from "../../../../API/api_calls";
import { useAppSelector } from "../../../../redux";
import {
  isFormSubmitButtonDisabled,
  validFile,
  validFileTypes,
} from "../../../../utils";
import { EmojiPicker } from "../../EmojiPicker";
import { FlexRow, FlexRowSpaced } from "../../Styles";
import { PostCommentFieldProps } from "../PostCommentField";
import ImgPreview from "./ImgPreview";
import UrlPreview from "./UrlPreview";

const Footer = ({
  formik,
  urlData,
  setTemp_id,
  temp_id,
  post_id,
  parent_comment_id,
  setIsFooterShow,
  comment_id,
  isEditMode,
  onClose,
}: PostCommentFieldProps.Footer) => {
  const { spacing } = useTheme();
  const { values, isValid } = formik;
  const emojiOptionsRef = useRef(null);
  const [editor] = useLexicalComposerContext();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isPreviewShow, setIsPreviewShow] = useState(true);
  const [showEmojiOptions, setShowEmojiOptions] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const { allPostComments } = useAppSelector((state) => state.comments);
  const { page_size, page } = allPostComments[post_id];

  const closeComment = (e: any) => {
    e.stopPropagation();
    onClose();
    setIsFooterShow(false);
    setTimeout(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }, 0);

    if (temp_id) {
      !isEditMode &&
        API_CALLS.COMMENTS.deleteATemporaryCommentImage(
          {
            temp_id,
            post_id,
          },
          setTemp_id,
          setUploadedFile,
          setIsImageUploading
        );
    }
    editor.update(() => {
      const root = $getRoot();
      root.clear();
      setUploadedFile(null);
      setTemp_id(null);
    });
  };

  const submitComment = (e: any) => {
    e.stopPropagation();
    if (isValid) {
      (isEditMode
        ? API_CALLS.COMMENTS.updateAComment({
            content: values.content,
            post_id,
            parent_comment_id: parent_comment_id,
            temp_id,
            page,
            page_size,
            comment_id: comment_id as string,
          })
        : API_CALLS.COMMENTS.createANewComment({
            content: values.content,
            post_id,
            parent_comment_id: parent_comment_id,
            temp_id,
            page,
            page_size,
          })
      ).then(() => {
        editor.update(() => {
          setIsFooterShow(false);
          onClose();
          setUploadedFile(null);
          setTemp_id(null);
          const root = $getRoot();
          root.clear();
          setTimeout(() => {
            if (document.activeElement instanceof HTMLElement) {
              document.activeElement.blur();
            }
          }, 0);
        });
      });
    }
  };

  return (
    <>
      {urlData && isPreviewShow && (
        <UrlPreview urlData={urlData} setIsPreviewShow={setIsPreviewShow} />
      )}
      {!isImageUploading && (temp_id || uploadedFile) && (
        <ImgPreview
          uploadedFile={uploadedFile}
          temp_id={temp_id}
          setTemp_id={setTemp_id}
          setUploadedFile={setUploadedFile}
          post_id={post_id}
          setIsImageUploading={setIsImageUploading}
          isEditMode={isEditMode}
          comment_id={comment_id}
        />
      )}
      <FlexRowSpaced>
        <IconButtonContainer $gap={Number(spacing["spacing-2"].value)}>
          <Components.IconButton
            disabled={uploadedFile ? true : false}
            iconType="Image"
            kind="Secondary"
            onClick={() => fileInputRef?.current?.click()}
            size="Small"
          />
          <Components.IconButton
            disabled={false}
            iconType="Gif"
            kind="Secondary"
            onClick={() => {}}
            size="Small"
          />

          <Components.IconButton
            disabled={false}
            iconType="Smile"
            kind="Secondary"
            onClick={() => {
              setShowEmojiOptions(true);
            }}
            size="Small"
          />
          {showEmojiOptions && (
            <EmojiPicker
              emojiOptionsRef={emojiOptionsRef}
              setShowEmojiOptions={setShowEmojiOptions}
              showEmojiOptions={showEmojiOptions}
            />
          )}
        </IconButtonContainer>
        <FlexRow $gap={Number(spacing["spacing-2"].value)}>
          <Components.Tooltip type="label" label="Cancel">
            <Components.IconButton
              iconType="X"
              kind="Secondary"
              onClick={closeComment}
              size="Small"
              disabled={
                isFormSubmitButtonDisabled({
                  formik,
                  requiredFileds: { content: true },
                }) && (temp_id ? false : true)
              }
            />
          </Components.Tooltip>
          <Components.Tooltip type="label" label="Comment">
            <Components.IconButton
              iconType="Send"
              kind="Primary"
              onClick={submitComment}
              size="Small"
              disabled={
                (isFormSubmitButtonDisabled({
                  formik,
                  requiredFileds: { content: true },
                }) &&
                  (temp_id ? false : true)) ||
                isImageUploading
              }
            />
          </Components.Tooltip>
        </FlexRow>
      </FlexRowSpaced>
      {!uploadedFile && (
        <HiddenInput
          type="file"
          multiple={false}
          disabled={false}
          onChange={(e) => {
            const files = e.target.files;
            if (files?.length) {
              const validFiles = validFile(files);
              if (validFiles.length > 0) {
                setUploadedFile(validFiles[0]);
                API_CALLS.COMMENTS.uploadATemporaryCommentImage(
                  {
                    image: validFiles[0],
                    post_id,
                  },
                  setTemp_id,
                  setIsImageUploading
                );
              }
            }
          }}
          ref={fileInputRef}
          id="image-upload"
          accept={validFileTypes.join(",")}
        />
      )}
    </>
  );
};

export default Footer;

const IconButtonContainer = styled(FlexRow)`
  position: relative;
`;

const HiddenInput = styled.input`
  display: none;
`;
