import { useEffect } from "react";
import { API_CALLS } from "../../API/api_calls";
import { useAppSelector } from "../../redux";
import { OnboardingComponentProps } from "./OnboardingComponent";

export const useInitialValues = () => {
  useEffect(() => {
    API_CALLS.ONBOARDING.getUserProfile();
  }, []);

  const { first_name, last_name, location, job_titles, industries, interests } =
    useAppSelector((state) => state.onboarding);

  const city = location?.combined?.split(",")[0];
  const getStartedInitialValues: OnboardingComponentProps.GetStartedFormValues =
    {
      first_name,
      last_name,
      location: city,
    };
  const locationInitialValues: OnboardingComponentProps.LocationFormValues = {
    location: city,
  };
  const jobTitleInitialValues: OnboardingComponentProps.JobTitleFormValues = {
    job_titles,
  };
  const industryInitialValues: OnboardingComponentProps.IndustryFormValues = {
    industries,
  };
  const interestsInitialValues: OnboardingComponentProps.InterestsFormValues = {
    interests,
  };

  const initialValues: OnboardingComponentProps.FormValues = {
    ...getStartedInitialValues,
    ...locationInitialValues,
    ...jobTitleInitialValues,
    ...industryInitialValues,
    ...interestsInitialValues,
  };

  return initialValues;
};
