import { Components } from "@thisgorilla/primate-ds";
import styled from "styled-components";
import { actions, useAppDispatch, useAppSelector } from "../../../redux";
import { FlexRow } from "../../Common/Styles";

export const SelectedInterests = () => {
  const dispatch = useAppDispatch();
  const { interests } = useAppSelector((state) => state.onboarding);

  if (!interests || !interests.length) return null;
  return (
    <Container $gap={16}>
      {interests.map((interest, index) => (
        <Components.Chip
          key={`${interest.id}-${index}`}
          content={interest.title}
          kind="Primary"
          showCloseIcon
          onClose={() => {
            dispatch(actions.onboarding.removeInterest(interest));
          }}
        />
      ))}
    </Container>
  );
};

const Container = styled(FlexRow)`
  flex-flow: row;
  flex-wrap: wrap;
`;
