import { useFormikContext } from "formik";
import { actions, useAppDispatch, useAppSelector } from "../../../redux";
import { OnboardingProps } from "../../../redux/slices/onboarding/onboarding";
import { OnboardingUtils } from "../../../redux/slices/onboarding/utils";
import { OnboardingComponentProps } from "../OnboardingComponent";
import { useIsStepValid } from "./useIsStepValid";
import { useSaveDataToBackend } from "./useSaveDataToBackend";

export const useNextButtonClick = ({
  validateFields,
}: OnboardingComponentProps.useNextButtonProps) => {
  const onboarding = useAppSelector((state) => state.onboarding);
  const { currentStep } = onboarding;
  const dispatch = useAppDispatch();
  const { setFieldTouched } =
    useFormikContext<OnboardingComponentProps.FormValues>();

  const isStepValid = useIsStepValid({ validateFields });
  const isLastStep = currentStep === "people_recommendations";

  const saveDataToBackend = useSaveDataToBackend();

  return () => {
    if (!isStepValid) {
      validateFields.forEach((field) => {
        setFieldTouched(field, true);
      });
      return;
    }
    if (isLastStep) dispatch(actions.me.setOnboarded(true));

    const nextStepNumber = OnboardingUtils.STEPNUMBERS[currentStep] + 1;
    const nextStep = OnboardingUtils.STEPS[
      nextStepNumber
    ] as OnboardingProps.Step;
    dispatch(actions.onboarding.setCurrentStep(nextStep));
    saveDataToBackend();
  };
};
