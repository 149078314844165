import instance from "../../axios/axios-user";
import { CommentsProps } from "../../redux/slices/comments/comments";
import { API_PATHS } from "../api_paths";

const getCommentsForAPost = ({
  post_id,
  sort_by,
  page,
  page_size,
}: CommentsProps.getCommentsForAPostPayload) => {
  return instance(
    `${API_PATHS.posts.post + post_id}/comments?${new URLSearchParams({
      sort_by,
      page,
      page_size,
    }).toString()}`
  );
};

const createANewComment = (data: CommentsProps.createANewCommentPayload) =>
  instance(
    `${API_PATHS.posts.post + data.post_id}/comments?${new URLSearchParams({
      page: String(data.page),
      page_size: String(data.page_size),
    }).toString()}`,
    {
      method: "POST",
      data,
    }
  );

const updateAComment = (data: CommentsProps.updateACommentPayload) => {
  const { comment_id, post_id, page, page_size } = data;
  if (data.temp_id === null) {
    delete data.temp_id;
  }
  return instance(
    `${
      API_PATHS.posts.post + post_id
    }/comments/${comment_id}?${new URLSearchParams({
      page: String(page),
      page_size: String(page_size),
    }).toString()}`,
    {
      method: "PUT",
      data,
    }
  );
};

const deleteAComment = ({
  comment_id,
  post_id,
  page,
  page_size,
}: CommentsProps.DeleteACommentPayload) =>
  instance(
    `${
      API_PATHS.posts.post + post_id
    }/comments/${comment_id}?${new URLSearchParams({
      page,
      page_size,
    }).toString()}`,
    {
      method: "DELETE",
    }
  );

const likeAComment = ({
  comment_id,
}: CommentsProps.likeOrDislikeCommentPayload) =>
  instance(`${API_PATHS.comment.comments + comment_id}/likes`, {
    method: "POST",
  });

const dislikeAComment = ({
  comment_id,
}: CommentsProps.likeOrDislikeCommentPayload) =>
  instance(`${API_PATHS.comment.comments + comment_id}/dislikes`, {
    method: "POST",
  });

const removeLikeOrDislikeFromAComment = ({
  comment_id,
}: CommentsProps.RemoveLikeOrDislikeFromACommentPayload) =>
  instance(`${API_PATHS.comment.comments + comment_id}/likes`, {
    method: "DELETE",
  });

const uploadATemporaryCommentImage = (
  data: CommentsProps.uploadATemporaryCommentImagePayload
) => {
  const formData = new FormData();
  formData.append("image", data.image);

  return instance(
    API_PATHS.posts.post + data.post_id + "/comments/temp/image",
    {
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
      },
      data: formData,
    }
  );
};

const deleteATemporaryCommentImage = ({
  post_id,
  temp_id,
}: CommentsProps.deleteATemporaryCommentImagePayload) => {
  return instance(
    API_PATHS.posts.post + post_id + "/comments/temp/delete-image/" + temp_id,
    {
      method: "DELETE",
      data: { temp_id },
    }
  );
};

const muteAUserOnComment = ({
  post_id,
  muted_user_id,
}: CommentsProps.muteAUserOnCommentPayload) => {
  return instance(
    API_PATHS.posts.post + `${post_id}/comments/more-actions/mute`,
    {
      method: "POST",
      data: { muted_user_id, post_id },
    }
  );
};

export const COMMENTS = {
  getCommentsForAPost,
  createANewComment,
  updateAComment,
  likeAComment,
  dislikeAComment,
  deleteAComment,
  removeLikeOrDislikeFromAComment,
  uploadATemporaryCommentImage,
  deleteATemporaryCommentImage,
  muteAUserOnComment,
};
