import { Components } from "@thisgorilla/primate-ds";
import { useState } from "react";
import { styled, useTheme } from "styled-components";
import { WithHeightTransition } from "../../../../../HOC/WithHeightTransition";
import {
  FlexColumn,
  FlexColumnWithTransition,
  FlexRow,
  FlexRowWithFlow,
} from "../../../../Common/Styles";

export const CommonInterests = () => {
  const [containerHeight, setContainerHeight] = useState(0);

  const [showCommonInterests, setShowCommonInterests] = useState(false);
  const { typography, Color, spacing } = useTheme();

  const industries = [
    "Fintech",
    "IT",
    "Healthcare",
    "Education",
    "SaaS",
    "Tech",
    "Software development",
    "Jobtech",
  ];
  const interests = [
    "Product Design",
    "UX Design",
    "UI Design",
    "Software",
    "Tech",
    "Venture capital",
    "AI",
  ];
  const padding = Number(spacing["spacing-2"].value);

  return (
    <Container
      $height={padding + containerHeight + padding}
      $transitionDuration={150}
    >
      <WithHeightTransition setContainerHeight={setContainerHeight}>
        <FlexColumn $gap={8}>
          <Title
            $gap={8}
            onClick={() => setShowCommonInterests((prev) => !prev)}
            key={"title"}
          >
            <TextAndCount>
              <Components.Text
                text="Common interests"
                style={{ tokenStyle: typography.Heading.XXS }}
                fontWeight="font-weight-semibold"
              />
              <Components.Counter
                count={11}
                size="Small"
                color="Dark"
                kind="Fill"
              />
            </TextAndCount>
            <Components.Icon
              type={showCommonInterests ? "ChevronUp" : "ChevronDown"}
              size="16px"
            />
          </Title>

          {showCommonInterests && (
            <CommonInterestsContainer $gap={8} key={"commonInterests"}>
              <ChipsContainer $gap={8}>
                <Components.Text
                  text="Industries"
                  style={{ tokenStyle: typography.Heading.XXS }}
                  color={Color.background.brand.default.value}
                  fontWeight="font-weight-semibold"
                />
                <ChipsWrapper $gap={8}>
                  {industries.map((industry, index) => (
                    <Components.Chip
                      content={industry}
                      kind="Primary"
                      key={industry + index}
                    />
                  ))}
                </ChipsWrapper>
              </ChipsContainer>

              <ChipsContainer $gap={8}>
                <Components.Text
                  text="Interests"
                  style={{ tokenStyle: typography.Heading.XXS }}
                  color={Color.background.positive.default.value}
                  fontWeight="font-weight-semibold"
                />
                <ChipsWrapper $gap={8}>
                  {interests.map((interest, index) => (
                    <Components.Chip
                      content={interest}
                      kind="Positive"
                      key={interest + index}
                    />
                  ))}
                </ChipsWrapper>
              </ChipsContainer>
            </CommonInterestsContainer>
          )}
        </FlexColumn>
      </WithHeightTransition>
    </Container>
  );
};

const Container = styled(FlexColumnWithTransition)`
  ${({ theme }) => {
    return `padding: ${theme.spacing["spacing-2"].value}px;
      border-radius: ${theme.radius["radius-xxs"].value}px;
      background: ${theme.Color.elevation.surface["surface-primary"].value};
    `;
  }}
`;
const Title = styled(FlexRow)`
  justify-content: space-between;
  cursor: pointer;
`;
const ChipsContainer = styled(FlexRow)`
  position: relative;
`;
const ChipsWrapper = styled(FlexRowWithFlow)``;
const CommonInterestsContainer = styled(FlexColumn)``;
const TextAndCount = styled(FlexRow)``;
