import { PostCommentFieldProps } from "./PostCommentField";

export const findCustomURL = (
  node: any
): PostCommentFieldProps.CustomURL | null => {
  if (node.type === "customurl") {
    return node;
  }
  if (Array.isArray(node.children)) {
    for (const child of node.children) {
      const result = findCustomURL(child);
      if (result) return result;
    }
  }
  return null;
};
