import { Components } from "@thisgorilla/primate-ds";
import { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { useAppSelector } from "../../../../../../redux";
import {
  FlexRow,
  FlexRowSpaced,
  FlexRowWithFlow,
} from "../../../../../Common/Styles";
import { PostCardContext } from "../PostCardContent";

export const Topics = () => {
  const { Color, typography } = useTheme();
  const { processed_posts } = useAppSelector((state) => state.newsfeed);
  const { postId } = useContext(PostCardContext);

  if (!postId) return null;
  const original_post = processed_posts[postId];
  const topics = original_post.topics;
  if (!topics || !topics.length) return null;

  return (
    <Container>
      <FlexRow $gap={8}>
        <Components.Text
          text="Topics"
          style={{ tokenStyle: typography.Body.M }}
          color={Color.text["text-emphasize-purple"].value}
          fontWeight="font-weight-semibold"
        />
        <FlexRowWithFlow $gap={4}>
          {topics.map((topic, index) => (
            <Components.Pill
              key={`${topic}-${index}`}
              text={topic.name}
              onClick={() => {}}
              type="Topics"
            />
          ))}
        </FlexRowWithFlow>
      </FlexRow>

      <IconContainer>
        <Components.Tooltip type="label" label="Tooltip needs to be added">
          <Components.Icon
            type="Info"
            color={Color.text["text-emphasize-purple"].value}
            size="16px"
          />
        </Components.Tooltip>
      </IconContainer>
    </Container>
  );
};

const Container = styled(FlexRowSpaced)`
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;
const IconContainer = styled.div`
  padding-top: 4px;
`;
