import { Components } from "@thisgorilla/primate-ds";
import moment from "moment";
import { createContext, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import useMountTransition from "../../../../../hooks/useMountTransition";
import { addProxyUrlToProfileImage } from "../../../../../utils";
import {
  FlexColumn,
  FlexColumnCenter,
  FlexRow,
  FlexRowSpaced,
} from "../../../Styles";
import { CommentProps } from "../Comment";
import { CommentOptions } from "./CommentOptions";
import { ReportComment } from "./ReportComment";

export const CommentHeaderContext = createContext({} as CommentProps.Context);
export const CommentHeader = ({
  comment_user,
  created_at,
  post_id,
  id,
  user_id,
  parent_comment_id,
  setIsEditComment,
}: CommentProps.CommentHeader) => {
  const { typography, Color } = useTheme();
  const commentOptionsRef = useRef<HTMLDivElement>(null);
  const { first_name, last_name, profile_picture, user_location } =
    comment_user;
  const [showCommentOptions, setShowCommentOptions] = useState(false);
  const [reportCommentOpen, setReportCommentOpen] = useState(false);
  const reportModalOpen = useMountTransition(reportCommentOpen, 300);

  return (
    <CommentHeaderContext.Provider
      value={{
        commentOptionsRef,
        setShowCommentOptions,
        post_id,
        comment_id: id,
        comment_user_name: first_name + " " + last_name,
        user_id: user_id,
        setReportCommentOpen,
        parent_comment_id,
        setIsEditComment,
      }}
    >
      <Container>
        <FlexRow $gap={10}>
          <Components.Avatar
            size="Small-32"
            src={addProxyUrlToProfileImage(profile_picture)}
            type="Circle"
            alt="profile-picture"
          />
          <FlexColumn $gap={0}>
            <Components.Text
              style={{ tokenStyle: typography.Body.L }}
              text={`${first_name} ${last_name}`}
              fontWeight="font-weight-bold"
            />
            <Components.Text
              text={user_location}
              style={{ tokenStyle: typography.Body.S }}
              color={Color.text["text-tertiary"].value}
            />
          </FlexColumn>
        </FlexRow>
        <FlexRow $gap={4}>
          <PostedDateAndIcon $gap={2}>
            <Components.Text
              text={moment(created_at).fromNow(true)}
              style={{ tokenStyle: typography.Body.S }}
            />
            <Dot />
            <Components.Icon type="Globe" size="16px" />
          </PostedDateAndIcon>
          <IconButtonContainer>
            <Components.IconButton
              disabled={false}
              iconType="MoreHorizontal"
              kind="Tertiary"
              size="Small"
              onClick={() => setShowCommentOptions((prev) => !prev)}
            />
            {showCommentOptions && <CommentOptions />}
          </IconButtonContainer>
        </FlexRow>
      </Container>
      {(reportModalOpen || reportCommentOpen) && (
        <ReportComment
          setReportCommentOpen={setReportCommentOpen}
          modalOpen={reportCommentOpen ? reportModalOpen : reportCommentOpen}
        />
      )}
    </CommentHeaderContext.Provider>
  );
};

const Container = styled(FlexRowSpaced)`
  align-items: self-start;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-none"].value}px;
`;
const IconButtonContainer = styled.div`
  position: relative;
`;

const PostedDateAndIcon = styled(FlexRow)`
  align-items: center;
`;
const Dot = styled(FlexColumnCenter)`
  background-color: ${({ theme }) => theme.Color.text["text-primary"].value};
  height: 3px;
  width: 3px;
  border-radius: 50%;
`;
