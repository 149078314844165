import styled from "styled-components";

export const Container = styled.span`
  ${({ theme }) => theme.typography.Body.M};
  .paragraph {
    margin: 0;
    position: relative;
  }

  .indent {
    --lexical-indent-base-value: 40px;
  }
  .textBold {
    font-weight: bold;
  }
  .textItalic {
    font-style: italic;
  }
  .textUnderline {
    text-decoration: underline;
  }

  .ol1 {
    padding: 0;
    margin: 0;
    list-style-position: outside;
  }
  .ol2 {
    padding: 0;
    margin: 0;
    list-style-type: upper-alpha;
    list-style-position: outside;
  }
  .ol3 {
    padding: 0;
    margin: 0;
    list-style-type: lower-alpha;
    list-style-position: outside;
  }
  .ol4 {
    padding: 0;
    margin: 0;
    list-style-type: upper-roman;
    list-style-position: outside;
  }
  .ol5 {
    padding: 0;
    margin: 0;
    list-style-type: lower-roman;
    list-style-position: outside;
  }
  .ul {
    padding: 0;
    margin: 0;
    list-style-position: outside;
  }
  .listItem {
    margin: 0 0 0 32px;
  }

  .nestedListItem {
    list-style-type: none;
  }
  .nestedListItem:before,
  .nestedListItem:after {
    display: none;
  }

  * {
    line-height: 24px;
    vertical-align: middle;
    margin: 0;
  }
`;
