import { Components } from "@thisgorilla/primate-ds";
import styled, { useTheme } from "styled-components";
import { FlexColumn, FlexRowWithFlow } from "../../../../../../Common/Styles";

export const YouAreAboutToSubmit = () => {
  const { typography, Color } = useTheme();
  const style = { tokenStyle: typography.Body.M };

  return (
    <Container>
      <Components.Text
        style={{ tokenStyle: typography.Body.L }}
        text="You’re about to submit a report"
        fontWeight="font-weight-semibold"
      />
      <Description>
        <Components.Text
          style={style}
          text="We only remove content that goes against our"
        />
        <Components.Text
          style={style}
          text="Community Standards."
          color={Color.text["text-link"].value}
          isLink={true}
          onLinkClick={() => {
            // TODO: Implement link click for community standards
          }}
        />
        <Components.Text
          style={style}
          text="You can review or edit your report details below."
        />
      </Description>
    </Container>
  );
};
const Container = styled(FlexColumn)(
  ({ theme }) => `
  padding: ${theme.spacing["spacing-2"].value}px;
  border-radius: ${theme.radius["radius-xs"].value}px;
  gap: ${theme.spacing["spacing-2"].value}px;
  background: ${theme.Color.elevation.surface["surface-secondary"].value};
`
);
const Description = styled(FlexRowWithFlow)``;
