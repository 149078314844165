import { Components } from "@thisgorilla/primate-ds";
import { createElement, ReactNode, useMemo } from "react";
import { CommentsProps } from "../../../../../redux/slices/comments/comments";
import { Container } from "./style";
import { addProxyUrlToProfileImage } from "../../../../../utils";

export const TextContent = ({
  blueprint,
  commentMeta,
}: {
  blueprint: string;
  commentMeta: CommentsProps.CommentMeta;
}) => {
  const content = useMemo(
    () => getTextContentForBlueprint(blueprint, commentMeta),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [blueprint]
  );
  if (!blueprint) return null;

  return <Container className="text-content-container">{content}</Container>;
};

const userPattern = /{{[\s]*@user:[^}]*}}/g;
const urlPattern = /{{[\s]*@url:[^}]*}}/g;
const getComponentForUserId = (
  id: string,
  commentMeta: CommentsProps.CommentMeta
) => {
  const cleanId = id.replace(/{{|}}|@user:/g, "").trim();
  const user = commentMeta?.comment_users?.find((user) => user.id === cleanId);
  if (!user) return null;

  const { name = "", profile_picture = "" } = user;

  return (
    <Components.MentionChip
      key={id}
      {...{ name, profile_picture: addProxyUrlToProfileImage(profile_picture) }}
    />
  );
};
const getComponentForUrlId = (
  id: string,
  commentMeta: CommentsProps.CommentMeta
) => {
  const cleanId = id.replace(/{{|}}|@url:/g, "").trim();
  const urlData = commentMeta?.comment_urls?.find((url) => url.id === cleanId);
  if (!urlData || !urlData.enabled) return null;
  const { url, image: imageUrl, title } = urlData;
  return <Components.RichLink key={id} {...{ url, imageUrl, title }} />;
};

const getTextContentForBlueprint = (
  blueprint: string,
  commentMeta: CommentsProps.CommentMeta
) => {
  if (!blueprint) return null;

  const users = blueprint.match(userPattern);
  const uniqueUsers = Array.from(new Set(users));
  const userComponents: { [key: string]: JSX.Element | null } = {};
  uniqueUsers?.forEach((user) => {
    userComponents[user] = getComponentForUserId(user, commentMeta);
  });

  const urls = blueprint.match(urlPattern);
  const uniqueUrls = Array.from(new Set(urls));
  const urlComponents: { [key: string]: JSX.Element | null } = {};
  uniqueUrls?.forEach((url) => {
    urlComponents[url] = getComponentForUrlId(url, commentMeta);
  });

  // Create a temporary div to parse HTML structure
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = blueprint;

  // Function to process text nodes
  const processAttributes = (element: Element) => {
    const props: any = {};
    Array.from(element.attributes).forEach((attr) => {
      if (attr.name === "style") {
        // Convert style string to object
        const styleObj: { [key: string]: string } = {};
        attr.value.split(";").forEach((style) => {
          const [key, value] = style.split(":").map((s) => s.trim());
          if (key && value) {
            // Convert kebab-case to camelCase
            const camelKey = key.replace(/-([a-z])/g, (g) =>
              g[1].toUpperCase()
            );
            styleObj[camelKey] = value;
          }
        });
        props.style = styleObj;
      } else if (attr.name === "class") {
        props.className = attr.value;
      } else {
        props[attr.name] = attr.value;
      }
    });

    return props;
  };
  const processNode = (node: Node): ReactNode => {
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent || "";
      let result: ReactNode[] = [];

      // Check for user mentions
      if (userPattern.exec(text)) {
        const component = userComponents[text];
        if (component) result.push(component);
        return result;
      }

      // Check for URLs
      if (urlPattern.exec(text)) {
        const component = urlComponents[text];
        if (component) result.push(component);
        return result;
      }

      result.push(text);
      return result;
    }

    // Handle element nodes
    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as Element;
      const children = Array.from(element.childNodes).map(processNode);

      const props = processAttributes(element);
      const tagName = element.tagName.toLowerCase();

      return createElement(tagName, props, ...children);
    }

    return null;
  };

  return processNode(tempDiv);
};
