import { Components, Icons } from "@thisgorilla/primate-ds";
import styled, { useTheme } from "styled-components";
import {
  FlexColumn,
  FlexColumnStart,
  FlexRowCenter,
} from "../../../../../../Common/Styles";

type ReportProps = { title: string; description: string };
export const Report = ({ title, description }: ReportProps) => {
  const { typography, Color } = useTheme();
  const textColor = Color.text["text-emphasize-brand-bolder"].value;

  return (
    <ReportContainer $gap={8}>
      <TitleAndDescription $gap={8}>
        <Title $gap={8}>
          <Icons.Info
            size="16px"
            color={Color.icon["icon-brand-bolder"].value}
          />
          <Components.Text
            style={{ tokenStyle: typography.Heading.S }}
            fontWeight="font-weight-semibold"
            text={title}
            color={textColor}
          />
        </Title>
        <Description $gap={0}>
          <Components.Text
            style={{ tokenStyle: typography.Body.M }}
            text={description}
            color={textColor}
          />
        </Description>
      </TitleAndDescription>
    </ReportContainer>
  );
};

const ReportContainer = styled(FlexColumn)(
  ({ theme }) => `
  border-radius: ${theme.spacing["spacing-2"].value}px;
  padding: ${theme.spacing["spacing-4"].value}px;
  background-color: ${theme.Color.background.brand.subtlest["default-hover"].value};
`
);
const TitleAndDescription = styled(FlexColumnStart)``;
const Description = styled(FlexColumn)``;
const Title = styled(FlexRowCenter)``;
