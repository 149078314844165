import { Components } from "@thisgorilla/primate-ds";
import { useContext } from "react";
import styled from "styled-components";

import { SchedulePostModalContext } from ".";
import { CreatePostModalContext } from "..";
import { Divider } from "../../../../../../../commonStyles";
import { isFormSubmitButtonDisabled } from "../../../../../../../utils";
import { scheduledPostUtils } from "./utils";

const SchedulePostFooter = () => {
  const { setSchedulePostOpen } = useContext(CreatePostModalContext);
  const { formik } = useContext(SchedulePostModalContext);
  return (
    <>
      <Divider />
      <Container>
        <Components.Button
          disabled={false}
          kind="Secondary"
          color="Neutral"
          label="Back"
          onClick={() => setSchedulePostOpen(false)}
          size="Medium"
        />
        <Components.Button
          kind="Primary"
          color="Primary"
          label="Next"
          onClick={() => {
            formik.isValid && formik.submitForm();
          }}
          disabled={isFormSubmitButtonDisabled({
            formik,
            requiredFileds: scheduledPostUtils.requiredFileds,
          })}
          size="Medium"
        />
      </Container>
    </>
  );
};

export default SchedulePostFooter;

const Container = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;
