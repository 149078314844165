import { Components } from "@thisgorilla/primate-ds";
import styled, { useTheme } from "styled-components";
import { FlexColumn, FlexRowCenter } from "../../../../../../Common/Styles";
import { ReportDetails } from "./ReportDetails";

export const SubmitSuccess = () => {
  const { Color, typography } = useTheme();

  return (
    <FlexColumn $gap={8}>
      <Components.ListItem key={`submit-success-divider`} type="Divider" />
      <Container $gap={10}>
        <TitleContainer $gap={8}>
          <CheckCircleContainer>
            <Components.Icon
              type="CheckCircle"
              size="20px"
              color={Color.icon.positive.default.value}
            />
          </CheckCircleContainer>
          <Components.Text
            text="Your Report has been submitted."
            style={{ tokenStyle: typography.Body.L }}
            fontWeight="font-weight-semibold"
            color={Color.text["text-emphasize-positive"].value}
          />
        </TitleContainer>
        <ReportDetailsContainer>
          <ReportDetails />
        </ReportDetailsContainer>
      </Container>
    </FlexColumn>
  );
};

const Container = styled(FlexColumn)(
  ({ theme }) => `
  padding: ${theme.spacing["spacing-2"].value}px;
  border-radius: ${theme.spacing["spacing-2"].value}px;
  background-color: ${theme.Color.elevation.surface["surface-secondary"].value};
`
);
const TitleContainer = styled(FlexRowCenter)`
  justify-content: flex-start;
`;
const CheckCircleContainer = styled(FlexRowCenter)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) =>
    theme.Color.icon.positive.subtlest["default-hover"].value};
`;
const ReportDetailsContainer = styled.div(
  ({ theme }) => `
padding: ${theme.spacing["spacing-2"].value}px;
background-color: ${theme.Color.elevation.surface["surface-primary"].value};
border: 1px solid ${theme.Color.border["ui-border-neutral"].value};
border-radius: ${theme.spacing["spacing-2"].value}px;
`
);
