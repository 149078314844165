import { createContext, useState } from "react";
import styled from "styled-components";
import { Modal } from "../../../../../../components/Common/Modal";
import {
  FlexColumn,
  FlexColumnWithTransition,
} from "../../../../../../components/Common/Styles";
import { WithHeightTransition } from "../../../../../../HOC/WithHeightTransition";
import { WithSlider } from "../../../../../../HOC/WithSlider";
import { SliderDirection } from "../../../../../../redux/common";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { ReportCommentProps } from "./ReportComment";
import { reportCommentUtils } from "./utils";

export const ReportCommentContext = createContext(
  {} as ReportCommentProps.Context
);
export const ReportComment = ({
  setReportCommentOpen,
  modalOpen
}: ReportCommentProps.IProps) => {
  const [direction, setDirection] = useState("to-right" as SliderDirection);
  const [containerHeight, setContainerHeight] = useState(0);
  const [reportComment, setReportComment] =
    useState<ReportCommentProps.ReportComment>({
      reportCommentStep: "why_reporting",
      why_reporting_answer: "",
      best_problem_description_answer: "",
      what_is_happening_answer: "",
    });

  return (
    <ReportCommentContext.Provider
      value={{
        direction,
        setDirection,
        reportComment,
        setReportComment,
        setReportCommentOpen,
      }}
    >
      <Modal
        from="report-comment"
        type="Pages"
        withAnimation
        modalOpen={modalOpen}
        showCloseButton={true}
        setCloseButton={setReportCommentOpen}
        stretchToFullWidth={false}
        maxWidth={510}
        minWidth={478}
        zIndex={2}
      >
        <Container $gap={16}>
          <Header />
          <ReportCommentContainer $height={containerHeight} $gap={4}>
            <WithHeightTransition setContainerHeight={setContainerHeight}>
              <WithSlider
                stepKey={reportComment.reportCommentStep}
                direction={direction}
              >
                {reportCommentUtils.Component[reportComment.reportCommentStep]}
              </WithSlider>
            </WithHeightTransition>
          </ReportCommentContainer>
          <Footer />
        </Container>
      </Modal>
    </ReportCommentContext.Provider>
  );
};

const Container = styled(FlexColumn)``;
const ReportCommentContainer = styled(FlexColumnWithTransition)`
  padding: 0 ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;
