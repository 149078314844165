import { Components } from "@thisgorilla/primate-ds";
import { useContext } from "react";
import styled from "styled-components";
import { useAppSelector } from "../../../redux";
import { OnboardingUtils } from "../../../redux/slices/onboarding/utils";
import { ModalContext } from "../../Common/Modal";
import { OnboardingComponentProps } from "../OnboardingComponent";
import { useBackbuttonClick } from "./useBackbuttonClick";
import { useIsStepValid } from "./useIsStepValid";
import { useNextButtonClick } from "./useNextButtonClick";

export const Footer = ({
  validateFields,
}: OnboardingComponentProps.FooterProps) => {
  const onboarding = useAppSelector((state) => state.onboarding);
  const { currentStep } = onboarding;
  const backButtonClick = useBackbuttonClick();
  const nextButtonClick = useNextButtonClick({ validateFields });

  const { footerRef } = useContext(ModalContext);

  const commonProps = {
    kind: "Primary",

    size: "Medium",
    disabled: false,
  } as const;

  const isFirstStep =
    currentStep === "location" || currentStep === "get_started";
  const isLastStep = currentStep === "people_recommendations";

  const isStepValid = useIsStepValid({ validateFields });

  return (
    <Container ref={footerRef}>
      <ButtonContainer>
        {!isFirstStep && !isLastStep ? (
          <Components.Button
            {...commonProps}
            color="Neutral"
            label="Back"
            disabled={isFirstStep}
            onClick={backButtonClick}
          />
        ) : (
          <div className="back-button-placeholder" />
        )}

        <Components.Button
          {...commonProps}
          color="Primary"
          label={isLastStep ? "Done" : "Next"}
          disabled={!isStepValid}
          onClick={nextButtonClick}
        />
      </ButtonContainer>
      <StepperContainer>
        <Components.Stepper
          onColor="On White"
          selectedStep={OnboardingUtils.STEPNUMBERS[currentStep]}
          steps={OnboardingUtils.MAX_STEP_NUMBER}
          view="Gallery only"
        />
      </StepperContainer>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  bottom: 0;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 24px 32px;
  justify-content: space-between;
`;
const StepperContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
`;
