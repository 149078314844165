import { Components } from "@thisgorilla/primate-ds";
import { useEffect } from "react";
import { useTheme } from "styled-components";
import { API_CALLS } from "../../../../API/api_calls";
import { Divider } from "../../../../commonStyles";
import { useAppSelector } from "../../../../redux";
import { RecommendedJobsList } from "./RecommendedJobsList";
import { RightPanelStyles } from "./RightPanel.styled";
import { SuggestedUserList } from "./SuggestedUserList";

export const RightPanel = () => {
  const { typography, Color } = useTheme();
  const { getSuggestedUsers_API_STATUS, getRecommendedJobs_API_STATUS } =
    useAppSelector((state) => state.me);

  useEffect(() => {
    if (getSuggestedUsers_API_STATUS !== "idle") return;

    API_CALLS.ME.getSuggestedUsers();
  }, [getSuggestedUsers_API_STATUS]);

  useEffect(() => {
    if (getRecommendedJobs_API_STATUS !== "idle") return;

    API_CALLS.ME.getRecommendedJobs();
  }, [getRecommendedJobs_API_STATUS]);

  return (
    <RightPanelStyles.Container className="right-panel">
      <RightPanelStyles.Title>
        <Components.Text
          text="Suggested for you"
          fontWeight="font-weight-medium"
          style={{ tokenStyle: typography.Heading.S }}
        />
      </RightPanelStyles.Title>
      <SuggestedUserList />
      <Divider $color={Color.border["ui-border-subtle"].value} />
      <RightPanelStyles.Title>
        <Components.Text
          text="Jobs for you"
          fontWeight="font-weight-medium"
          style={{ tokenStyle: typography.Heading.S }}
        />
      </RightPanelStyles.Title>
      <RecommendedJobsList />
    </RightPanelStyles.Container>
  );
};
