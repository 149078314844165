import { useContainerSize } from "../../../hooks/useContainerSize";
import { ModalProps } from "./Modal";

export const useModalContentHeight = ({
  headerRef,
  footerRef,
}: ModalProps.useModalContentHeightProps) => {
  const { height: headerHeight } = useContainerSize(headerRef);
  const { height: footerHeight } = useContainerSize(footerRef);

  const maxModalHeight = window.innerHeight * 0.95; // 95dvh in pixels
  const maxContentHeight = maxModalHeight - headerHeight - footerHeight;

  return { maxContentHeight };
};
