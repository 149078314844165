import { Inputs } from "@thisgorilla/primate-ds";
import { CommonFieldProps } from "@thisgorilla/primate-ds/dist/inputs/CommonField";
import { useEffect, useMemo, useState } from "react";
import { API_CALLS } from "../../../API/api_calls";
import { actions, useAppDispatch, useAppSelector } from "../../../redux";
import { OnboardingProps } from "../../../redux/slices/onboarding/onboarding";
import { InputContainer } from "../../Common/Styles";
import { OnboardingCommon } from "../common";
import { OnboardingStyles } from "../styles";
import { ONBOARDING_ERRORS, OnboardingUtils } from "../utils";
import { SelectedInterests } from "./selectedInterests";

export const Interests = () => {
  const dispatch = useAppDispatch();
  const { interestOptions, interests, searchInterests_API_STATUS } =
    useAppSelector((state) => state.onboarding);
  const [interestInput, setInterestInput] = useState("");
  useEffect(() => {
    dispatch(actions.onboarding.setSearchInterests_API_STATUS("idle"));
    //eslint-disable-next-line
  }, []);

  const options = useMemo(() => {
    if (!interestOptions || !interestOptions.length) return [];
    const newOptions: CommonFieldProps.OptionProps[] = [];
    interestOptions.forEach((interestOption) => {
      const existingInterestIndex = interests?.findIndex(
        (interest) => interest.title === interestOption.title
      );
      const isInterestExists = existingInterestIndex !== -1;
      if (isInterestExists) return;
      const newOption = {
        label: interestOption.title,
        value: interestOption,
      } as CommonFieldProps.OptionProps;
      newOptions.push(newOption);
    });
    return newOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interestOptions]);

  const emptyOptions: CommonFieldProps.OptionProps[] = [
    {
      label: ONBOARDING_ERRORS.emptyInterest,
      value: {} as OnboardingProps.Location,
    },
  ];
  const showEmptyOptions = OnboardingUtils.getShowEmptyOptions({
    inputLength: interestInput?.length,
    optionsLength: options?.length,
    apiStatus: searchInterests_API_STATUS,
  });

  return (
    <OnboardingStyles.Container $stepName="interests">
      <OnboardingStyles.TitleAndDescription>
        <OnboardingCommon.Title text="What are your Interests?" />
        <OnboardingStyles.DescriptionWithInfo>
          <OnboardingCommon.Description text="Tell us about your interests. We’ve curated interests below based on your job title and industry." />
          <OnboardingStyles.InstructionContainer $gap={0}>
            <OnboardingCommon.Instruction text="Select an interest from the dropdown if your interests arent accurate. Or add an interest by typing." />
          </OnboardingStyles.InstructionContainer>
        </OnboardingStyles.DescriptionWithInfo>
        <SelectedInterests />
      </OnboardingStyles.TitleAndDescription>

      <InputContainer>
        <Inputs.Search
          fieldName="interests"
          idPrefix="interest"
          isRequired={true}
          value={interestInput}
          onChangeHandler={(value) => {
            setInterestInput(value);
          }}
          options={showEmptyOptions ? emptyOptions : options}
          searchFunction={(title) => {
            API_CALLS.ONBOARDING.searchInterests({ title });
          }}
          placeholder="Enter your interests"
          handleSelect={({ value }) => {
            if (!value.title) return;
            setInterestInput("");
            dispatch(actions.onboarding.addInterest(value));
            dispatch(actions.onboarding.clearInterestsOptions());
          }}
          scrollContainerId="onboarding-step-container"
        />
      </InputContainer>
    </OnboardingStyles.Container>
  );
};
