import { reportCommentUtils } from "../utils";
import { ReportListItem } from "./ListItem";

export const WhyReporting = () => {
  return (
    <>
      {reportCommentUtils.whyReportingListItems.map((item) => (
        <ReportListItem key={item} label={item} />
      ))}
    </>
  );
};
