// Library imports
import { Components } from "@thisgorilla/primate-ds";
import { styled } from "styled-components";

// Local imports
import { useEffect, useRef, useState } from "react";
import { WithSlider } from "../../../HOC/WithSlider";
import { useAppSelector } from "../../../redux";
import { SliderDirection } from "../../../redux/common";
import { FlexColumn, FlexRow } from "../../Common/Styles";
import { OnboardingCommon } from "../common";
import { OnboardingStyles } from "../styles";
import { UserCardWithCommonInterests } from "./UserCardWithCommonInterests";

export const RecommendedPeople = () => {
  const { onboarded, post_theme, emojis } = useAppSelector((state) => state.me);
  const { recommendedUsers } = useAppSelector((store) => store.onboarding);

  const containerRef = useRef<HTMLDivElement>(null);
  const iconButtonWrapperRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const [direction, setDirection] = useState("to-right" as SliderDirection);
  const [currentStep, setCurrentStep] = useState(1);
  const TOTAL_DATA_PER_SLIDE = 3;
  const TOTAL_DATA_LENGTH = recommendedUsers?.length;
  const TOTAL_SLIDES = Math.ceil(TOTAL_DATA_LENGTH / TOTAL_DATA_PER_SLIDE);

  const isFirstSlide = currentStep === 1;
  const isLastSlide = currentStep === TOTAL_SLIDES;
  const startIndex = (currentStep - 1) * TOTAL_DATA_PER_SLIDE;
  const endIndex = startIndex + TOTAL_DATA_PER_SLIDE;

  useEffect(() => {
    titleRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [currentStep]);

  return (
    <OnboardingStyles.Container
      className="people_recommendations"
      $stepName="people_recommendations"
      ref={containerRef}
    >
      <OnboardingStyles.TitleAndDescription ref={titleRef}>
        <OnboardingCommon.Title text="Recommended people to follow..." />
        <OnboardingStyles.Description>
          <OnboardingCommon.Description text="We've curated people that you'd be interested in following below based on your job title, industry and interests." />
        </OnboardingStyles.Description>
      </OnboardingStyles.TitleAndDescription>

      <WithSlider
        stepKey={`recommended-people-${currentStep}`}
        direction={direction}
        animationDuration={300}
      >
        <FlexColumn>
          {recommendedUsers.slice(startIndex, endIndex).map((user, index) => {
            return (
              <UserCardWithCommonInterests
                key={`${user.id}-${index}`}
                showHoverEffect={false}
                user={{
                  ...user,
                  onboarded,
                  post_theme,
                  emojis,
                }}
              />
            );
          })}
        </FlexColumn>
      </WithSlider>
      <IconButtonWrapper
        className="recommended-people-icon-button-wrapper"
        ref={iconButtonWrapperRef}
      >
        {!isFirstSlide ? (
          <Components.IconButton
            iconType="ChevronLeft"
            disabled={isFirstSlide}
            kind="Opacity"
            size="Medium"
            onClick={() => {
              setCurrentStep(currentStep - 1);
              setDirection("to-left");
            }}
          />
        ) : (
          <div />
        )}
        {!isLastSlide && (
          <Components.IconButton
            iconType="ChevronRight"
            disabled={isLastSlide}
            kind="Opacity"
            size="Medium"
            onClick={() => {
              setCurrentStep(currentStep + 1);
              setDirection("to-right");
            }}
          />
        )}
      </IconButtonWrapper>
    </OnboardingStyles.Container>
  );
};

const IconButtonWrapper = styled(FlexRow)`
  justify-content: space-between;
`;
