import { Components } from "@thisgorilla/primate-ds";
import { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { API_CALLS } from "../../../../../../../API/api_calls";
import { useAppSelector } from "../../../../../../../redux";
import { Slider } from "../../../../../../Common/Slider";
import { FlexColumn } from "../../../../../../Common/Styles";
import { EmojiProps } from "../../../../../../Emoji";
import { PostCardContext } from "../../PostCardContent";
import { VotedFeedBack } from "./VotedFeedBack";

export const RateContent = () => {
  const { postId } = useContext(PostCardContext);
  const { typography } = useTheme();
  const { id, emojis } = useAppSelector((state) => state.me);
  const { processed_posts } = useAppSelector((state) => state.newsfeed);
  const original_post = processed_posts[postId];
  const rating = original_post?.interactions?.rating_interaction;
  const isVoted = !!rating?.rating_interaction_user_voted;
  const leftEmoji = (emojis[rating?.left_emoji_id]?.name ||
    "ThumbsDown") as EmojiProps.EmojiType;
  const rightEmoji = (emojis[rating?.right_emoji_id]?.name ||
    "ThumbsUp") as EmojiProps.EmojiType;

  const onDragEnd = (ratingValue: number) => {
    if (!rating) return;

    API_CALLS.POSTS.submitAnswerForARateInteraction({
      choice: ratingValue,
      created_at: new Date().toISOString(),
      id: ratingValue <= 50 ? rating?.left_emoji_id : rating?.right_emoji_id,
      slider_id: rating?.id,
      user_id: id,
    });
  };

  if (!rating || !rating.id) return null;

  const aggregatePercent = rating.user_median_votes?.average_score;

  return (
    <Container>
      <QuestionContainer $gap={8}>
        <Components.Text
          text={rating?.question}
          style={{ tokenStyle: typography.Body.L }}
          fontWeight="font-weight-semibold"
          lineHeight="line-height-200"
        />
        <RateContainer $gap={8}>
          {isVoted && <VotedFeedBack />}
          <Slider
            emojiValue={[leftEmoji, rightEmoji]}
            isInteractive={!isVoted}
            isVoted={isVoted}
            onDragEnd={onDragEnd}
            aggregatePercent={aggregatePercent}
          />
        </RateContainer>
      </QuestionContainer>
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => `
  padding-top: ${theme.spacing["spacing-2"].value}px;
  padding-right: ${theme.spacing["spacing-4"].value}px;
  padding-bottom: ${theme.spacing["spacing-2"].value}px;
  padding-left: ${theme.spacing["spacing-4"].value}px;
  gap:  ${theme.spacing["spacing-2"].value}px;
  
`
);
const QuestionContainer = styled(FlexColumn)(
  ({ theme }) => `
  border-radius: ${theme.radius["radius-xs"].value}px;
  border: 1px solid ${theme.Color.border["ui-border-neutral"].value};
  padding: ${theme.spacing["spacing-2"].value}px;
`
);
const RateContainer = styled(FlexColumn)(
  ({ theme }) => `
  border-radius: ${theme.radius["radius-xs"].value}px;
  border: 1px solid ${theme.Color.border["ui-border-neutral"].value};
  padding-top: ${theme.spacing["spacing-6"].value}px;
  padding-right: ${theme.spacing["spacing-2"].value}px;
  padding-bottom: ${theme.spacing["spacing-6"].value}px;
  padding-left: ${theme.spacing["spacing-2"].value}px;
  
  `
);
