import styled from "styled-components";

import UserInfo from "../UserInfo";
import { UserCardProps } from "./UserCard";
import UserFollowersInfo from "./UserFollowersInfo";

const UserCard = ({ user, showHoverEffect = true }: UserCardProps.IProps) => {
  const { first_name, last_name, profile_picture, headline, id } = user;

  return (
    <Container $showHoverEffect={showHoverEffect} className="user-card">
      <UserInfo
        id={id}
        profile_picture={profile_picture}
        name={first_name + " " + last_name}
        headline={headline}
      />
      <UserFollowersInfo {...user} />
    </Container>
  );
};

export default UserCard;

const Container = styled.div<{ $showHoverEffect: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-3"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px;
  &:hover {
    background: ${({ theme, $showHoverEffect }) =>
      $showHoverEffect
        ? theme.Color.elevation.surface["surface-primary-hover"].value
        : "transparent"};
  }
`;
