import { Components } from "@thisgorilla/primate-ds";
import moment from "moment";
import { useContext, useEffect, useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { SchedulePostModalContext } from ".";
import { FlexColumn, FlexRow } from "../../../../../../Common/Styles";
import Select from "../../../../../../Select";
import { scheduledPostUtils } from "./utils";

const SchedulePostContent = () => {
  const CURRENT_YEAR = moment().year();
  const CURRENT_MONTH = moment().month() + 1;
  const CURRENT_DAY = moment().date();
  const ALL_MONTHS = moment.months();

  const { typography, Color, spacing } = useTheme();
  const { formik } = useContext(SchedulePostModalContext);
  const { values, initialValues, setFieldValue } = formik;
  const { month, day, hour, year } = values;

  const { CommonFieldProps, dateDescription, createDate } = scheduledPostUtils;

  useEffect(() => {
    const intialDate = createDate(initialValues);
    const currentDate = createDate(values);
    const isSame = moment(intialDate).isSame(currentDate);
    const isBefore = moment(intialDate).isBefore(currentDate);
    if (isSame || isBefore) return;

    if (moment(intialDate).diff(currentDate, "months")) {
      setFieldValue("month", moment(intialDate).format("MMMM"));
    } else if (moment(intialDate).diff(currentDate, "days")) {
      setFieldValue("day", moment(intialDate).format("DD"));
    } else if (moment(intialDate).diff(currentDate, "hours")) {
      setFieldValue("hour", moment(intialDate).format("HH"));
    } else if (moment(intialDate).diff(currentDate, "minutes")) {
      setFieldValue("minute", moment(intialDate).format("mm"));
    }
  }, [values, initialValues, createDate, setFieldValue]);

  const monthOption = useMemo(() => {
    if (Number(year) === CURRENT_YEAR)
      return ALL_MONTHS.slice(CURRENT_MONTH - 1);
    return ALL_MONTHS;
  }, [year, ALL_MONTHS, CURRENT_MONTH, CURRENT_YEAR]);

  const dayOption = useMemo(() => {
    const monthNumber = moment().month(month).format("M");
    const daysInMonth = moment(
      `${year}-${monthNumber}`,
      "YYYY-MM"
    ).daysInMonth();
    const days = Array.from({ length: daysInMonth }, (_, i) =>
      String(i + 1).padStart(2, "0")
    );
    const isCurrentDate =
      Number(year) === CURRENT_YEAR && Number(monthNumber) === CURRENT_MONTH;

    if (isCurrentDate) return days.slice(CURRENT_DAY - 1);

    return days;
  }, [year, month, CURRENT_DAY, CURRENT_MONTH, CURRENT_YEAR]);

  const hourOption = useMemo(() => {
    const hours = Array.from({ length: 24 }, (_, i) =>
      String(i).padStart(2, "0")
    );
    const currentHour = moment().hour();
    const monthNumber = moment().month(month).format("M");
    const isCurrentDate =
      Number(day) === CURRENT_DAY &&
      Number(year) === CURRENT_YEAR &&
      Number(monthNumber) === CURRENT_MONTH;

    if (isCurrentDate)
      return hours.filter((hour) => Number(hour) >= currentHour + 1);

    return hours;
  }, [year, month, day, CURRENT_DAY, CURRENT_MONTH, CURRENT_YEAR]);

  const minuteOption = useMemo(() => {
    const minitues = Array.from({ length: 60 }, (_, i) =>
      String(i).padStart(2, "0")
    );
    const currentHour = moment().hour();
    const monthNumber = moment().month(month).format("M");
    const isCurrentDate =
      Number(hour) === currentHour + 1 &&
      Number(day) === CURRENT_DAY &&
      Number(monthNumber) === CURRENT_MONTH &&
      Number(year) === CURRENT_YEAR;

    if (isCurrentDate)
      return minitues.filter(
        (currentMinute) => Number(currentMinute) >= Number(initialValues.minute)
      );

    return minitues;
  }, [
    year,
    month,
    day,
    hour,
    CURRENT_DAY,
    CURRENT_MONTH,
    CURRENT_YEAR,
    initialValues.minute,
  ]);

  useEffect(() => {
    if (!dayOption.includes(day)) {
      setFieldValue("day", dayOption[0]);
    }
  }, [month, day, dayOption, setFieldValue]);

  return (
    <SchedulePostContentContainer $gap={Number(spacing["spacing-6"].value)}>
      <PostDateInfo>
        <PostScheduledFor>
          <Components.Icon
            size="16px"
            type="Check"
            color={Color.icon["icon-brand"].value}
          />
          <Components.Text
            style={{ tokenStyle: typography.Heading.XS }}
            color={Color.text["text-emphasize-brand-bolder"].value}
            text="Post scheduled for:"
            lineHeight="line-height-75"
            fontWeight="font-weight-semibold"
          />
        </PostScheduledFor>

        <Components.Text
          style={{ tokenStyle: typography.Heading.XXS }}
          color={Color.text["text-emphasize-brand"].value}
          text={dateDescription(createDate(values))}
          lineHeight="line-height-100"
        />
      </PostDateInfo>
      <Container $gap={Number(spacing["spacing-2"].value)}>
        <Components.Text
          style={{ tokenStyle: typography.Heading.S }}
          color={Color.text["text-secondary"].value}
          lineHeight="line-height-75"
          text="Date"
          fontWeight="font-weight-semibold"
        />
        <InputContainer $gap={Number(spacing["spacing-4"].value)}>
          <Select
            {...CommonFieldProps}
            fieldName="month"
            label="Month"
            options={monthOption.map((month) => ({
              label: month,
              value: month,
            }))}
          />
          <Select
            {...CommonFieldProps}
            fieldName="day"
            label="Day"
            options={dayOption.map((day) => ({
              label: day,
              value: day,
            }))}
          />
          <Select
            {...CommonFieldProps}
            fieldName="year"
            label="Year"
            options={Array.from({ length: 10 }, (_, i) =>
              String(i + CURRENT_YEAR)
            ).map((year) => ({
              label: year,
              value: year,
            }))}
          />
        </InputContainer>
      </Container>
      <Container $gap={Number(spacing["spacing-2"].value)}>
        <Components.Text
          style={{ tokenStyle: typography.Heading.S }}
          color={Color.text["text-secondary"].value}
          lineHeight="line-height-75"
          text="Time"
          fontWeight="font-weight-semibold"
        />
        <InputContainer $gap={Number(spacing["spacing-4"].value)}>
          <Select
            {...CommonFieldProps}
            fieldName="hour"
            label="Hour"
            options={hourOption.map((hour) => ({
              label: hour,
              value: hour,
            }))}
          />
          <Select
            {...CommonFieldProps}
            fieldName="minute"
            label="Minute"
            options={minuteOption.map((minute) => ({
              label: minute,
              value: minute,
            }))}
          />
        </InputContainer>
      </Container>
      <Components.Button
        color="Primary"
        kind="Tertiary"
        disabled={true}
        label="View all scheduled posts"
        onClick={() => {}}
        size="Medium"
        iconPosition="right"
        iconType="Calendar"
      />
    </SchedulePostContentContainer>
  );
};

export default SchedulePostContent;

const SchedulePostContentContainer = styled(FlexColumn)`
  padding: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;
const Container = styled(FlexColumn)``;
const InputContainer = styled(FlexRow)``;

const PostScheduledFor = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-1"].value}px;
`;

const PostDateInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-1"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-1"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  background: ${({ theme }) =>
    theme.Color.background.brand.subtlest["default-hover"].value};

  & > label {
    display: flex;
  }
`;
