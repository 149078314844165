import { useContext, useRef } from "react";
import styled from "styled-components";

import { CreatePostModalContext } from "../..";
import { useAppSelector } from "../../../../../../../../redux";
import { validFileTypes } from "../../../../../../../../utils";
import MediaUpload from "./MediaUpload";
import MultiImagePreview from "./MultiImagePreview";
import SingleImagePreview from "./SingleImagePreview";
import { uploadFile } from "./utils";

const ImageUpload = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    imageUploadOpen,
    setImageUploadOpen,
    setIsMoreImgUploadButtonDisable,
    isMoreImgUploadButtonDisable,
  } = useContext(CreatePostModalContext);
  const { uploadedFiles, post_id } = useAppSelector((state) => state.posts);
  const isMulti = uploadedFiles.length > 1;

  return (
    <>
      {uploadedFiles.length > 0 &&
        (isMulti ? (
          <MultiImagePreview fileInputRef={fileInputRef} />
        ) : (
          <SingleImagePreview fileInputRef={fileInputRef} />
        ))}
      {imageUploadOpen && <MediaUpload fileInputRef={fileInputRef} />}

      {!isMoreImgUploadButtonDisable && (
        <HiddenInput
          type="file"
          multiple
          disabled={isMoreImgUploadButtonDisable}
          onChange={(e) => {
            const files = e.target.files;
            if (files?.length) {
              uploadFile({
                files,
                uploadedImages: uploadedFiles.length,
                setImageUploadOpen,
                setIsMoreImgUploadButtonDisable,
                post_id,
              });
            }
          }}
          ref={fileInputRef}
          id="image-upload"
          accept={validFileTypes.join(",")}
        />
      )}
    </>
  );
};

export default ImageUpload;

const HiddenInput = styled.input`
  display: none;
`;
