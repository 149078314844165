import { authActions, authReducer } from "./auth";
import { commentsActions, commentsReducer } from "./comments";
import { connectionsActions, connectionsReducer } from "./connections";
import { infoActions, infoReducer } from "./info";
import { meActions, meReducer } from "./me";
import { newsfeedActions, newsfeedReducer } from "./newsfeed";
import { nlpActions, nlpReducer } from "./nlp";
import { onboardingActions, onboardingReducer } from "./onboarding";
import { pollsActions, pollsReducer } from "./polls";
import { postsActions, postsReducer } from "./posts";
import { reportPostActions, reportPostReducer } from "./reportPost";
import { savedItemsActions, savedItemsReducer } from "./savedItems";

export type ReduxKeys = keyof typeof actions;

export const actions = {
  auth: authActions,
  connections: connectionsActions,
  info: infoActions,
  me: meActions,
  newsfeed: newsfeedActions,
  nlp: nlpActions,
  onboarding: onboardingActions,
  polls: pollsActions,
  posts: postsActions,
  reportPost: reportPostActions,
  savedItems: savedItemsActions,
  comments: commentsActions,
};

export const reducer = {
  auth: authReducer,
  connections: connectionsReducer,
  info: infoReducer,
  me: meReducer,
  newsfeed: newsfeedReducer,
  nlp: nlpReducer,
  onboarding: onboardingReducer,
  polls: pollsReducer,
  posts: postsReducer,
  reportPost: reportPostReducer,
  savedItems: savedItemsReducer,
  comments: commentsReducer,
};
