import { useEffect, useState } from "react";

export const useMountTransition = (
  isMounted: boolean,
  unmountDelay: number
) => {
  const [transitionStart, setTransitionStart] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isMounted && !transitionStart) {
      setTransitionStart(true);
    } else if (!isMounted && transitionStart) {
      timeoutId = setTimeout(() => setTransitionStart(false), unmountDelay);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [unmountDelay, isMounted, transitionStart]);

  return transitionStart;
};

export default useMountTransition;
