import { EmojiProps } from "./Emoji";
import { useEmojiDefaultValues } from "./useEmojiDefaultValues";

export const Sad = (props: EmojiProps.Emoji) => {
  const { size } = useEmojiDefaultValues(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M11.5 22.5C17.5751 22.5 22.5 17.5751 22.5 11.5C22.5 5.42487 17.5751 0.5 11.5 0.5C5.42487 0.5 0.5 5.42487 0.5 11.5C0.5 17.5751 5.42487 22.5 11.5 22.5Z"
        fill="#F0C419"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7412 9.60333C10.7412 11.9095 9.21255 13.7757 7.32738 13.7757C5.4422 13.7757 3.91358 11.9095 3.91358 9.60333C3.91307 9.32501 3.93591 9.04714 3.98186 8.77264L5.58255 8.52229L9.58427 7.88884L10.3884 7.76367C10.6245 8.34811 10.7444 8.973 10.7412 9.60333Z"
        fill="#ECF0F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9829 9.60364C9.9829 11.2802 8.96255 12.6381 7.70704 12.6381C6.45152 12.6381 5.43117 11.2802 5.43117 9.60364C5.42997 9.23791 5.48107 8.87388 5.5829 8.52261L9.58462 7.88916C9.85077 8.42123 9.98725 9.00873 9.9829 9.60364Z"
        fill="#2FA8CC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0864 9.60333C19.0864 11.9095 17.5578 13.7757 15.6726 13.7757C13.7875 13.7757 12.2589 11.9095 12.2589 9.60333C12.2557 8.973 12.3755 8.34811 12.6116 7.76367L13.4157 7.88884L17.4175 8.52229L19.0182 8.77264C19.0641 9.04714 19.0869 9.32501 19.0864 9.60333Z"
        fill="#ECF0F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57538 8.04834C9.00147 8.4519 9.23707 9.01679 9.224 9.60351C9.224 10.6504 8.54504 11.5001 7.70676 11.5001C6.86849 11.5001 6.18952 10.6504 6.18952 9.60351C6.18605 9.16385 6.31418 8.7332 6.55745 8.36696L8.57538 8.04834Z"
        fill="#35495E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91384 6.94851C3.70436 6.94925 3.53394 6.78002 3.53321 6.57053C3.53247 6.36104 3.7017 6.19062 3.91119 6.18989C3.96922 6.18989 5.1925 6.14437 5.83733 4.53155C5.91526 4.33693 6.1362 4.24234 6.33081 4.32027C6.52542 4.3982 6.62001 4.61914 6.54208 4.81375C5.6966 6.92424 3.98629 6.94851 3.91384 6.94851Z"
        fill="#E57E25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0861 6.94842C19.0137 6.94842 17.3034 6.92414 16.4579 4.81366C16.4075 4.68777 16.428 4.54454 16.5119 4.43794C16.5957 4.33133 16.73 4.27755 16.8642 4.29684C16.9985 4.31612 17.1122 4.40556 17.1626 4.53145C17.8075 6.14883 19.0376 6.18904 19.0895 6.1898C19.299 6.19074 19.4681 6.36133 19.4671 6.57081C19.4662 6.7803 19.2956 6.94936 19.0861 6.94842Z"
        fill="#E57E25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2585 17.6258C12.2384 17.6258 12.2184 17.6242 12.1985 17.6212C11.7351 17.5518 11.2639 17.5518 10.8004 17.6212C10.5934 17.6541 10.399 17.513 10.3661 17.306C10.3332 17.099 10.4743 16.9046 10.6813 16.8717C11.2237 16.7902 11.7752 16.7902 12.3176 16.8717C12.5135 16.9023 12.6527 17.0785 12.6372 17.2761C12.6217 17.4737 12.4567 17.6261 12.2585 17.6258Z"
        fill="#F29C1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2932 17.1895C15.211 17.1899 15.1311 17.1632 15.0656 17.1137C12.8994 15.6977 10.1007 15.6977 7.93454 17.1137C7.82613 17.195 7.68257 17.2123 7.55795 17.159C7.43333 17.1058 7.34658 16.9901 7.33037 16.8556C7.31416 16.7211 7.37096 16.5881 7.47937 16.5068C9.91553 14.8884 13.0846 14.8884 15.5207 16.5068C15.6514 16.6047 15.7046 16.7753 15.653 16.9302C15.6014 17.0851 15.4564 17.1895 15.2932 17.1895Z"
        fill="#E57E25"
      />
      <path
        d="M7.32745 18.7068C6.9215 18.7188 6.52858 18.5628 6.24141 18.2756C5.95423 17.9884 5.79823 17.5955 5.81021 17.1895C5.80958 16.98 5.9789 16.8097 6.18839 16.8091C6.39787 16.8085 6.56821 16.9778 6.56883 17.1873C6.55522 17.3925 6.63068 17.5937 6.77593 17.7394C6.92118 17.8851 7.12214 17.9612 7.32745 17.9482C7.53694 17.9482 7.70676 18.118 7.70676 18.3275C7.70676 18.5369 7.53694 18.7068 7.32745 18.7068Z"
        fill="#F29C1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6723 18.707C15.4628 18.707 15.293 18.5371 15.293 18.3277C15.293 18.1182 15.4628 17.9483 15.6723 17.9483C15.8777 17.9615 16.0788 17.8854 16.2241 17.7396C16.3694 17.5938 16.4448 17.3924 16.4309 17.1871C16.4316 16.9776 16.6021 16.8084 16.8115 16.8091C17.021 16.8098 17.1903 16.9802 17.1895 17.1897C17.2015 17.5957 17.0455 17.9886 16.7583 18.2758C16.4711 18.5629 16.0782 18.7189 15.6723 18.707Z"
        fill="#F29C1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5689 9.60364C17.5689 11.2802 16.5486 12.6381 15.2931 12.6381C14.0375 12.6381 13.0172 11.2802 13.0172 9.60364C13.0128 9.00873 13.1493 8.42123 13.4155 7.88916L17.4172 8.52261C17.519 8.87388 17.5701 9.23791 17.5689 9.60364Z"
        fill="#2FA8CC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4425 8.36696C16.6857 8.7332 16.8139 9.16385 16.8104 9.60351C16.8104 10.6504 16.1314 11.5001 15.2931 11.5001C14.4549 11.5001 13.7759 10.6504 13.7759 9.60351C13.7628 9.01679 13.9984 8.4519 14.4245 8.04834L16.4425 8.36696Z"
        fill="#35495E"
      />
      <path
        d="M16.431 9.60367C16.85 9.60367 17.1896 9.26402 17.1896 8.84505C17.1896 8.42607 16.85 8.08643 16.431 8.08643C16.012 8.08643 15.6724 8.42607 15.6724 8.84505C15.6724 9.26402 16.012 9.60367 16.431 9.60367Z"
        fill="#ECF0F1"
      />
      <path
        d="M8.84505 9.60367C9.26402 9.60367 9.60367 9.26402 9.60367 8.84505C9.60367 8.42607 9.26402 8.08643 8.84505 8.08643C8.42607 8.08643 8.08643 8.42607 8.08643 8.84505C8.08643 9.26402 8.42607 9.60367 8.84505 9.60367Z"
        fill="#ECF0F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53432 9.22433C3.33619 9.22444 3.17135 9.07204 3.15595 8.87451C3.14055 8.67698 3.27977 8.50087 3.47553 8.47027L10.6824 7.33233C10.8894 7.29965 11.0837 7.44095 11.1164 7.64792C11.149 7.85489 11.0077 8.04917 10.8008 8.08185L3.59387 9.21978C3.57416 9.22279 3.55425 9.22431 3.53432 9.22433Z"
        fill="#F29C1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4656 9.22429C19.4457 9.22428 19.4257 9.22276 19.406 9.21974L12.1991 8.0818C12.0653 8.06066 11.9528 7.9697 11.9042 7.84318C11.8555 7.71666 11.8781 7.5738 11.9633 7.46842C12.0486 7.36304 12.1836 7.31115 12.3175 7.33229L19.5244 8.47022C19.7201 8.50082 19.8594 8.67693 19.844 8.87446C19.8286 9.07199 19.6637 9.2244 19.4656 9.22429Z"
        fill="#F29C1F"
      />
    </svg>
  );
};
