import { Dispatch, SetStateAction } from "react";
import { v4 } from "uuid";

import { API_CALLS } from "../../../../../../../../API/api_calls";
import { validFile } from "../../../../../../../../utils";

export const MAX_IMAGE_UPLOAD_COUNT = 10;

export const uploadFile = ({
  files,
  post_id,
  uploadedImages,
  setImageUploadOpen,
  setIsMoreImgUploadButtonDisable,
}: {
  files: FileList;
  uploadedImages: number;
  setImageUploadOpen: Dispatch<SetStateAction<boolean>>;
  setIsMoreImgUploadButtonDisable: Dispatch<SetStateAction<boolean>>;
  post_id: string;
}) => {
  const remainingImages = MAX_IMAGE_UPLOAD_COUNT - uploadedImages;
  const validFileList = validFile(files).slice(0, remainingImages);

  if (!validFileList.length) return;

  API_CALLS.POSTS.uploadsImageForAPost(
    {
      post_id,
      images: validFileList.map((file) => ({ file, id: v4() })),
    },
    setImageUploadOpen,
    setIsMoreImgUploadButtonDisable
  );
};
