import styled from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import { DefaultPostProps } from "./DefaultPost";

export const Attachments = ({
  openCreatePostModal,
}: DefaultPostProps.OpenPostModal) => {
  return (
    <Container className="default-post-attachments">
      <Components.Tooltip  type="label" label="Add media">
        <Components.IconButton
          iconType={"Image"}
          kind="Secondary"
          size="Medium"
          onClick={() => openCreatePostModal("Image")}
          disabled={false}
        />
      </Components.Tooltip>
      <Components.Tooltip  type="label" label="Add videos">
        <Components.IconButton
          iconType={"Video"}
          kind="Secondary"
          size="Medium"
          onClick={() => {}}
          disabled={false}
        />
      </Components.Tooltip>

      <Components.Tooltip  type="label" label="Add a GIF">
        <Components.IconButton
          iconType={"Gif"}
          kind="Secondary"
          size="Medium"
          onClick={() => {}}
          disabled={false}
        />
      </Components.Tooltip>

      <Components.Tooltip  type="label" label="Create a poll">
        <Components.IconButton
          iconType={"Poll"}
          kind="Secondary"
          size="Medium"
          onClick={() => openCreatePostModal("Poll")}
          disabled={false}
        />
      </Components.Tooltip>

      <Components.Tooltip  type="label" label="Create a rating">
        <Components.IconButton
          iconType={"Slider"}
          kind="Secondary"
          size="Medium"
          onClick={() => openCreatePostModal("Slider")}
          disabled={false}
        />
      </Components.Tooltip>
      <Components.Tooltip  type="label" label="Write an article">
        <Components.IconButton
          iconType={"FileText"}
          kind="Secondary"
          size="Medium"
          onClick={() => {}}
          disabled={false}
        />
      </Components.Tooltip>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
