import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import PostCommentField from "../../PostCommentField";
import { FlexColumn } from "../../Styles";
import { CommentProps } from "./Comment";
import CommentFooter from "./CommentFooter";
import { CommentHeader } from "./CommentHeader";
import { TextContent } from "./TextContent";

const CommentCard = ({
  showReplyButton,
  comment,
  observeCount,
}: CommentProps.Comment) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [isEditComment, setIsEditComment] = useState(false);

  useEffect(() => {
    if (contentRef?.current) {
      const currentComment = contentRef.current as Element;
      const parentComment =
        currentComment.parentElement?.parentElement?.parentElement;

      if (parentComment?.classList.contains("comment")) {
        const height = Number(
          (
            currentComment?.getBoundingClientRect().top -
            parentComment?.getBoundingClientRect().top -
            16
          ).toFixed(0)
        );
        setHeight(height);
      }
    }
  }, [contentRef, observeCount]);

  return (
    <>
      <FlexColumn
        ref={contentRef}
        className="comment"
        style={{ position: "relative" }}
        $gap={0}
      >
        <CommentHeader {...comment} setIsEditComment={setIsEditComment} />
        {isEditComment ? (
          <PostCommentField
            parent_comment_id={comment.parent_comment_id}
            value={comment.content}
            isEditMode
            setIsEditComment={setIsEditComment}
            comment_id={comment.id}
            urls={comment.meta.comment_urls}
            image_id={comment.images?.[0]?.id}
            post_id={comment.post_id}
          />
        ) : (
          <Container $gap={10}>
            <TextContent
              blueprint={comment.content}
              commentMeta={comment.meta}
            />
            {comment.images && (
              <img
                src={process.env.REACT_APP_GORRILA_API + comment.images[0].url}
                alt="post-comment-img"
              />
            )}
          </Container>
        )}
        <CommentFooter
          {...comment}
          showReplyButton={showReplyButton}
          observeCount={observeCount}
        />
      </FlexColumn>
      {height > 0 && <Line $height={height} />}
    </>
  );
};

export default CommentCard;

const Container = styled(FlexColumn)`
  padding: ${({ theme }) => theme.spacing["spacing-none"].value}px
    ${({ theme }) => theme.spacing["spacing-none"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-9"].value}px;

  img {
    width: 100%;
    border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  }
`;

const Line = styled.div<{ $height: number }>`
  position: absolute;
  top: 44px;
  left: 15px;
  height: ${({ $height }) => $height}px;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-bottom-left-radius: ${({ theme }) => theme.radius["radius-s"].value}px;
  border-color: ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  width: 19px;
`;
