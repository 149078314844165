import { WhyReporting } from "./Contents/1_why_reporting";
import { BestProblemDescription } from "./Contents/2_best_problem_description";
import { WhatIsHappening } from "./Contents/3_what_is_happening";
import { SubmitConfirmation } from "./Contents/4_submit_confirmation";
import { SubmitSuccess } from "./Contents/5_submit_success";

export const REPORT_COMMENT_STEPS = [
  "why_reporting",
  "best_problem_description",
  "what_is_happening",
  "submit_confirmation",
  "submit_success",
] as const;

const ReportCommentStepNumber: Record<
  (typeof REPORT_COMMENT_STEPS)[number],
  number
> = {
  why_reporting: 1,
  best_problem_description: 2,
  what_is_happening: 3,
  submit_confirmation: 4,
  submit_success: 5,
};

const Component: Record<(typeof REPORT_COMMENT_STEPS)[number], JSX.Element> = {
  why_reporting: <WhyReporting />,
  best_problem_description: <BestProblemDescription />,
  what_is_happening: <WhatIsHappening />,
  submit_confirmation: <SubmitConfirmation />,
  submit_success: <SubmitSuccess />,
};
const ReportCommentDescription: Record<
  (typeof REPORT_COMMENT_STEPS)[number],
  string
> = {
  why_reporting: "Why are you reporting this post?",
  best_problem_description: "Which option best describes the problem?",
  what_is_happening: "What’s happening in the post?",
  submit_confirmation: "",
  submit_success: "",
};
const whyReportingListItems = [
  "Nudity or sexual activity",
  "Bullying or harassment",
  "Suicide, self-injury or eating disorders",
  "Violence, hate or exploitation",
  "Selling or promoting restricted items",
  "Scam, fraud or impersonation",
];
const bestProblemDescriptionListItems = [
  "Sharing private or sexual images",
  "Sexual exploitation",
  "Adult nudity or sexual activity",
  "Child nudity",
];
const whatIsHappeningListItems = ["Sexual exploitation", "Nudity"];

export const reportCommentUtils = {
  Component,
  ReportCommentDescription,
  whyReportingListItems,
  bestProblemDescriptionListItems,
  whatIsHappeningListItems,
  REPORT_COMMENT_STEPS,
  ReportCommentStepNumber,
};
