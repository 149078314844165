import { CustomHOCs } from "@thisgorilla/primate-ds";
import styled from "styled-components";
import { useMouseOver } from "../../../hooks/useMouseOver";
import {
  MOUSE_ENTER_UPDATION_DELAY,
  MOUSE_LEAVE_UPDATION_DELAY,
} from "../../../utils";
import { UserInfoPopup } from "./UserInfo";

type PopupProps = {
  parentRef: React.RefObject<HTMLDivElement>;
  type: "user";
  id: string;
};
export const Popup = (props: PopupProps) => {
  const { parentRef, type, id } = props;
  const { isHovered } = useMouseOver({
    ref: parentRef,
    mouseLeaveDelay: MOUSE_LEAVE_UPDATION_DELAY,
    mouseEnterDelay: MOUSE_ENTER_UPDATION_DELAY,
  });

  return (
    <CustomHOCs.WithPopupPosition
      parentRef={parentRef}
      includeContentHeight={false}
      additionalOffset={20}
    >
      <Container $showPopup={isHovered} className={`${type}-popup`}>
        {type === "user" && isHovered && <UserInfoPopup id={id} />}
      </Container>
    </CustomHOCs.WithPopupPosition>
  );
};

type ContainerProps = { $showPopup: boolean };

const Container = styled.div<ContainerProps>(({ $showPopup }) => {
  return `
    height: ${$showPopup ? "fit-content" : 0};
    width: ${$showPopup ? "fit-content" : 0};
    opacity: ${$showPopup ? 1 : 0};
    visibility: ${$showPopup ? "visible" : "hidden"};
    z-index: ${$showPopup ? 1 : -1};
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    pointer-events: ${$showPopup ? "auto" : "none"};
`;
});
