import { ReportPostProps } from "./reportPost";

export const REPORT_POST_STEPS = [
  "why_reporting",
  "best_problem_description",
  "what_is_happening",
  "submit_confirmation",
  "submit_success",
] as const;
const ReportPostStepNumber: Record<ReportPostProps.ReportPostStep, number> = {
  why_reporting: 1,
  best_problem_description: 2,
  what_is_happening: 3,
  submit_confirmation: 4,
  submit_success: 5,
};

export const reportPostUtils = {
  REPORT_POST_STEPS,
  ReportPostStepNumber,
};
