import { Inputs } from "@thisgorilla/primate-ds";
import { Field, FieldProps } from "formik";
import { useState } from "react";

import { SelectProps } from "./Select";

const Select = (props: SelectProps.IProps) => {
  const { fieldName, helpText, helpTextKind } = props;
  const [isFocused, setFocused] = useState(false);
  return (
    <Field name={fieldName} key={fieldName}>
      {({ field, meta, form }: FieldProps) => {
        const { value } = field;
        const { error } = meta;
        const { setFieldValue, validateField } = form;
        return (
          <Inputs.Search
            {...{
              ...props,
              value,
              handleSelect: (option) => {
                setFieldValue(fieldName, option.value, false).then(() => {
                  validateField(fieldName);
                });
              },
              helpText: !isFocused && error ? error : helpText,
              helpTextKind: !isFocused && error ? "Error" : helpTextKind,
            }}
            onChangeHandler={() => {}}
            onFocus={() => setFocused(true)}
            onBlur={() => {
              setFocused(false);
              validateField(fieldName);
            }}
          />
        );
      }}
    </Field>
  );
};

export default Select;
