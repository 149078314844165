import { Components, Inputs } from "@thisgorilla/primate-ds";
import { Field, FieldProps, Formik } from "formik";
import { useState } from "react";
import styled from "styled-components";

import instance from "../../../axios/axios-user";
import { useAppSelector } from "../../../redux";
import {
  addProxyUrlToProfileImage,
  RICHTEXT_INITIAL_VALUE,
} from "../../../utils";
import { FlexRow } from "../Styles";
import Footer from "./Footer";
import { PostCommentFieldProps } from "./PostCommentField";
import { findCustomURL } from "./utils";

const PostCommentField = ({
  parent_comment_id,
  value = "",
  isEditMode = false,
  setIsEditComment,
  comment_id,
  urls,
  image_id = null,
  post_id,
  onClose = () => {},
}: PostCommentFieldProps.IProps) => {
  const { profile_picture, suggestedUsers } = useAppSelector(
    (state) => state.me
  );
  const [isFocused, setIsFocused] = useState(false);
  const [isFooterShow, setIsFooterShow] = useState(isEditMode ? true : false);
  const [temp_id, setTemp_id] = useState<string | null>(image_id);
  const [urlData, setUrlData] = useState<
    PostCommentFieldProps.Footer["urlData"]
  >(
    urls?.length
      ? {
          id: urls[0].id,
          description: urls[0].description,
          imageUrl: urls[0].image,
          title: urls[0].title,
          url: urls[0].url,
          type: "",
          version: 0,
        }
      : null
  );
  const fieldNameContent = "content";

  return (
    <Formik initialValues={{ content: value }} onSubmit={() => {}}>
      {(formik) => {
        return (
          <FlexRow $gap={10}>
            <Components.Avatar
              size="Small-32"
              src={profile_picture}
              type="Circle"
            />
            <InputContainer
              $isFocused={isFocused}
              $isFooterShow={isFooterShow}
              onClick={() => {
                setIsFooterShow(true);
              }}
            >
              <Field key={fieldNameContent} name={fieldNameContent}>
                {({ field, form }: FieldProps) => {
                  const { value } = field;
                  const { setFieldValue, validateField } = form;

                  return (
                    <Inputs.RichTextarea
                      fieldName={fieldNameContent}
                      axiosInstance={instance}
                      idPrefix="post"
                      isRequired={false}
                      mentionList={suggestedUsers[0].followers.map((user) => ({
                        ...user,
                        profile_picture: addProxyUrlToProfileImage(
                          user.profile_picture
                        ),
                      }))}
                      onChangeHandler={(state, html) => {
                        setUrlData(findCustomURL(state.toJSON().root));
                        if (!html || html === RICHTEXT_INITIAL_VALUE) {
                          setFieldValue(fieldNameContent, "");
                        } else {
                          setFieldValue(fieldNameContent, html);
                          setTimeout(() => {
                            validateField(fieldNameContent);
                          }, 0);
                        }
                      }}
                      value={value}
                      placeholder="Add a comment..."
                      variant="Post"
                      showToolbar={false}
                      maxHeight={150}
                      minHeight={24}
                      onFocus={() => {
                        setIsFocused(true);
                      }}
                      onBlur={() => {
                        setIsFocused(false);
                        validateField(fieldNameContent);
                      }}
                      urls={urls}
                      footerComponent={
                        isFooterShow ? (
                          <Footer
                            formik={formik}
                            urlData={urlData}
                            setTemp_id={setTemp_id}
                            temp_id={temp_id}
                            post_id={post_id}
                            parent_comment_id={parent_comment_id}
                            setIsFooterShow={
                              isEditMode && setIsEditComment
                                ? setIsEditComment
                                : setIsFooterShow
                            }
                            isEditMode={isEditMode}
                            comment_id={comment_id}
                            onClose={onClose}
                          />
                        ) : (
                          <></>
                        )
                      }
                    />
                  );
                }}
              </Field>
            </InputContainer>
          </FlexRow>
        );
      }}
    </Formik>
  );
};

export default PostCommentField;

const InputContainer = styled.div<{
  $isFocused: boolean;
  $isFooterShow: boolean;
}>`
  height: fit-content;
  width: 100%;
  padding: ${({ theme, $isFooterShow }) =>
    $isFooterShow
      ? `${theme.spacing["spacing-2"].value}px ${theme.spacing["spacing-3"].value}px`
      : `${theme.spacing["spacing-1"].value}px ${theme.spacing["spacing-3"].value}px`};

  border: 1px solid;
  border-radius: 3px;

  ${({ $isFocused }) =>
    $isFocused
      ? Inputs.CommonField.CommonFieldStyle.inputActivecss
      : Inputs.CommonField.getInputCss("Normal")}
`;
