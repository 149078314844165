import instance from "../../axios/axios-user";
import { PollsProps } from "../../redux/slices/polls/Polls";
import { API_PATHS } from "../api_paths";

const createANewPoll = (data: PollsProps.CreateANewPollPayload) =>
  instance(API_PATHS.polls.CreateANewPoll, { method: "POST", data: data });

const showAPoll = (poll_id: string) =>
  instance(API_PATHS.polls.CreateANewPoll + `/${poll_id}`);

const submitAnswerForAPoll = (data: PollsProps.SubmitAnswerForAPollPayload) =>
  instance.post(`${API_PATHS.polls.poll}/${data.poll_id}/answer`, {
    choice: data.choice,
  });

export const POLLS = { createANewPoll, showAPoll, submitAnswerForAPoll };
