import styled, { css } from "styled-components";
import { FadeTransitionType } from "../../redux/common";
import { FADE_ANIMATION_DURATION } from "../../utils";

//#region Effects
type TransitionProps = { $height: number; $transitionDuration?: number };
const transition = css<TransitionProps>`
  height: ${({ $height }) => $height}px;
  transition: height
    ${({ $transitionDuration }) => $transitionDuration || 300}ms ease-in;
  overflow: hidden;
`;
const fadeAnimation = css<{ $fadeTransitionType: FadeTransitionType }>`
  animation: ${({ $fadeTransitionType }) =>
      $fadeTransitionType === "FadeIn" ? "fadeIn" : "fadeOut"}
    ${FADE_ANIMATION_DURATION}ms ease-in forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;
const hideScrollbar = css`
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const verticalScroll = css`
  overflow-y: scroll;
  ${hideScrollbar};
`;
//#endregion

//#region Flex
type FlexProperties = { $gap?: number };
const Flex = styled.div<FlexProperties>`
  display: flex;
  gap: ${(props) => props.$gap ?? 4}px;
`;
const FlexRow = styled(Flex)`
  flex-direction: row;
`;
const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

//#region Start
const start = css`
  align-items: flex-start;
`;
const FlexRowStart = styled(FlexRow)`
  ${start}
`;
const FlexColumnStart = styled(FlexColumn)`
  ${start}
`;
//#endregion

//#region Centered
const centered = css`
  align-items: center;
  justify-content: center;
`;
const FlexRowCenter = styled(FlexRow)`
  ${centered}
`;
const FlexColumnCenter = styled(FlexColumn)`
  ${centered}
`;
//#endregion

//#region End
const FlexRowEnd = styled(FlexRow)`
  justify-content: flex-end;
`;
const FlexColumnEnd = styled(FlexColumn)`
  align-items: flex-end;
`;
//#endregion

//#region Spaced
const spaced = css`
  justify-content: space-between;
`;
const FlexRowSpaced = styled(FlexRow)`
  ${spaced}
`;
const FlexColumnSpaced = styled(FlexColumn)`
  ${spaced}
`;
//#endregion

//#region Flow
const FlexRowWithFlow = styled(FlexRow)`
  flex-wrap: wrap;
  flex-flow: row;
`;
const FlexColumnWithFlow = styled(FlexColumn)`
  flex-wrap: wrap;
`;
//#endregion
const FlexWithTransition = styled(Flex)<TransitionProps>`
  ${transition}
`;
const FlexColumnWithTransition = styled(FlexColumn)<TransitionProps>`
  ${transition}
`;
//#endregion

//#region Inputs
const InputContainer = styled(FlexColumn)`
  gap: 4px;
`;
//#endregion

export {
  fadeAnimation,
  FlexColumn,
  FlexColumnCenter,
  FlexColumnEnd,
  FlexColumnSpaced,
  FlexColumnStart,
  FlexColumnWithFlow,
  FlexColumnWithTransition,
  FlexRow,
  FlexRowCenter,
  FlexRowEnd,
  FlexRowSpaced,
  FlexRowStart,
  FlexRowWithFlow,
  FlexWithTransition,
  hideScrollbar,
  InputContainer,
  transition,
  verticalScroll,
};
export type { TransitionProps };
