import {
  FlexColumn
} from "../../../../../Common/Styles";
import { ImageContent } from "./ImageContent";
import { PollContent } from "./PollContent";
import { RateContent } from "./RateContent";
import { TextContent } from "./TextContent";

export const Content = () => {

  return (
    <FlexColumn $gap={0}>
      <TextContent />
      <ImageContent />
      <PollContent />
      <RateContent />
    </FlexColumn>
  );
};
