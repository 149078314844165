import * as Yup from "yup";

export const createPostValidation = ({
  choiceCount = [],
  pollOpen,
  sliderOpen,
}: {
  choiceCount: number[];
  pollOpen: boolean;
  sliderOpen: boolean;
}) => {
  let schemaShape: {
    [key: string]: Yup.StringSchema | Yup.NumberSchema;
  } = {};

  if (pollOpen) {
    for (let i = 1; i <= choiceCount.length; i++) {
      schemaShape[`choice_${choiceCount[i - 1]}`] =
        Yup.string().required(`Please add an option`);
    }
    schemaShape.duration = Yup.number().required("Please select Duration");
    schemaShape.question = Yup.string().required("Please add your question");
  }
  if (sliderOpen) {
    schemaShape["slider_question"] = Yup.string().required(
      "Please Enter Slider question"
    );
  }

  schemaShape["left_emoji_id"] = Yup.string();
  schemaShape["right_emoji_id"] = Yup.string();
  schemaShape.content = Yup.string();
  schemaShape.delivery_date = Yup.string();

  return Yup.object(schemaShape);
};

export const durationOption = [
  { label: "1 Day", value: 1 },
  { label: "1 Week", value: 7 },
  { label: "2 Weeks", value: 14 },
];

export const CHOICE_COUNT_INITIAL_VALUE = [1, 2, 3];

export const initialValues = {
  left_emoji_id: "",
  right_emoji_id: "",
  content: "",
  delivery_date: "",
  question: "",
  slider_question: "",
  duration: "",
};
