import { Components } from "@thisgorilla/primate-ds";
import { createContext, useEffect, useRef } from "react";
import styled, { css } from "styled-components";

import { disableScroll, enableScroll } from "../../../utils";
import { FlexColumn, FlexColumnCenter, FlexRowCenter } from "../Styles";
import { ModalProps } from "./Modal";
import { useModalContentHeight } from "./useModalContentHeight";

export const ModalContext = createContext({} as ModalProps.Context);
export const Modal = ({
  children,
  type,
  stretchToFullWidth = type === "Onboarding" ? false : true,
  maxWidth = type === "Onboarding" ? "unset" : 726,
  minWidth = type === "Onboarding" ? "unset" : 320,
  from,
  showCloseButton,
  setCloseButton,
  onDrop,
  zIndex = 2,
  modalOpen = true,
  withAnimation = false,
}: ModalProps.IProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const { maxContentHeight } = useModalContentHeight({ headerRef, footerRef });

  useEffect(() => {
    disableScroll();
    return () => {
      enableScroll();
    };
  }, []);
  useEffect(() => {
    const element = contentRef.current;
    if (!element) return;

    element.style.maxHeight = `${maxContentHeight}px`;
  }, [maxContentHeight]);

  return (
    <Overlay
      key={from}
      id={from}
      onDrop={onDrop && onDrop}
      onDragOver={onDrop && ((e) => e.preventDefault())}
      $zIndex={zIndex}
      $modalopen={modalOpen}
    >
      <Container $withAnimation={withAnimation} $modalopen={modalOpen}>
        <ModalContainer
          ref={modalRef}
          $type={type}
          $stretchToFullWidth={stretchToFullWidth}
          $maxWidth={maxWidth}
          $minWidth={minWidth}
          $modalopen={modalOpen}
          $withAnimation={withAnimation}
          $gap={0}
        >
          <ModalContext.Provider value={{ contentRef, headerRef, footerRef }}>
            {children}
          </ModalContext.Provider>
          {showCloseButton && (
            <CloseButtonContainer className="close-button-container">
              <Components.IconButton
                disabled={false}
                iconType="X"
                kind="Tertiary"
                onClick={() => {
                  setCloseButton(false);
                }}
                size="Medium"
              />
            </CloseButtonContainer>
          )}
        </ModalContainer>
      </Container>
    </Overlay>
  );
};

export default Modal;

const Overlay = styled(FlexColumnCenter)<{
  $modalopen: boolean;
  $zIndex: number;
}>`
  position: fixed;
  inset: 0;
  height: 100dvh;
  width: 100dvw;
  z-index: ${({ $zIndex }) => $zIndex};
  visibility: ${({ $modalopen }) => ($modalopen ? "visible" : "hidden")};
  transition: ${({ $modalopen }) =>
    $modalopen ? "visibility 0ms ease-out" : "visibility 0ms 300ms ease-out"};
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary-hover"].value};
  backdrop-filter: blur(4px);
`;
const ModalTransition = css<{ $modalopen: boolean }>`
  transform: translateY(${({ $modalopen }) => ($modalopen ? "0" : "100%")});
  transition: transform 300ms ease-out;
`;

const Container = styled(FlexRowCenter)<{
  $withAnimation: boolean;
  $modalopen: boolean;
}>`
  height: 100%;
  width: 100%;
  ${({ $withAnimation }) => $withAnimation && ModalTransition}
`;

const ModalContainer = styled(FlexColumn)<ModalProps.ModalContainer>`
  position: relative;
  max-width: ${({ $maxWidth }) =>
    $maxWidth === "unset" ? "unset" : `${$maxWidth}px`};
  min-width: ${({ $minWidth }) =>
    $minWidth === "unset" ? "unset" : `${$minWidth}px`};
  width: ${({ $stretchToFullWidth }) =>
    $stretchToFullWidth ? "90%" : "unset"};
  max-height: 95dvh;
  padding: 0px;
  border-radius: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-default"].value};
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-overlay"].value};
  transition: ${({ $withAnimation }) =>
    $withAnimation && "max-width 300ms ease-out"};
`;
const CloseButtonContainer = styled.span`
  width: fit-content;
  position: absolute;
  top: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  right: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;
