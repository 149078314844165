import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import styled from "styled-components";
import { useContainerSize } from "../hooks/useContainerSize";

type WithHeightTransitionProps = {
  children: React.ReactNode;
  setContainerHeight: Dispatch<SetStateAction<number>>;
  additionalHeight?: number; //usually used to include top and bottom padding of the parent
};

export const WithHeightTransition = ({
  children,
  setContainerHeight,
  additionalHeight = 0,
}: WithHeightTransitionProps) => {
  const ref = useRef(null);
  const { height } = useContainerSize(ref);

  useEffect(() => {
    setContainerHeight(height + additionalHeight);
  }, [height, additionalHeight, setContainerHeight]);

  return (
    <Container className="with-height-transition" ref={ref}>
      {children}
    </Container>
  );
};

const Container = styled.div``;
