import { Components } from "@thisgorilla/primate-ds";
import { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { ReportCommentContext } from ".";
import {
  FlexColumnStart,
  FlexRow,
} from "../../../../../../components/Common/Styles";
import { ReactComponent as AngryGorilla } from "../../../../../../svg/Angry-Gorilla.svg";
import { reportCommentUtils } from "./utils";

export const Header = () => {
  const { typography } = useTheme();
  const { reportComment } = useContext(ReportCommentContext);

  const description =
    reportCommentUtils.ReportCommentDescription[
      reportComment.reportCommentStep
    ];

  return (
    <Container className="report-post-header">
      <FlexRow $gap={8}>
        <AngryGorilla />
        <TitleAndDescription $gap={8}>
          <Components.Text
            style={{ tokenStyle: typography.Heading.L }}
            fontWeight="font-weight-semibold"
            text="Report"
          />
          {description && (
            <DescriptionContainer>
              <Components.Text
                style={{ tokenStyle: typography.Body.L }}
                fontWeight="font-weight-semibold"
                text={description}
              />
            </DescriptionContainer>
          )}
        </TitleAndDescription>
      </FlexRow>
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => `
  position: sticky;
  top: 0;
  padding: ${theme.spacing["spacing-4"].value}px;
  padding-top: ${theme.spacing["spacing-9"].value}px;
  padding-bottom: 0;
`
);

const DescriptionContainer = styled.div(
  ({ theme }) => `
  padding: ${theme.spacing["spacing-2"].value}px;
  border-radius: ${theme.radius["radius-xs"].value}px;
  background: ${theme.Color.elevation.surface["surface-secondary"].value}; 
`
);
const TitleAndDescription = styled(FlexColumnStart)`
  justify-content: center;
`;
