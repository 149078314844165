import { useContext } from "react";
import { ReportCommentContext } from ".";
import { reportCommentUtils } from "./utils";

export const useGoToNextStep = (answer = "") => {
  const { setDirection, reportComment, setReportComment } =
    useContext(ReportCommentContext);
  const { reportCommentStep } = reportComment;
  const currentStepNumber =
    reportCommentUtils.ReportCommentStepNumber[reportCommentStep];
  const nextStepNumber = currentStepNumber;
  const nextStep = reportCommentUtils.REPORT_COMMENT_STEPS[nextStepNumber];

  const goToNextStep = () => {
    setDirection("to-right");
    setReportComment((prev) => ({ ...prev, reportCommentStep: nextStep }));

    switch (reportCommentStep) {
      case "why_reporting":
        setReportComment((prev) => ({ ...prev, why_reporting_answer: answer }));
        break;
      case "best_problem_description":
        setReportComment((prev) => ({
          ...prev,
          best_problem_description_answer: answer,
        }));
        break;
      case "what_is_happening":
        setReportComment((prev) => ({
          ...prev,
          what_is_happening_answer: answer,
        }));
        break;
    }
  };

  return { goToNextStep };
};
