import styled from "styled-components";
import { FlexColumn } from "../Styles";

const Container = styled(FlexColumn)(
  ({ theme }) => `
  background: ${theme.Color.elevation.surface["surface-primary"].value};
  border: 1px solid ${theme.Color.border["ui-border-default"].value};
  box-shadow: ${theme.boxShadows["box-shadow-m"]};
  border-radius: 8px;
  padding: 16px 12px;
  max-width: 380px;
  min-width: 247px;
`
);
export const PopupStyles = { Container };
