import { useContext } from "react";
import { useAppSelector } from "../../../../../../redux";
import UserInfo from "../../../../../Common/UserInfo";
import { PostCardContext } from "../PostCardContent";

export const PostCardHeaderLeft = () => {
  const { postId } = useContext(PostCardContext);
  const { processed_posts } = useAppSelector((state) => state.newsfeed);
  const { name, profilePicture, activeDuration, headline, user_id } =
    processed_posts[postId].UserDetails;

  return (
    <UserInfo
      headline={headline}
      id={user_id}
      name={name}
      profile_picture={profilePicture.String}
      activeDuration={activeDuration}
      showPostedDate={true}
      avatarSize="Large-56"
    />
  );
};
