import { Components } from "@thisgorilla/primate-ds";
import { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { TextGroup } from "../../../../../../../commonStyles";
import { useAppSelector } from "../../../../../../../redux";
import { FlexRow } from "../../../../../../Common/Styles";
import { PostCardContext } from "../../PostCardContent";

export const VotedFeedBack = () => {
  const { typography, Color } = useTheme();
  const { postId } = useContext(PostCardContext);

  const { processed_posts } = useAppSelector((state) => state.newsfeed);
  const original_post = processed_posts[postId];
  const votedUsersLength =
    original_post?.interactions?.rating_interaction?.users_profile_pictures
      ?.length;

  return (
    <Container $gap={8}>
      <IconWrapper>
        <Components.Icon type="ThumbsUp" size="16px" />
      </IconWrapper>
      <TextGroup>
        <Components.Text
          text={votedUsersLength > 1 ? `You and ` : `You have `}
          style={{ tokenStyle: typography.Body.M }}
        />
        {votedUsersLength > 1 && (
          <Components.Text
            text={`${votedUsersLength - 1} `}
            style={{ tokenStyle: typography.Body.M }}
            color={Color.text["text-emphasize-brand"].value}
            fontWeight="font-weight-semibold"
          />
        )}
        {votedUsersLength > 1 && (
          <Components.Text
            text={`other${votedUsersLength > 2 ? "s" : ""} have `}
            style={{ tokenStyle: typography.Body.M }}
          />
        )}
        <Components.Text
          text={`voted! See full slider results below.`}
          style={{ tokenStyle: typography.Body.M }}
        />
      </TextGroup>
    </Container>
  );
};
const IconWrapper = styled.div`
  padding-top: 2px;
`;
const Container = styled(FlexRow)(
  ({ theme }) => `
  border-radius: ${theme.radius["radius-xs"].value}px;
  border: 1px solid ${theme.Color.border["ui-border-primary-subtle"].value};
  padding: ${theme.spacing["spacing-4"].value}px;
  background: ${theme.Color.background.neutral.subtle.default.value};
  `
);
