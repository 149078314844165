import { Formik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../redux";
import { onboardingActions } from "../../redux/slices/onboarding";
import { disableScroll, enableScroll } from "../../utils";
import { Modal } from "../Common";
import { OnboardingForm } from "./OnboardingForm";
import { useInitialValues } from "./useInitialValues";

export const Onboarding = () => {
  const dispatch = useAppDispatch();
  const { currentStep, first_name, last_name, getUserProfile_API_STATUS } =
    useAppSelector((state) => state.onboarding);

  //#region Effects
  useEffect(() => {
    disableScroll();
    return enableScroll;
  }, []);

  // Set current step to get_started if name is not available
  useEffect(() => {
    const name = `${first_name} ${last_name}`;
    if (!name.trim() && currentStep === "location")
      dispatch(onboardingActions.setCurrentStep("get_started"));
    else if (name.trim() && currentStep === "get_started")
      dispatch(onboardingActions.setCurrentStep("location"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserProfile_API_STATUS]);
  //#endregion

  return (
    <Modal type="Onboarding" from="Onboarding">
      <Formik
        initialValues={useInitialValues()}
        onSubmit={() => {}}
        enableReinitialize
        validationSchema={Yup.object({
          first_name: Yup.string().required("First name is required"),
          last_name: Yup.string().required("Last name is required"),
        })}
      >
        {() => <OnboardingForm />}
      </Formik>
    </Modal>
  );
};
