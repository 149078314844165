import { Components } from "@thisgorilla/primate-ds";
import { functionType } from "../redux/common";

export const getErrorMessage = (error: any) => {
  if (!error) return "Something went wrong";
  if (error?.response?.data?.error) return error.response.data.error;
  return error.message;
};

const getErrorMessageTitle = (functionName: string) => {
  const splittedWords = functionName
    .match(/[A-Z]?[a-z]+/g)
    ?.map((word) => word.toLowerCase());
  if (!splittedWords || splittedWords.length === 0) return "";

  const functionType: functionType = splittedWords[0] as functionType;
  if (functionType === "get") splittedWords[0] = "fetching";
  if (functionType === "submit") splittedWords[0] = "submitting";
  return splittedWords?.join(" ");
};

export const handleError = (error: any, functionName: string) => {
  const errorMessage = getErrorMessage(error);
  const wordsFromFunction = getErrorMessageTitle(functionName);

  let message = errorMessage;
  if (wordsFromFunction) {
    message = `Error while ${wordsFromFunction}: ${errorMessage}`;
  }

  Components.Toast.toastErrorMessage({ message });
};
