import styled from "styled-components";
import { FlexColumn, verticalScroll } from "../Styles";

export const Container = styled(FlexColumn)<{
  $position: "top" | "bottom";
}>`
  position: absolute;
  top: ${({ theme, $position }) =>
    $position === "bottom" && theme.spacing["spacing-9"].value}px;
  bottom: ${({ theme, $position }) =>
    $position === "top" && theme.spacing["spacing-11"].value}px;
  left: 0px;
  z-index: 2;
  width: 342px;
  outline: none;

  .menu-container {
    padding-top: ${({ theme }) => theme.spacing["spacing-5"].value}px;
  }
`;

export const EmojiContainer = styled(FlexColumn)`
  max-height: 255px;
  ${verticalScroll}
`;

export const EmojiListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
export const EmojiWrapper = styled.div<{ $selected: boolean; $hover: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  cursor: pointer;
  background: ${({ theme, $selected, $hover }) =>
    $selected || $hover
      ? theme.Color.elevation.surface["surface-primary-hover"].value
      : "transparent"};
  border: ${({ theme, $selected }) =>
    $selected &&
    "1px solid" + theme.Color.border["ui-border-emphasize-brand"].value};
  &:hover {
    background: ${({ theme }) =>
      theme.Color.elevation.surface["surface-primary-hover"].value};
  }
`;

export const IconButtonWrapper = styled.div<{ $active: boolean }>`
  background: ${({ theme, $active }) =>
    $active && theme.Color.background.brand.subtler["default-active"].value};
  border-radius: ${({ theme, $active }) =>
    $active && theme.radius["radius-xxs"].value + "px"};
  button {
    outline: none;
  }
`;
