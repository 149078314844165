import { createSlice } from "@reduxjs/toolkit";

import { EmojiProps } from "../../../components/Emoji";
import { addProxyUrlToProfileImage } from "../../../utils";
import { setAPI_STATUS } from "../../common";
import { MeProps } from "./Me";

const defaultUser: MeProps.User = {
  first_name: "",
  headline: "",
  id: "",
  last_name: "",
  profile_picture: "",
  post_theme: "",
  onboarded: true,
  emojis: {},
};
const defaultCommon: MeProps.Common = {
  allUsers: [],
  mentions: [],
  suggestedUsers: [],
  recommendedJobs: [],
  peopleYouMightKnow: [],
  yourInnerCircle: [],
};
const defaultAPI_STATUS: MeProps.API = {
  peopleYouMightKnow_API_STATUS: "idle",
  yourInnerCircle_API_STATUS: "idle",
  getSuggestedUsers_API_STATUS: "idle",
  getRecommendedJobs_API_STATUS: "idle",
};

const initialState: MeProps.State = {
  ...defaultUser,
  ...defaultCommon,
  ...defaultAPI_STATUS,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: MeProps.setUser) => {
      const {
        first_name,
        headline,
        id,
        last_name,
        profile_picture,
        onboarded,
        post_theme,
        emojis,
      } = action.payload;
      const newEmojis: Record<string, MeProps.Emoji> = {};
      Object.entries(emojis).forEach(([key, { data, name }]) => {
        const emojiType = name.replaceAll(" ", "") as EmojiProps.EmojiType;
        newEmojis[key] = {
          data,
          name: emojiType,
        };
      });

      state.first_name = first_name;
      state.last_name = last_name;
      state.headline = headline;
      state.id = id;
      state.post_theme = post_theme;
      state.emojis = newEmojis;
      state.profile_picture = addProxyUrlToProfileImage(profile_picture);
           state.onboarded = onboarded;
    },
    setFirstName: (state, action: MeProps.setFirstName) => {
      state.first_name = action.payload;
    },
    setLastName: (state, action: MeProps.setLastName) => {
      state.last_name = action.payload;
    },
    setOnboarded: (state, action: MeProps.setOnboarded) => {
      state.onboarded = action.payload;
    },
    setPeopleYouMightKnow: (state, action: MeProps.setPeopleYouMightKnow) => {
      state.peopleYouMightKnow = action.payload;
    },

    setYourInnerCircle: (state, action: MeProps.setYourInnerCircle) => {
      state.yourInnerCircle = action.payload;
    },
    setYourInnerCircleAPI_STATUS: (state, action: setAPI_STATUS) => {
      state.yourInnerCircle_API_STATUS = action.payload;
    },

    setMentions: (state, action: MeProps.setMentions) => {
      state.mentions = action.payload;
    },
    setSuggestedUsers: (state, action: MeProps.setSuggestedUsers) => {
      state.suggestedUsers = action.payload;
    },
    setAllUsers: (state, action: MeProps.setAllUsers) => {
      state.allUsers = action.payload;
    },

    setRecommendedJobs: (state, action: MeProps.setRecommendedJobs) => {
      state.recommendedJobs = action.payload;
    },

    //API
    setPeopleYouMightKnow_API_STATUS: (state, action: setAPI_STATUS) => {
      state.peopleYouMightKnow_API_STATUS = action.payload;
    },
    setYourInnerCircle_API_STATUS: (state, action: setAPI_STATUS) => {
      state.yourInnerCircle_API_STATUS = action.payload;
    },
    setSuggestedUsers_API_STATUS: (state, action: setAPI_STATUS) => {
      state.getSuggestedUsers_API_STATUS = action.payload;
    },
    setRecommendedJobs_API_STATUS: (state, action: setAPI_STATUS) => {
      state.getRecommendedJobs_API_STATUS = action.payload;
    },

    reset: () => initialState,
  },
});

export const meActions = slice.actions;
export const meReducer = slice.reducer;
