import { Components } from "@thisgorilla/primate-ds";
import { useContext } from "react";
import styled from "styled-components";
import { ReactComponent as DittoFavicon } from "../../../../../../../src/svg/Ditto-Favicon.svg";
import { FlexRow } from "../../../../../Common/Styles";
import { PostCardContext } from "../PostCardContent";
import { PostCardOptions } from "./PostCardOptions";

export const PostCardHeaderRight = () => {
  const { setShowPostCardOptions, showPostCardOptions } =
    useContext(PostCardContext);

  return (
    <Container $gap={4}>
      <DittoFavicon />
      <IconButtonContainer>
        <Components.IconButton
          disabled={false}
          iconType="MoreHorizontal"
          kind="Tertiary"
          size="Small"
          onClick={() => setShowPostCardOptions((prev) => !prev)}
        />
        {showPostCardOptions && <PostCardOptions />}
      </IconButtonContainer>
    </Container>
  );
};

const Container = styled(FlexRow)``;
const IconButtonContainer = styled.div`
  position: relative;
`;
