import { Components } from "@thisgorilla/primate-ds";
import { FlexColumn } from "../Styles";
import Comment from "./Comment";
import { CommentProps } from "./Comment/Comment";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const CommentList = ({
  showReplyButton,
  comments,
  observeCount,
  setIsButtonShow = () => {},
  isButtonShow = false,
}: CommentProps.CommentList) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (contentRef?.current) {
      const currentComment = contentRef.current as Element;
      const parentComment =
        currentComment.parentElement?.parentElement?.parentElement;

      if (parentComment?.classList.contains("comment")) {
        const height = Number(
          (
            currentComment?.getBoundingClientRect().top -
            parentComment?.getBoundingClientRect().top -
            32
          ).toFixed(0)
        );
        setHeight(height);
      }
    }
  }, [contentRef, isButtonShow, observeCount]);
  return (
    <FlexColumn $gap={24}>
      {(showReplyButton || !isButtonShow) &&
        comments.map((comment) => (
          <Comment
            showReplyButton={showReplyButton}
            comment={comment}
            key={comment.id}
            observeCount={observeCount}
          />
        ))}
      {!showReplyButton && comments.length > 0 && isButtonShow && (
        <div ref={contentRef}>
          <Components.Button
            color="Neutral"
            size="XS"
            kind="Primary"
            disabled={false}
            label={
              comments.length + (comments.length > 1 ? ` replies` : " reply")
            }
            onClick={() => setIsButtonShow(false)}
          />
        </div>
      )}
      {height > 0 && isButtonShow && <Line $height={height} />}
    </FlexColumn>
  );
};

export default CommentList;
const Line = styled.div<{ $height: number }>`
  position: absolute;
  top: 44px;
  left: 15px;
  height: ${({ $height }) => $height}px;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-bottom-left-radius: ${({ theme }) => theme.radius["radius-s"].value}px;
  border-color: ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  width: 19px;
`;
