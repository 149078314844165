import { Formik } from "formik";
import moment from "moment";
import { createContext, useContext } from "react";

import { CreatePostModalContext } from "..";
import SchedulePostContent from "./SchedulePostContent";
import SchedulePostFooter from "./SchedulePostFooter";
import SchedulePostHeader from "./SchedulePostHeader";
import { SchedulePostModalProps } from "./SchedulePostModal";
import { scheduledPostUtils } from "./utils";

export const SchedulePostModalContext = createContext(
  {} as SchedulePostModalProps.Context
);
const SchedulePostModal = () => {
  const { formik, setSchedulePostOpen } = useContext(CreatePostModalContext);
  const { delivery_date } = formik.values;
  const date = delivery_date
    ? moment(delivery_date)
    : moment(new Date()).add(1, "hour");
  const onSubmit = (values: SchedulePostModalProps.FormData) => {
    formik.setFieldValue(
      "delivery_date",
      scheduledPostUtils.createDate(values as SchedulePostModalProps.FormData)
    );
    setSchedulePostOpen(false);
  };

  return (
    <Formik
      initialValues={{
        day: date.format("DD"),
        hour: date.format("HH"),
        minute: date.format("mm"),
        month: date.format("MMMM"),
        year: date.format("YYYY"),
      }}
      onSubmit={onSubmit}
      validationSchema={scheduledPostUtils.schedulePostValidation}
    >
      {(formik) => {
        return (
          <SchedulePostModalContext.Provider value={{ formik }}>
            <SchedulePostHeader />
            <SchedulePostContent />
            <SchedulePostFooter />
          </SchedulePostModalContext.Provider>
        );
      }}
    </Formik>
  );
};

export default SchedulePostModal;
