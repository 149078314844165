import styled from "styled-components";
import { Emojis } from ".";
import { EmojiProps } from "./Emoji";
import { useEmojiDefaultValues } from "./useEmojiDefaultValues";

export const Emoji = ({ type, scale = 1, ...rest }: EmojiProps.Props) => {
  const { size: defaultSize } = useEmojiDefaultValues(rest);

  if (!type || type === "none") return null;
  const EmojiIcon = Emojis[type];
  if (!EmojiIcon) return null;
  const sizeWithScale = rest.size ? rest.size * scale : defaultSize * scale;

  return (
    <AnimationWrapper $size={sizeWithScale} $scale={scale}>
      <EmojiIcon {...rest} />
    </AnimationWrapper>
  );
};

const AnimationWrapper = styled.div<EmojiProps.AnimationWrapper>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
  transform: scale(${({ $scale }) => $scale});
  transition: all 50ms ease-in-out;
`;
