import { useEffect } from "react";
import { actions, useAppDispatch } from "../../../redux";
import { OnboardingCommon } from "../common";
import { OnboardingStyles } from "../styles";
import { useLocationField } from "./useLocation";

export const Location = () => {
  const locationField = useLocationField({ from: "location" });
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(actions.onboarding.setGettingStartedVisited(false));
  }, [dispatch]);

  return (
    <OnboardingStyles.Container $stepName="location">
      <OnboardingStyles.TitleAndDescription $gap={8}>
        <OnboardingCommon.Title text="Where are you from?" />
        <OnboardingStyles.Description>
          <OnboardingCommon.Description text="Share your location to receive tailored content and precise job recommendations." />
        </OnboardingStyles.Description>
      </OnboardingStyles.TitleAndDescription>
      {locationField}
    </OnboardingStyles.Container>
  );
};
