import { createContext, useRef, useState } from "react";
import styled from "styled-components";
import { FlexColumn } from "../../../../Common/Styles";
import { PostCardHeader } from "./1-PostCardHeader";
import { Topics } from "./2-Topics";
import { Content } from "./3-Content";
import { Footer } from "./4-Footer";
import { PostCardProps } from "./PostCard";
import { ReportPost } from "./ReportPost";

export const PostCardContext = createContext({} as PostCardProps.Context);
export const PostCardContent = ({ postId }: PostCardProps.PostCardContent) => {
  const [showPostCardOptions, setShowPostCardOptions] = useState(false);
  const [reportPostOpen, setReportPostOpen] = useState(false);
  const postCardOptionsRef = useRef<HTMLDivElement>(null);

  return (
    <PostCardContext.Provider
      value={{
        showPostCardOptions,
        setShowPostCardOptions,
        postCardOptionsRef,
        reportPostOpen,
        setReportPostOpen,
        postId,
      }}
    >
      <Container className="post-card" $gap={0}>
        <PostCardHeader />
        <Topics />
        <Content />
        <Footer postId={postId} />
      </Container>
      {reportPostOpen && <ReportPost setReportPostOpen={setReportPostOpen} />}
    </PostCardContext.Provider>
  );
};

const Container = styled(FlexColumn)(
  ({ theme }) => `
  background-color: ${theme.Color.elevation.surface["surface-primary"].value};
  border-radius: ${theme.spacing["spacing-2"].value}px;
  box-shadow: ${theme.boxShadows["box-shadow-m"]};
`
);
