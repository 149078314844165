import styled, { css } from "styled-components";

import { OnboardingProps } from "../../redux/slices/onboarding/onboarding";
import { FlexColumn, FlexRow, FlexRowCenter } from "../Common/Styles";

const Container = styled(FlexColumn)<{ $stepName: OnboardingProps.Step }>`
  width: ${({ $stepName }) =>
    $stepName === "people_recommendations" ? 696 : 510}px;
  gap: 16px;
`;

const TitleAndDescription = styled(FlexColumn)`
  gap: 16px;
`;

const description = css`
  border-radius: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  background: ${({ theme }) => theme.Color.background.neutral.default.value};
  align-items: start;
`;
const Description = styled(FlexRow)`
  ${description};
`;
const DescriptionWithInfo = styled(FlexColumn)`
  ${description};
`;
const InstructionContainer = styled(FlexRowCenter)``;

export const OnboardingStyles = {
  Container,
  TitleAndDescription,
  Description,
  DescriptionWithInfo,
  InstructionContainer,
};
