import { Inputs } from "@thisgorilla/primate-ds";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { actions, useAppDispatch } from "../../../redux";
import { FlexColumn, InputContainer } from "../../Common/Styles";
import { useLocationField } from "../1-location/useLocation";
import { OnboardingCommon } from "../common";
import { OnboardingComponentProps } from "../OnboardingComponent";
import { OnboardingStyles } from "../styles";
import { ONBOARDING_ERRORS } from "../utils";

export const GetStarted = () => {
  const dispatch = useAppDispatch();

  const { values, handleChange, setFieldTouched, touched } =
    useFormikContext<OnboardingComponentProps.FormValues>();

  const locationField = useLocationField({ from: "get_started" });

  useEffect(() => {
    dispatch(actions.onboarding.setGettingStartedVisited(true));
  }, [dispatch]);

  return (
    <OnboardingStyles.Container $stepName="get_started">
      <OnboardingCommon.Title text="Get Started" />
      <FlexColumn $gap={16}>
        <InputContainer>
          <Inputs.Input
            fieldName="first_name"
            placeholder="First name"
            idPrefix="getStarted"
            isRequired
            value={values.first_name}
            onChangeHandler={(e) => {
              handleChange(e);
              dispatch(actions.onboarding.setFirstName(e));
            }}
            onBlur={() => setFieldTouched("first_name")}
          />
          <Inputs.CommonField.HelpText
            kind="Error"
            content={
              touched.first_name && !values.first_name
                ? ONBOARDING_ERRORS.emptyFirstName
                : ""
            }
          />
        </InputContainer>

        <InputContainer>
          <Inputs.Input
            fieldName="last_name"
            placeholder="Last name"
            idPrefix="getStarted"
            isRequired
            value={values.last_name}
            onChangeHandler={(e) => {
              handleChange(e);
              dispatch(actions.onboarding.setLastName(e));
            }}
            onBlur={() => setFieldTouched("last_name")}
          />
          <Inputs.CommonField.HelpText
            kind="Error"
            content={
              touched.last_name && !values.last_name
                ? ONBOARDING_ERRORS.emptyLastName
                : ""
            }
          />
        </InputContainer>

        {locationField}
      </FlexColumn>
    </OnboardingStyles.Container>
  );
};
