import { useContext } from "react";
import { ReportCommentContext } from ".";
import { reportCommentUtils } from "./utils";

export const useGoBackToPrevStep = () => {
  const { reportComment, setReportComment } = useContext(ReportCommentContext);
  const currentStepNumber =
    reportCommentUtils.ReportCommentStepNumber[reportComment.reportCommentStep];
  const prevStepNumber = currentStepNumber - 2;
  const prevStep = reportCommentUtils.REPORT_COMMENT_STEPS[prevStepNumber];

  const goBackToPrevStep = () => {
    setReportComment((prev) => ({ ...prev, reportCommentStep: prevStep }));
  };

  return { goBackToPrevStep };
};
