import { useMemo } from "react";
import { Components } from "@thisgorilla/primate-ds";
import styled, { useTheme } from "styled-components";

import { getPeopleNamesText } from "../../../utils";
import { UserCardProps } from "./UserCard";

const UserFollowersInfo = (user: UserCardProps.User) => {
  const { Color, typography } = useTheme();
  const { total_followers, followers } = user;

  const followersNamesText = useMemo(
    () => getPeopleNamesText(followers),
    [followers]
  );

  return (
    <FollowButtonAndInfoContainer>
      <FollowInfoContainer>
        {total_followers > 0 && (
          <Components.AvatarGroup
            srcCountToBeRendered={total_followers >= 2 ? 2 : followers.length}
            src={followers.map((follower) => follower.profile_picture)}
            size="XXSmall-20"
          />
        )}
        <Components.Text
          text={(followersNamesText ? "Followed by " : "") + followersNamesText}
          maxLineLength={2}
          style={{ tokenStyle: typography.Body.S }}
          color={Color.text["text-tertiary"].value}
        />
      </FollowInfoContainer>
      <Components.Button
        color="Neutral"
        kind="Secondary"
        disabled={false}
        label="Follow"
        onClick={() => {}}
        size="XS"
        iconType="Plus"
        iconPosition="left"
      />
    </FollowButtonAndInfoContainer>
  );
};

export default UserFollowersInfo;

const FollowInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-1"].value}px;
`;

const FollowButtonAndInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing["spacing-1"].value}px;
`;
