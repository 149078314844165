import { API_INSTANCES } from "../api_instance";
import { handleError } from "../errors";

const getPostImage = async (id: string) => {
  try {
    const response = await API_INSTANCES.IMAGES.getPostImage(id);
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  } catch (error) {
    handleError(error, "getPostImage");
    return null;
  }
};

export const IMAGES = {
  getPostImage,
};
