import { Components } from "@thisgorilla/primate-ds";
import { ReportCommentProps } from "../ReportComment";
import { useGoToNextStep } from "../useGoToNextStep";

export const ReportListItem = ({
  label,
}: ReportCommentProps.ReportListItem) => {
  const { goToNextStep } = useGoToNextStep(label);

  return (
    <>
      <Components.ListItem
        key={label}
        type="List Item"
        label={label}
        onClick={goToNextStep}
        ArrowIconType="ChevronRight"
      />
      <Components.ListItem key={`${label}-divider`} type="Divider" />
    </>
  );
};
