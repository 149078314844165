import { useContext } from "react";
import { ReportPostContext } from ".";
import {
  actions,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../redux";
import { reportPostUtils } from "../../../../../../redux/slices/reportPost/utils";

export const useGoToNextStep = (answer = "") => {
  const dispatch = useAppDispatch();
  const { reportPostStep } = useAppSelector((state) => state.reportPost);
  const currentStepNumber =
    reportPostUtils.ReportPostStepNumber[reportPostStep];
  const nextStepNumber = currentStepNumber;
  const nextStep = reportPostUtils.REPORT_POST_STEPS[nextStepNumber];
  const { setDirection } = useContext(ReportPostContext);

  const goToNextStep = () => {
    setDirection("to-right");
    dispatch(actions.reportPost.setReportPostStep(nextStep));

    switch (reportPostStep) {
      case "why_reporting":
        dispatch(actions.reportPost.setWhyReportingAnswer(answer));
        break;
      case "best_problem_description":
        dispatch(actions.reportPost.setBestProblemDescriptionAnswer(answer));
        break;
      case "what_is_happening":
        dispatch(actions.reportPost.setWhatIsHappeningAnswer(answer));
        break;
    }
  };

  return { goToNextStep };
};
