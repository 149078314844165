import { useContext } from "react";
import styled from "styled-components";
import { ReportCommentContext } from "..";
import { FlexRowEnd } from "../../../../../../Common/Styles";
import { FooterButtons } from "./Buttons";
import { TweakYourFeed } from "./TweakYourFeed";

export const Footer = () => {
  const { reportComment } = useContext(ReportCommentContext);
  const { reportCommentStep } = reportComment;
  const showTweakYourFeed = reportCommentStep === "why_reporting";
  const showBackButton =
    reportCommentStep === "best_problem_description" ||
    reportCommentStep === "what_is_happening" ||
    reportCommentStep === "submit_confirmation";
  const showSubmitButton = reportCommentStep === "submit_confirmation";
  const showDoneButton = reportCommentStep === "submit_success";

  return (
    <Container
      $withButton={showBackButton || showSubmitButton || showDoneButton}
    >
      {showTweakYourFeed && <TweakYourFeed />}
      {showBackButton && <FooterButtons.BackButton />}
      {showSubmitButton && <FooterButtons.SubmitButton />}
      {showDoneButton && <FooterButtons.DoneButton />}
    </Container>
  );
};

const Container = styled(FlexRowEnd)<{ $withButton: boolean }>(
  ({ theme, $withButton }) => `
  position: sticky;
  bottom: 0;
  padding: ${
    $withButton
      ? `${theme.spacing["spacing-6"].value}px ${theme.spacing["spacing-8"].value}px`
      : `3px ${theme.spacing["spacing-4"].value}px ${theme.spacing["spacing-6"].value}px ${theme.spacing["spacing-4"].value}px`
  } ;
  gap: ${theme.spacing["spacing-2"].value}px;
`
);
